import React, { lazy, Suspense } from "react";
import { Redirect, Routes, Route, Switch, useLocation } from "react-router-dom";
import Dashboard from '../Pages/Dashboard/Dashboard';
import Home from "../Pages/Home/Home";
import Users from "../Pages/Users/Users";
import Login from "../Pages/Login/Login";
import Logout from "../Pages/Login/Logout";
import PrivateRoute from "./PrivateRoute";
import Devices from "../Pages/Devices/Devices";
import Areas from "../Pages/Areas/Areas";
import Profitability from "../Pages/Profitability/Profitability";
import Counters from "../Pages/Counters/Counters";
import PhaseScreen from '../Pages/Phase/Phase';
import { accessName } from "../utils/access";
import DevicesDashboard from '../Pages/Home/DevicesDashboard';
import Options from '../Pages/Options/Options';
import StatusPage from '../Pages/StatusPage/StatusPage';

// const Home = lazy(() => {
//     import("../Pages/Home/Home")
// });

// const Users = lazy(() => {
//     import("../Pages/Users/Users")
// });

const Router_god_owner = () => {
    return (
        <Routes>
            <Route path="/" element={<PrivateRoute component={Dashboard} name='dashboard'/>} />
            <Route path="/devices" element={<PrivateRoute component={DevicesDashboard} name='home'/>} />
            <Route path="/profit" element={<PrivateRoute component={Profitability} name='profit' />} />
            <Route path="/counters" element={<PrivateRoute component={Counters} name='counters' />} />
            <Route path="/phase" element={<PrivateRoute component={PhaseScreen} name='phases' />} />
            <Route path="/areas" element={<PrivateRoute component={Areas} name='areas' />} />
            <Route path="/users" element={<PrivateRoute component={Users} name='users' />} />
            <Route path="/options" element={<PrivateRoute component={Options} name='options' />} />
            <Route path="/uptime" element={<PrivateRoute component={StatusPage} name='uptime' />} />
            <Route path="/logout" element={<PrivateRoute component={Logout} name='logout' />} />
        </Routes>
    )
}

const Router_admin = () => {
    return (
        <Routes>
            <Route path="/" element={<PrivateRoute component={Dashboard} name='dashboard'/>} />
            <Route path="/devices" element={<PrivateRoute component={DevicesDashboard} name='home'/>} />
            <Route path="/counters" element={<PrivateRoute component={Counters} name='counters' />} />
            <Route path="/phase" element={<PrivateRoute component={PhaseScreen} name='phases' />} />
            <Route path="/areas" element={<PrivateRoute component={Areas} name='areas' />} />
            <Route path="/options" element={<PrivateRoute component={Options} name='options' />} />
            <Route path="/uptime" element={<PrivateRoute component={StatusPage} name='uptime' />} />
            <Route path="/logout" element={<PrivateRoute component={Logout} name='logout' />} />
        </Routes>
    )
}

const Router_tech = () => {
    return (
        <Routes>
            <Route path="/" element={<PrivateRoute component={Dashboard} name='dashboard'/>} />
            <Route path="/devices" element={<PrivateRoute component={DevicesDashboard} name='home'/>} />
            <Route path="/counters" element={<PrivateRoute component={Counters} name='counters' />} />
            <Route path="/phase" element={<PrivateRoute component={PhaseScreen} name='phases' />} />
            <Route path="/uptime" element={<PrivateRoute component={StatusPage} name='uptime' />} />
            <Route path="/logout" element={<PrivateRoute component={Logout} name='logout' />} />
        </Routes>
    )
}

const Router_finance = () => {
    return (
        <Routes>
            <Route path="/" element={<PrivateRoute component={Dashboard} name='dashboard'/>} />
            <Route path="/devices" element={<PrivateRoute component={DevicesDashboard} name='home'/>} />
            <Route path="/profit" element={<PrivateRoute component={Profitability} name='profit' />} />
            <Route path="/counters" element={<PrivateRoute component={Counters} name='counters' />} />
            <Route path="/phase" element={<PrivateRoute component={PhaseScreen} name='phases' />} />
            <Route path="/uptime" element={<PrivateRoute component={StatusPage} name='uptime' />} />
            <Route path="/logout" element={<PrivateRoute component={Logout} name='logout' />} />
        </Routes>
    )
}

export default function AppRouter({user}) {
    const _accessName = accessName(user.access_type)

    if(_accessName == "GOD" || _accessName == "OWNER")
        return (<Router_god_owner/>)
    else if(_accessName == "ADMIN")  {
        return (<Router_admin/>)
    }else if(_accessName == "TECH")  {
        return (<Router_tech/>)
    }else if(_accessName == "FINANCE")  {
        return (<Router_finance/>)
    } else  {
        return (
            <div>

            </div>
        )
    }

    // return (
    //     <Routes>
    //         <Route path="/" element={<PrivateRoute component={Home} name='home'/>} />
    //         <Route path="/devices" element={<PrivateRoute component={Devices} name='devices' />} />
    //         <Route path="/profit" element={<PrivateRoute component={Profitability} name='profit' />} />
    //         <Route path="/logout" element={<PrivateRoute component={Logout} name='logout' />} />
    //         <Route path="/areas" element={<PrivateRoute component={Areas} name='areas' />} />
    //         <Route path="/users" element={<PrivateRoute component={Users} name='users' />} />
    //         <Route path="/counters" element={<PrivateRoute component={Counters} name='counters' />} />
    //     </Routes>
    // )
}