import { React, Component, useState, useEffect, useContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { AreaContext } from '../Area/Area';
import { Oval } from  'react-loader-spinner'
import { getAreaCounterByUUID, createCounterK } from '../../store/actions';
import Swal from 'sweetalert2';

export default function CounterModal({data}) {

    const dispatch = useDispatch();
    const {selectedCounterUUID,setSelectedCounterUUID} = useContext(AreaContext)
    const [isCounterHistoryLoading, setIsCounterHistoryLoading] = useState(false);
    const [counterHistory, setCounterHistory] = useState(null);
    const [k, setK] = useState('');

    useEffect( () => { 
        const fetchAreasCounters = async () => {
            const result = await dispatch(getAreaCounterByUUID(selectedCounterUUID))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setCounterHistory(result.payload.data)
            setIsCounterHistoryLoading(false)
        }

        if(selectedCounterUUID.length > 0)  {
            setIsCounterHistoryLoading(true)
            fetchAreasCounters();
        }

    }, [selectedCounterUUID]);

    const isNumeric = (str) => {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const onAddK = () => {
        async function sendData() {
            if(!isNumeric(k)) {
                return Swal.fire({text:'Коэффициент трансформации это число', icon: 'error'});
            }

            const kNum = parseFloat(k)

            const result = await dispatch(
                createCounterK(data.area_uuid, 
                    selectedCounterUUID, 
                    kNum)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            let ch = counterHistory
            const assumedData = [...ch.k, result.payload.data]

            ch.k = assumedData.sort((first, second) => {
                return second.create_time - first.create_time
            })
            setCounterHistory(ch)

            setK('')
        }
        sendData()
    }

    return (
        <div className={selectedCounterUUID.length > 0 ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    setSelectedCounterUUID('')
                    setCounterHistory(null)
                }}>
            </div>
            <div className="modal-content">
                <div className="box is-fullheight">
                    {(isCounterHistoryLoading)? (
                        <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
                            <Oval
                                height={80}
                                width={80}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperclassName=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                            <p className="mt-2">Загрузка</p>
                        </div>
                    ): ( (counterHistory !== null) ? (
                        <div>
                            <h3 className="subtitle is-3 has-text-centered">{counterHistory.serial_number + '(' + counterHistory.model_number + ')'}</h3>
                            <div className="field is-grouped">
                                <p className="control is-expanded">
                                    <input className="input" 
                                        type="text" 
                                        placeholder="Коэффициент трансформации"
                                        value={k || ''} 
                                        onChange={(e) => setK(e.target.value)}/>
                                </p>
                                <p className="control">
                                    <a className="button is-info" onClick={onAddK}>
                                        Добавить
                                    </a>
                                </p>
                            </div>
                            <table className="table is-fullwidth is-striped is-bordered s-narrow is-hoverable">
                                <thead>
                                    <tr>
                                    <th>k</th>
                                    <th>Дата</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(
                                    counterHistory.k.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.k}</td>
                                                <td>{new Date(item.create_time*1000).toLocaleString()}</td>
                                            </tr>
                                        )
                                    })
                                )}
                                </tbody>
                            </table>
                        </div>) : (<></>)
                    )}
                </div>
            </div>
        </div>
    )
}