import React, { lazy, Suspense } from "react";
import { Redirect, Routes, Route, Switch, useLocation } from "react-router-dom";
import Login from "../Pages/Login/Login";

export default function AppRouter() {
    return (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/logout" element={<Login />} />
        </Routes>
    )
}