
import { React, Component, useState, useEffect, useContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Oval } from  'react-loader-spinner'
import { changeBeingModeForDevice, getRoomRanges } from '../../store/actions';
import Swal from 'sweetalert2';
import Calendar from 'moedim';

export default function RocketCalendar({visible_settings, handle}) {
    const dispatch = useDispatch();

    const [value, setValue] = useState(new Date())

    return (
        <div className={visible_settings.visible ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    handle({visible:false, mode:visible_settings.mode, date:visible_settings.date})
                }}>
            </div>
            <div className="modal-content">
                <div className="box is-fullheight has-text-centered">
                    <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-center' style={{height:'100%'}}>
                        <Calendar value={visible_settings.date} onChange={(d) => {handle({visible:false, mode:visible_settings.mode, date:d})}} />
                    </div>
                </div>
            </div>
        </div>
    )
}