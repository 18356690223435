import { React, Component, useState, useEffect, useContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { UserContext } from './Users';
import { Oval } from  'react-loader-spinner'
import { changeUserAreas, deleteUser } from '../../store/actions';
import Swal from 'sweetalert2';

export default function UserModal({data, onFinish}) {

    const dispatch = useDispatch();
    const {selectedUser,setSelectedUser} = useContext(UserContext)

    const [areas, setAreas] = useState([])
    const [telegramAcc, setTelegramAcc] = useState('')

    useEffect( () => { 
        if(selectedUser !== null)   {
            const ids = []
            data.areas.map(area => {

                let ret = false
                selectedUser.areas.map(tt => {
                    if(tt.id == area.id)    {
                        ret = true
                    }
                })
                ids.push({
                    id: area.id,
                    name: area.name,
                    checked: ret
                })
            })
            setAreas(ids)
            setTelegramAcc(selectedUser.telegram_acc)
        }
    }, [selectedUser]);

    const updateCheckStatus = (index) => {
        let newArr = areas.map((area, currentIndex) => {
            return currentIndex == index
                ? { ...area, checked: !area.checked}
                : area
        })
        setAreas(newArr)
    }

    const onSave = () => {
        async function onSaveUser() {
            const arr = []
            areas.map((area, index) => {
                if(area.checked)    {
                    arr.push(area.id) 
                }
            })
    
            data = {
                id: selectedUser.id,
                areas:arr,
                telegram_acc: telegramAcc
            }

            console.log(data)

            const result = await dispatch(
                changeUserAreas(data)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setSelectedUser(null)
            onFinish()
        }

        onSaveUser()
    }

    const onDelete = () => {
        async function onDeleteUser() {
            const result = await dispatch(
                deleteUser(selectedUser.id)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setSelectedUser(null)
            onFinish()
        }

        onDeleteUser()
    }

    return (
        <div className={selectedUser !== null ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    setSelectedUser(null)
                }}>
            </div>
            <div className="modal-content">
                {
                    (selectedUser !== null && areas !== null) ? (
                        <div className="box is-fullheight">
                            <div className="field">
                                <label className="label">Логин</label>
                                <div className="control">
                                    <input className="input" type="text" readOnly={true} placeholder="" value={selectedUser.username}/>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Пароль</label>
                                <div className="control">
                                    <input className="input" type="text" readOnly={true} placeholder="" value={selectedUser.password}/>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Telegram аккаунт</label>
                                <div className="control">
                                    <input className="input"
                                        type="text"
                                        placeholder="Без @"
                                        value={telegramAcc || ''} 
                                        onChange={(e) => setTelegramAcc(e.target.value)}/>
                                </div>
                            </div>
                            <p className='is-size-5'>Роль:</p>
                            <div className="control mt-2 is-flex is-justify-content-space-around">
                                <label className="radio">
                                    <input type="radio" name="role" disabled={true} checked={(selectedUser.access_type == 1)}/>
                                    Владелец
                                </label>
                                <label className="radio">
                                    <input type="radio" name="role" disabled={true} checked={(selectedUser.access_type == 2)}/>
                                    Администратор
                                </label>
                                <label className="radio">
                                    <input type="radio" name="role" disabled={true} checked={(selectedUser.access_type == 3)}/>
                                    Тех. специалист
                                </label>
                                <label className="radio">
                                    <input type="radio" name="role" disabled={true} checked={(selectedUser.access_type == 4)}/>
                                    Бухгалтер
                                </label>
                            </div>
                            <p className='is-size-5 mt-3'>Доступные зоны:</p>
                            <div className="control mt-2 is-flex is-justify-content-space-around">
                                {(
                                    areas.map((area, index) => {
                                        return (
                                            <label className="checkbox" key={area.id}>
                                                <input type="checkbox" checked={area.checked} onChange={()=>{updateCheckStatus(index)}}/>
                                                {area.name}
                                            </label>
                                        )
                                    })
                                )}
                            </div>
                            <div className="buttons is-flex mt-5 is-centered">
                                <button className="button is-success" onClick={onSave}>Сохранить</button>
                                <button className="button is-danger" onClick={onDelete}>Удалить пользователя</button>
                            </div>
                        </div>
                    ) : (null)
                }
            </div>
        </div>
    )
}