import { React, Component, useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getAreaPools, createPool, deletePool } from '../../store/actions';
import { IoMdArrowDropdown } from 'react-icons/io';

export default function F2PoolList({data, onCounterClick}) {

    const portDefaultValue = '/dev/ttyUSB0'
    const poolsList = [
        {
            name: 'F2Pool',
            type: 'f2pool'
        }
    ]
    const currencyList = [
        {
            name: 'bitcoin',
            currency: 'BTC'
        },
        {
            name: 'litecoin',
            currency: 'LTC'
        },
        {
            name: 'dogecoin',
            currency: 'DOGE'
        },
    ]

    const dispatch = useDispatch();

    const [selectedPoolIndex, setSelectedPoolIndex] = useState(0);
    const [poolDropDownShow, setPoolDropDownShow] = useState(false);
    const idBtnRef = useRef()

    const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState(0);
    const [currencyDropDownShow, setCurrencyDropDownShow] = useState(false);

    const [pools, setPools] = useState([]);

    const [сurrency, setCurrency] = useState('');
    const [subaccountName, setSubaccountName] = useState('');

    const [serialNumber, setSerialNumber] = useState('');
    const [modelNumber, setModelNumber] = useState('');
    const [port, setPort] = useState(portDefaultValue);
    const [k, setK] = useState('');

    useEffect( () => { 
        const fetchAreaPool = async () => {
            const result = await dispatch(getAreaPools(data.area_uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setPools(result.payload.data)
        }

        fetchAreaPool();
    }, []);

    const onDeletePool = (pool_id) => {
        async function sendDeletePool(counter_id) {
            const result = await dispatch(
                deletePool(data.area_uuid, 
                    pool_id)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            const poolIndex = pools.findIndex(item => item.id == pool_id)
            
            let newPools = [...pools];
            newPools[poolIndex].is_disabled = true
            setPools(newPools)
        }
        sendDeletePool(pool_id)
    }

    const Pool = ({pool}) => (
        <div className="control" key={pool.id}>
            {
                (pool.is_disabled) ? (
                    <div className="tags has-addons">
                        <span className="tag is-dark">{pool.currency_type}</span>
                        <span className="tag is-danger" onClick={()=>{}}>{pool.currency}/{pool.subaccount}</span>
                    </div>
                ): (
                    <div className="tags has-addons">
                        <span className="tag is-dark">{pool.currency_type}</span>
                        <span className="tag is-dark is-success" onClick={()=>{}}>{pool.currency}/{pool.subaccount}</span>
                        <a className="tag is-delete" onClick={()=>{onDeletePool(pool.id)}}></a>
                    </div>
                )
            }
        </div>
    )

    const onAddPool = () => {
        async function sendData() {
            if (сurrency.length === 0) {
                return Swal.fire({text:'Введите pool prefix', icon: 'error'});
            } else if (subaccountName.length === 0) {
                return Swal.fire({text:'Введите pool subaccount', icon: 'error'});
            }

            const result = await dispatch(
                createPool(data.area_uuid, 
                    сurrency, 
                    subaccountName,
                    currencyList[selectedCurrencyIndex]['currency'],
                    poolsList[selectedPoolIndex]['type'])
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setPools([...pools, result.payload.data])
            setCurrency('')
            setSubaccountName('')
        }
        sendData()
    }

    return (
        <div className='box'>
            <h3 className="title is-3">Пулы</h3>
            <div className="field is-grouped is-grouped-multiline">
                {
                    pools.map(item => {
                        return (
                            <Pool pool={item} key={item.id}/>
                        )
                    })
                }
            </div>
            
            <div className='is-flex'>
                <div className="field">
                    <label className="label">Тип пула</label>
                    <div className={(poolDropDownShow)?"dropdown is-active":"dropdown"}>
                        <div className="dropdown-trigger">
                            <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={()=>{setPoolDropDownShow(!poolDropDownShow)}}>
                                <span>
                                    {
                                        (poolsList.length > 0) ? (poolsList[selectedPoolIndex]['name']) : (null)
                                    }
                                </span>
                                <span className="icon is-small">
                                    {
                                        <IoMdArrowDropdown />
                                    }
                                </span>
                            </button>
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            <div className="dropdown-content">
                                {
                                    poolsList.map((item, index) => {
                                        return (
                                            <a key={index}
                                                className={(index == selectedPoolIndex)?"dropdown-item is-active":"dropdown-item"}
                                                onClick={()=>{
                                                    setSelectedPoolIndex(index)
                                                    setPoolDropDownShow(false)
                                                }}
                                            >
                                                {item['name']}
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field ml-4">
                    <label className="label">Currency</label>
                    <div className={(currencyDropDownShow)?"dropdown is-active":"dropdown"}>
                        <div className="dropdown-trigger">
                            <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={()=>{setCurrencyDropDownShow(!currencyDropDownShow)}}>
                                <span>
                                    {
                                        (currencyList.length > 0) ? (currencyList[selectedCurrencyIndex]['name']) : (null)
                                    }
                                </span>
                                <span className="icon is-small">
                                    {
                                        <IoMdArrowDropdown />
                                    }
                                </span>
                            </button>
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu" role="menu">
                            <div className="dropdown-content">
                                {
                                    currencyList.map((item, index) => {
                                        return (
                                            <a key={index}
                                                className={(index == selectedCurrencyIndex)?"dropdown-item is-active":"dropdown-item"}
                                                onClick={()=>{
                                                    setSelectedCurrencyIndex(index)
                                                    setCurrencyDropDownShow(false)
                                                }}
                                            >
                                                {item['name']}
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">Pool prefix</label>
                <input className="input" 
                    type="text" 
                    placeholder="bitcoin"
                    value={сurrency || ''} 
                    onChange={(e) => setCurrency(e.target.value)}/>
            </div>

            <div className="field">
                <label className="label">Pool subaccount</label>
                <input className="input" 
                    type="text" 
                    placeholder="xberg186cv"
                    value={subaccountName || ''} 
                    onChange={(e) => setSubaccountName(e.target.value)}/>
            </div>

            <div className="control">
                <button className="button is-primary" onClick={onAddPool}>Добавить</button>
            </div>
        </div>
    )
}