import { React, Component, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

import { getMiningDeviceList, deleteMiningDevice, updateMiningDevice, createMiningDevice } from '../../store/actions';
import {
  getIsAreasLoading
} from '../../store/selectors';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, IconButton, MenuItem, Tooltip } from '@mui/material';

export default function Options() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});

    const columns = useMemo(() => [
        {
            id: 'model',
            header: 'Модель',
            accessorFn: (row) => row['model'],
            filterVariant: 'text',
            enableEditing: true,
            muiEditTextFieldProps: {
                required: true,
                error: !!validationErrors?.model,
                helperText: validationErrors?.model ? validationErrors?.model : 'Различные виды моделей',
                onFocus: () => {
                    setValidationErrors({
                        ...validationErrors,
                        model: undefined,
                    })
                },
            }
        },
        {
            id: 'hashrate',
            header: 'Hashrate',
            accessorFn: (row) => row['hashrate'],
            filterVariant: 'text',
            enableEditing: true,
            muiEditTextFieldProps: {
                required: true,
                error: !!validationErrors?.hashrate,
                helperText: validationErrors?.hashrate ? validationErrors?.hashrate : 'тут текст подсказка',
                onFocus: () => {
                    setValidationErrors({
                        ...validationErrors,
                        hashrate: undefined,
                    })
                }
            }
        },
        {
            id: 'power',
            header: 'Потребление',
            accessorFn: (row) => row['power'],
            filterVariant: 'text',
            enableEditing: true,
            muiEditTextFieldProps: {
                required: true,
                error: !!validationErrors?.power,
                helperText: validationErrors?.power ? validationErrors?.power : 'тут текст подсказка',
                onFocus: () => {
                    setValidationErrors({
                        ...validationErrors,
                        power: undefined,
                    })
                }
            }
        },
        {
            id: 'temperature',
            header: 'Температура',
            accessorFn: (row) => row['temperature'],
            filterVariant: 'text',
            enableEditing: true,
            muiEditTextFieldProps: {
                required: true,
                error: !!validationErrors?.temperature,
                helperText: validationErrors?.temperature ? validationErrors?.temperature : 'тут текст подсказка',
                onFocus: () => {
                    setValidationErrors({
                        ...validationErrors,
                        temperature: undefined,
                    })
                }
            }
        },
        {
            id: 'fan_speed',
            header: 'Скорость',
            accessorFn: (row) => row['fan_speed'],
            filterVariant: 'text',
            enableEditing: true,
            muiEditTextFieldProps: {
                required: true,
                error: !!validationErrors?.fan_speed,
                helperText: validationErrors?.fan_speed ? validationErrors?.fan_speed : 'тут текст подсказка',
                onFocus: () => {
                    setValidationErrors({
                        ...validationErrors,
                        fan_speed: undefined,
                    })
                }
            }
        },
    ], [validationErrors])

    const handleAddRow = ({
        exitCreatingMode,
        row,
        table,
        values
    }) => {
        const newValidationErrors = validateDevice(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }

        setValidationErrors({});
        onAdd(values)
        exitCreatingMode();
    };

    const handleEditRow = ({
        exitEditingMode,
        row,
        table,
        values
    }) => {
        const newValidationErrors = validateDevice(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        
        setValidationErrors({});
        onSave({...values, id: row.original.id}, row.index)
        exitEditingMode();
    };

    //DELETE action
    const openDeleteConfirmModal = (row) => {
        if (window.confirm('Are you sure you want to delete this?')) {
            onDeleteMiningDevice(row.original.id);
        }
    };

    const validateDevice = (device) => {
        return {
            model: (device.model.length === 0) ? 'Model is required' : '',
            hashrate: (!Number.isInteger(parseInt(device.hashrate))) ? 'Hashrate is integer field' : '',
            power: (!Number.isInteger(parseInt(device.power))) ? 'Power is integer field' : '',
            temperature: (!Number.isInteger(parseInt(device.temperature))) ? 'Temperature is integer field' : '',
            fan_speed: (!Number.isInteger(parseInt(device.fan_speed))) ? 'Fan speed is integer field' : '',
        }
    }

    const table = useMaterialReactTable({
        columns,
        data: data,
        enablePagination: false,
        enableRowNumbers: true,
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        enableColumnFilterModes: true,
        enableFacetedValues: true,
        enableColumnResizing: true,
        layoutMode: 'grid',
        enableEditing: true,

        onCreatingRowSave: (handleAddRow),
        onEditingRowSave: (handleEditRow),

        renderTopToolbarCustomActions: ({table}) => {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        gap: '16px',
                        padding: '8px',
                        flexWrap: 'wrap',
                        flexDirection:'row',
                        justifyContent:'space-between'
                    }}>
                    <Tooltip title="Create new device">
                        <IconButton onClick={()=>{table.setCreatingRow(true)}}>
                            <AddBoxIcon />
                        </IconButton>
                    </Tooltip>
                </Box>)
        },

        enableRowActions: true,
        renderRowActionMenuItems: ({ closeMenu, row }) => [
            <MenuItem key="delete_action" onClick={() => {closeMenu(); openDeleteConfirmModal(row)}}>
                <DeleteIcon /> Delete
            </MenuItem>
        ],
    })

    const onDeleteMiningDevice = (uuid) => {
        async function sendDeleteDevice(uuid) {
            const result = await dispatch(deleteMiningDevice(uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setData(data.filter(item => item.id !== uuid))
        }
        sendDeleteDevice(uuid)
    }

    const onSave = (device, row_index) => {
        async function save(device, row_index) {
            const result = await dispatch(updateMiningDevice(device))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            } else  {
                data[row_index] = device
                setData([...data]);
            }
        }
        save(device, row_index)
    }

    const onAdd = (device) => {
        async function add(device) {
            const result = await dispatch(createMiningDevice(device))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            } else  {
                data.push(device);
                setData([...data]);
            }
        }
        add(device)
    }

    useEffect( () => { 
        const fetchData = async () => {
            const result = await dispatch(getMiningDeviceList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setData(result.payload.data)
        }

        fetchData()
    }, []);

    return (
        <MaterialReactTable table={table}/>
    );
}


  