import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from '@mui/material';

const EventListDialog = ({open, onClose}) => {

    const [data, setData] = useState([])

    const convertTimestampToUTC = (timestamp) => {
        // Create a Date object from the timestamp (in milliseconds)
        const date = new Date(timestamp * 1000);

        // Extract UTC components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');

        // Construct the final formatted string
        return `${day}.${month}.${year} ${hours}:${minutes} UTC`;
    }

    useEffect(()=>{
        let arr = Array()
        open.events.forEach((event) => {
            arr.push({
                startDate: convertTimestampToUTC(event.startTime),
                endDate: convertTimestampToUTC(event.endTime),
                description: event.description
            })
        })
        setData(arr)
    },[open])

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open.open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Таблица событий</DialogTitle>
                <DialogContent>
                    {data.length == 0 ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <Typography variant="h6">Нет данных</Typography>
                        </Box>
                    ):(
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Дата начала</TableCell>
                                        <TableCell>Дата окончания</TableCell>
                                        <TableCell>Описание</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.startDate}</TableCell>
                                            <TableCell>{row.endDate}</TableCell>
                                            <TableCell>{row.description}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ок
                    </Button>
                </DialogActions>
        </Dialog>
    );
};

export default EventListDialog;
