import { React, Component, useState, useEffect, createContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import Pagination from 'bulma-pagination-react';
import moment from 'moment';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

import { getIsAreasProcessing } from '../../store/selectors';
import { getCounterConsumptionRange, getCounterConsumptionSamplingRange } from '../../store/actions';
import RocketCalendar from '../../components/Calendar/RocketCalendar';

export default function CounterStats({areas}) {
    const dispatch = useDispatch();

    const date_switch_list = [
        {title:'Часы', is_active: true, sampling:'1h'},
        {title:'Дни', is_active: false, sampling:'1d'},
        //{title:'Недели', is_active: false, sampling:'1w'},
        {title:'Месяцы', is_active: false, sampling:'1M'},
        {title:'Года', is_active: false, sampling:'1Y'}
    ]

    const COLORS = [
        '#4dc9f6',
        '#f67019',
        '#f53794',
        '#537bc4',
        '#acc236',
        '#166a8f',
        '#00a950',
        '#58595b',
        '#8549ba'
    ];

    const [counterConsumptions, setCounterConsumptions] = useState({});
    const [dateList, setDateList] = useState(date_switch_list);
    const [dateIndex, setDateIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isChartLoading, setIsChartLoading] = useState(true);

    const [areaIndex, setAreaIndex] = useState(0);
    const [startDateValue, setStartDateValue] = useState(moment().subtract(1, 'day').toDate())
    const [endDateValue, setEndDateValue] = useState(new Date())
    const [calendarVisible, setCalendarVisible] = useState({visible:false, mode:'start', date:startDateValue});

    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);

    const fetchCounterConsumptionRange = async (id, startDate, endDate) => {
        setIsLoading(true)
        const result = await dispatch(getCounterConsumptionRange(id, startDate, endDate))
        setIsLoading(false)
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        }

        setCounterConsumptions(result.payload.data)
    }

    const fetchCounterConsumptionSamplingRange = async (id, sampling, startDate, endDate) => {
        setIsChartLoading(true)
        const result = await dispatch(getCounterConsumptionSamplingRange(id, sampling, startDate, endDate))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        }

        const samplingData = result.payload.data
        
        let dataSample = []
        Object.keys(samplingData).map((value, index) => {
            const date = new Date(value * 1000).toUTCString()
            const humanTime = moment(date).utc().format((dateIndex == 0) ? "DD/MM/YYYY HH:00" : "DD/MM/YYYY")

            const counterConsumptionList = samplingData[value]
            counterConsumptionList.map((item, index) => {
                const keys = Object.keys(item)
                if(keys.length > 0) {
                    const counterSN = keys[0]
                    const counterConsumption = item[counterSN]

                    const data = {
                        label: counterSN,
                        data: [{x:humanTime, y:counterConsumption}],
                        stack: 'Stack ' + index,
                        backgroundColor: COLORS[index]
                    }
                    dataSample.push(data)
                }
            });
        });

        setIsChartLoading(false)
        setChartData(dataSample)

        // let times = Array()
        // let consumptions = {}
        // Object.keys(samplingData).map((value, index) => {
        //     const date = new Date(value * 1000).toUTCString()
        //     const humanTime = moment(date).utc().format((dateIndex == 0) ? "DD/MM/YYYY HH:00" : "DD/MM/YYYY")
        //     times.push(humanTime)

        //     const counterConsumptionList = samplingData[value]
        //     counterConsumptionList.map((item, index) => {
        //         const keys = Object.keys(item)
        //         if(keys.length > 0) {
        //             const counterSN = keys[0]
        //             const counterConsumption = item[counterSN]
                    
        //             if(!(counterSN in consumptions))   {
        //                 consumptions[counterSN] = []
        //             }

        //             consumptions[counterSN].push(counterConsumption / 1000)
        //         }
        //     })
        // })

        // setIsChartLoading(false)
        
        // setChartLabels(times)

        // let dataSample = []
        // Object.keys(consumptions).map((value, index) => {
            // const data = {
            //     label: value,
            //     data: consumptions[value],
            //     stack: 'Stack '+index,
            //     backgroundColor:COLORS[index]
            // }
        //     dataSample.push(data)
        // })
        
        // setChartData(dataSample)
    }

    useEffect( () => {
        const startDateString = moment(startDateValue).format('YYYY-MM-DDT00:00:00');
        const endDateString = moment(endDateValue).format('YYYY-MM-DDT23:59:59');
        let sampling = dateList[dateIndex]['sampling']

        fetchCounterConsumptionRange(areas[areaIndex].id, startDateString, endDateString)
        fetchCounterConsumptionSamplingRange(areas[areaIndex].id, sampling, startDateString, endDateString)
    }, [areaIndex, startDateValue, endDateValue]);

    useEffect( () => {
        const startDateString = moment(startDateValue).format('YYYY-MM-DDT00:00:00');
        const endDateString = moment(endDateValue).format('YYYY-MM-DDT23:59:59');

        let sampling = dateList[dateIndex]['sampling']

        fetchCounterConsumptionSamplingRange(areas[areaIndex].id, sampling, startDateString, endDateString)
    }, [dateIndex]);

    const onSwitchStage = async (index) => {
        setDateList([
            {title:'Часы', is_active: (index == 0), sampling:'1h'},
            {title:'Дни', is_active: (index == 1), sampling:'1d'},
            //{title:'Недели', is_active: (index == 2), sampling:'1w'},
            {title:'Месяцы', is_active: (index == 3), sampling:'1M'},
            {title:'Года', is_active: (index == 4), sampling:'1Y'}
        ])
        setDateIndex(index)
        //setDateRangeByIndex(index)
    }

    // const setDateRangeByIndex = async (index) => {
    //     var now = new Date();

    //     let startDate;
    //     let endDate;
    //     switch(index){
    //         case 0:
    //             startDate = moment(now).subtract(1, 'days')
    //             endDate = moment(now)
    //             break
    //         case 1:
    //             startDate = moment(now).subtract(7, 'days')
    //             endDate = moment(now)
    //             break
    //         case 2:
    //             startDate = moment(now).subtract(30, 'days')
    //             endDate = moment(now)
    //             break
    //         default:
    //             startDate = moment(now).subtract(1, 'days')
    //             endDate = moment(now)
    //     }

        
    //     var startDateString = startDate.format('YYYY-MM-DD');
    //     var endDateString = endDate.format('YYYY-MM-DD');
    //     setDateRange({start:startDateString, end:endDateString})
    // }

    const onChangeValueCalendar = async (settings) => {
        if(settings.mode == 'start')    {
            setStartDateValue(settings.date)
        } else if(settings.mode == 'end')   {
            setEndDateValue(settings.date)
        }

        setCalendarVisible(settings)
    }

    const onSwitchArea = async (index) => {
        setAreaIndex(index)
    }

    //{/* Счетчик {index+1} № */}
    return (
        <>
        <div className="column is-full">
            <div className="columns is-multiline is-centered">
                <div className='column is-full'>
                    <div className="card mt-4 mx-4" style={{height:'60px'}}>
                        <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-center' style={{height:'100%'}}>
                            <button className="button is-outlined is-centered mr-5" onClick={()=>{
                                setCalendarVisible({visible:true, mode:'start', date:startDateValue})
                            }}>{moment(startDateValue).format('YYYY-MM-DD')}</button>
                            <button className="button is-outlined is-centered" onClick={()=>{
                                setCalendarVisible({visible:true, mode:'end', date:endDateValue})
                            }}>{moment(endDateValue).format('YYYY-MM-DD')}</button>
                        </div>
                    </div>
                    <div className="card mx-4 mt-4" style={{height:'40px'}}>
                        <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-center' style={{height:'100%'}}>
                            <nav className="breadcrumb has-bullet-separator is-centered" aria-label="breadcrumbs">
                                    <ul>
                                        {
                                            areas.map((item, index) => {
                                                return (
                                                    <li key={index} className={(areaIndex == index) ? "is-active": ""}>
                                                        <a href="#" onClick={()=>{onSwitchArea(index)}}>{item.name}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="card mt-4 mx-4 is-vcentered mt-1" style={{height:'40px'}}>
                        <nav className="breadcrumb has-bullet-separator is-centered pt-2" aria-label="breadcrumbs">
                            <ul>
                                {
                                    dateList.map((item, index) => {
                                        return (
                                            <li key={index} className={(item.is_active) ? "is-active": ""}>
                                                <a href="#" onClick={()=>{onSwitchStage(index)}}>{item.title}</a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {
                (isLoading) ? (
                    <div className='hero is-flex is-centered'>
                        <div className="container has-text-centered mt-4"> 
                            <Oval
                                height={40}
                                width={40}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}/>
                        </div>
                    </div>
                ) : (
                        (counterConsumptions.hasOwnProperty('items'))?(
                            <nav className="level">
                                <div className="level-item has-text-centered">
                                    <div>
                                        <p className="heading">расход за период</p>
                                        <p className="title">{counterConsumptions['total_consumption']/1000}</p>
                                        <p className="subtitle mt-1">кВт/ч</p>
                                    </div>
                                </div>
                                {
                                    (counterConsumptions.hasOwnProperty('items') && counterConsumptions['items'].length > 0) ? (
                                        counterConsumptions['items'].map((item, index) => {
                                            return (
                                                <div className="level-item has-text-centered">
                                                    <div>
                                                        
                                                        <p className="heading">{item.serial_number}</p>
                                                        <p className="title">{item.consumption/1000}</p>
                                                        <p className="subtitle mt-1">кВт/ч</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </nav>
                        ) : (
                            <div className='hero is-flex is-centered'>
                                <div className="container has-text-centered mt-4"> 
                                    <>Нет данных</>
                                </div>
                            </div>
                        )
                )
            }

            <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-center' style={{height:'100%'}}>
                {
                (isChartLoading) ? (
                    <div className='hero is-flex is-centered'>
                        <div className="container has-text-centered mt-4"> 
                            <Oval
                                height={40}
                                width={40}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}/>
                        </div>
                    </div>
                ) : (
                    <div style={{width:'80%', height:'80%'}}>
                        <Bar options={
                            {
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'График потребления'
                                    },
                                    legend: {
                                        display: false
                                    },
                                },
                                responsive: true,
                                interaction: {
                                    intersect: false,
                                },
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true
                                    }
                                },
                            }
                        } data={{
                            //labels: chartLabels,
                            datasets: chartData
                        }}/>
                    </div>
                )
                }
            </div>

            <RocketCalendar visible_settings={calendarVisible} handle={(settings)=>{onChangeValueCalendar(settings)}} />
        </div>
        </>
    );
}

