import { React, Component, useState, useEffect, useRef, createContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";
import { Oval } from  'react-loader-spinner'
import { getCurrentUser } from "../../store/selectors";
import { isTechUser } from '../../utils/access';
import moment from 'moment';
import { FaSortAlphaDown, FaSortAlphaUpAlt } from 'react-icons/fa';
import { MdFilterListAlt } from "react-icons/md";
import useWindowDimensions from './useWindowDimensions';

import { getAreasList, getDeviceList, getDevicePropertiesList, getTotalHashrateHistory, getTotalDeviceHistory, rebootDevice, pingDevice, changeBeingModeForDevice, getTaskInfo, updateDeviceField } from '../../store/actions';
import {
  getIsAreasLoading, getIsDevicesLoading
} from '../../store/selectors';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import RebootDeviceModal from '../../components/RebootDevices/RebootDeviceModal';
import DeviceHashrateHistory from './DeviceHH';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const DeviceContext = createContext('');

export default function Home() {
    const csvLinkEl = useRef(null)
    const tableRef = useRef(null);

    const headers = [
        { label: "Объект", key: "location_name" },
        { label: "IP", key: "ip" },
        { label: "Помещение", key: "room" },
        { label: "Линия", key: "line" },
        { label: "Место", key: "place" },
        { label: "Модель", key: "model" },
        { label: "S/N", key: "sn" },
        { label: "MAC address", key: "mac" },
        { label: "Worker", key: "worker" },
        { label: "ИМЯ", key: "worker_edit" },
        { label: "Температура", key: "temperature" },
        { label: "FAN SPEED", key: "fan_speed" },
        { label: "Хешрейт", key: "hashrate" },
        { label: "Хешрейт ideal", key: "hashrate_ideal" },
        { label: "Кол-во хешплат", key: "chain_num" },
        { label: "Онлайн", key: "is_online" },
        { label: "Runtime", key: "runtime" },
        { label: "ПУЛ/ПОРТ", key: "pools_url" },
        { label: "ШАРЫ REJECTED", key: "pool_rejected" },
        { label: "Чипы work:rejected", key: "chain_asic" },
        { label: "ИМЯ ПУЛА", key: "name" },
        { label: "ПРОШИВКА", key: "compile_time" },
      ];

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => getCurrentUser(state))

    const { width:windowHeight, height:windowWidth } = useWindowDimensions();

    const [data, setData] = useState([]);

    const [areas, setAreas] = useState([]);
    const [areaIndex, setAreaIndex] = useState(0);
    const [devices, setDevices] = useState([]);
    const isAreaLoading = useSelector(state => getIsAreasLoading(state));
    const isDeviceLoading = useSelector(state => getIsDevicesLoading(state));

    const [rebootDeviceShow, setRebootDeviceShow] = useState({visible:false, mode:'sleep'});

    const [allDeviceCount, setAllDeviceCount] = useState(0);
    const [areaDeviceCount, setAreaDeviceCount] = useState(0);

    const [referenceHashrate, setReferenceHashrate] = useState(0);
    const [areaAggHashrate, setAreaAggHashrate] = useState(0);
    const [missingHashrate, setMissingHashrate] = useState(0);
    const [aggHashrate, setAggHashrate] = useState(0);

    const [deviceProperties, setDeviceProperties] = useState([]);
    const [isDevicePropertiesLoading, setIsDevicePropertiesLoading] = useState(false);
    const [devicePropertiesSource, setDevicePropertiesSource] = useState([]);

    const [selectedDevice, setSelectedDevice] = useState(null);

    const [isHashrateHistoryAppear, setIsHashrateHistoryAppear] = useState(false);
    const [isHashrateHistoryLoading, setIsHashrateHistoryLoading] = useState(false);
    const [totalHashrateHistory, setTotalHashrateHistory] = useState([]);
    const [totalHashrateChartData, setTotalHashrateChartData] = useState({
        labels: [],
        datasets: [
        ]
    });

    const [isDeviceHistoryAppear, setIsDeviceHistoryAppear] = useState(false);
    const [isDeviceHistoryLoading, setIsDeviceHistoryLoading] = useState(false);
    const [totalDeviceHistory, setTotalDeviceHistory] = useState([]);
    const [totalDeviceChartData, setTotalDeviceChartData] = useState({
        labels: [],
        datasets: [
        ]
    });

    const [isIDMenuActive, setIsIDMenuActive] = useState(false);

    const SortDirection = {
        Ascending: 1,
        Descending: 2,
        Default: 0
    }

    const idBtnRef = useRef()
    const ipBtnRef = useRef()
    const roomBtnRef = useRef()
    const lineBtnRef = useRef()
    const placeBtnRef = useRef()
    const modelBtnRef = useRef()
    const snBtnRef = useRef()
    const macBtnRef = useRef()
    const workerBtnRef = useRef()
    const workerEditBtnRef = useRef()
    const temperatureBtnRef = useRef()
    const fanSpeedBtnRef = useRef()
    const hashrateBtnRef = useRef()
    const hashrateIdealBtnRef = useRef()
    const chainNumBtnRef = useRef()
    const isOnlineBtnRef = useRef()
    const runtimeBtnRef = useRef()
    const poolsUrlBtnRef = useRef()
    const poolRejectedBtnRef = useRef()
    const chainAsicBtnRef = useRef()
    const nameBtnRef = useRef()
    const compileTimeBtnRef = useRef()

    const [filterVisiblePopup, setFilterVisiblePopup] = useState({
        id: {
            isVisible: false,
        },
        ip: {
            isVisible: false,
        },
        room: {
            isVisible: false,
        },
        line: {
            isVisible: false,
        },
        place: {
            isVisible: false,
        },
        model: {
            isVisible: false,
        },
        sn: {
            isVisible: false,
        },
        mac: {
            isVisible: false,
        },
        worker: {
            isVisible: false,
        },
        worker_edit: {
            isVisible: false,
        },
        temperature: {
            isVisible: false,
        },
        fan_speed: {
            isVisible: false,
        },
        hashrate: {
            isVisible: false,
        },
        hashrate_ideal: {
            isVisible: false,
        },
        chain_num: {
            isVisible: false,
        },
        is_online: {
            isVisible: false,
        },
        runtime: {
            isVisible: false,
        },
        pools_url: {
            isVisible: false,
        },
        pool_rejected: {
            isVisible: false,
        },
        chain_asic: {
            isVisible: false,
        },
        name: {
            isVisible: false,
        },
        compile_time: {
            isVisible: false,
        }
    })
    const [filterContent, setFilterContent] = useState({
        id: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        ip: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        room: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        line: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        place: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        model: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        sn: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        mac: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        worker: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        worker_edit: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        temperature: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        fan_speed: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        hashrate: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        hashrate_ideal: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        chain_num: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        is_online: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        runtime: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        pools_url: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        pool_rejected: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        chain_asic: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        name: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        },
        compile_time: {
            sort: SortDirection.Default,
            min: 0,
            max: 0,
            isRangeOn: false,
            render: false
        }
    })

    const [filterRangeContent, setFilterRangeContent] = useState({
        id: {
            min: 0,
            max: 0,
        },
        ip: {
            min: 0,
            max: 0,
        },
        room: {
            min: 0,
            max: 0,
        },
        line: {
            min: 0,
            max: 0,
        },
        place: {
            min: 0,
            max: 0,
        },
        model: {
            min: 0,
            max: 0,
        },
        sn: {
            min: 0,
            max: 0,
        },
        mac: {
            min: 0,
            max: 0,
        },
        worker: {
            min: 0,
            max: 0,
        },
        worker_edit: {
            min: 0,
            max: 0,
        },
        temperature: {
            min: 0,
            max: 0,
        },
        fan_speed: {
            min: 0,
            max: 0,
        },
        hashrate: {
            min: 0,
            max: 0,
        },
        hashrate_ideal: {
            min: 0,
            max: 0,
        },
        chain_num: {
            min: 0,
            max: 0,
        },
        is_online: {
            min: 0,
            max: 0,
        },
        runtime: {
            min: 0,
            max: 0,
        },
        pools_url: {
            min: 0,
            max: 0,
        },
        pool_rejected: {
            min: 0,
            max: 0,
        },
        chain_asic: {
            min: 0,
            max: 0,
        },
        name: {
            min: 0,
            max: 0,
        },
        compile_time: {
            min: 0,
            max: 0,
        }
    })

    const [rerender, setRerender] = useState(false);
    const fieldNoWarningColor = 'is-size-7 has-text-centered is-vcentered'
    const fieldWarningColor = 'is-size-7 has-text-centered is-vcentered has-text-danger'

    useEffect( () => { 
        const fetchAreasData = async () => {
            const result = await dispatch(getAreasList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setAreas(result.payload.data)
        }

        fetchAreasData();
    }, []);

    useEffect(() => {
        const fetchAllDevices = async (area_uuid) => {
            const result = await dispatch(getDeviceList(area_uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setDevices(result.payload.data)
        }

        if(areas.length > 0)    {
            // setAreaAggHashrate(0)
            // setMissingHashrate(0)
            // setAggHashrate(0)
            // setAreaDeviceCount(0)
            fetchAllDevices(areas[areaIndex].id);
        }
    },[areas, areaIndex])

    useEffect(() => {
        if(areas.length > 0)    {
            setAllDeviceCount(areas[areaIndex]?.device_count)
            setReferenceHashrate(areas[areaIndex]?.reference_hashrate)
        }
    },[areas, areaIndex])

    useEffect(() => {
        setAreaDeviceCount(devices.length)

        const sum = devices.reduce((prev, next) => {
            return prev + next.hashrate
        }, 0)
        const aggValue = (sum / devices.length).toFixed(2)

        try {
            const rateUnit = devices[0].rate_unit;
            const tfHashrate = hashrateTransformation(aggValue, rateUnit)
            setAreaAggHashrate(tfHashrate.toFixed(3))
            setMissingHashrate((referenceHashrate - tfHashrate).toFixed(2))

            const aggHashrate = hashrateTransformation(sum, rateUnit)
            setAggHashrate(aggHashrate)
        } catch(e)  {
            console.log(e)
        }
    },[devices, areaIndex])

    useEffect(() => {
        const fetchDevicePropertiesList = async (area_uuid) => {
            setIsDevicePropertiesLoading(true)

            const result = await dispatch(getDevicePropertiesList(area_uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setDeviceProperties(result.payload.data)
            setDevicePropertiesSource(result.payload.data)
            setIsDevicePropertiesLoading(false)
        }

        if(areas.length > 0)    {
            fetchDevicePropertiesList(areas[areaIndex].id);
        }
    },[areas, areaIndex])

    // useEffect(() => {
    //     const closeDropdown = e => {

    //         if(idBtnRef.current.contains(e.target))   {
    //             const tagValue = filterVisiblePopup['id']
    //             Object.keys(filterVisiblePopup).forEach(function(key) {
    //                 if(key !== 'id') {
    //                     filterVisiblePopup[key].isVisible = false
    //                 }
    //             });
    //             setFilterVisiblePopup({
    //                 ...filterVisiblePopup,
    //                 id: {
    //                     ...tagValue,
    //                     isVisible: true
    //                 }
    //             })
    //         } else if(typeBtnRef.current.contains(e.target))   {
    //             const tagValue = filterVisiblePopup['model']
    //             Object.keys(filterVisiblePopup).forEach(function(key) {
    //                 if(key !== 'model') {
    //                     filterVisiblePopup[key].isVisible = false
    //                 }
    //             });
    //             setFilterVisiblePopup({
    //                 ...filterVisiblePopup,
    //                 model: {
    //                     ...tagValue,
    //                     isVisible: true
    //                 }
    //             })
    //         } else if(macBtnRef.current.contains(e.target))   {
    //             const tagValue = filterVisiblePopup['mac']
    //             Object.keys(filterVisiblePopup).forEach(function(key) {
    //                 if(key !== 'mac') {
    //                     filterVisiblePopup[key].isVisible = false
    //                 }
    //             });
    //             setFilterVisiblePopup({
    //                 ...filterVisiblePopup,
    //                 mac: {
    //                     ...tagValue,
    //                     isVisible: true
    //                 }
    //             })
    //         } else if(tempBtnRef.current.contains(e.target))   {
    //             const tagValue = filterVisiblePopup['temperature']
    //             Object.keys(filterVisiblePopup).forEach(function(key) {
    //                 if(key !== 'temperature') {
    //                     filterVisiblePopup[key].isVisible = false
    //                 }
    //             });
    //             setFilterVisiblePopup({
    //                 ...filterVisiblePopup,
    //                 temperature: {
    //                     ...tagValue,
    //                     isVisible: true
    //                 }
    //             })
    //         } else if(countBtnRef.current.contains(e.target))   {
    //             const tagValue = filterVisiblePopup['chain_num']
    //             Object.keys(filterVisiblePopup).forEach(function(key) {
    //                 if(key !== 'chain_num') {
    //                     filterVisiblePopup[key].isVisible = false
    //                 }
    //             });
    //             setFilterVisiblePopup({
    //                 ...filterVisiblePopup,
    //                 chain_num: {
    //                     ...tagValue,
    //                     isVisible: true
    //                 }
    //             })
    //         } else if(hashrateBtnRef.current.contains(e.target))   {
    //             const tagValue = filterVisiblePopup['hashrate']
    //             Object.keys(filterVisiblePopup).forEach(function(key) {
    //                 if(key !== 'hashrate') {
    //                     filterVisiblePopup[key].isVisible = false
    //                 }
    //             });
    //             setFilterVisiblePopup({
    //                 ...filterVisiblePopup,
    //                 hashrate: {
    //                     ...tagValue,
    //                     isVisible: true
    //                 }
    //             })
    //         } else  {
    //             console.log(e.target.className)
    //             if(e.target.className !== 'input')  {
    //                 //Object.keys(filterVisiblePopup).forEach(function(key) {
    //                     setFilterVisiblePopup({
    //                         id: {
    //                             isVisible: false,
    //                         },
    //                         model: {
    //                             isVisible: false,
    //                         },
    //                         mac: {
    //                             isVisible: false,
    //                         },
    //                         temperature: {
    //                             isVisible: false,
    //                         },
    //                         chain_num: {
    //                             isVisible: false,
    //                         },
    //                         hashrate: {
    //                             isVisible: false,
    //                         },
    //                     })
    //                 //});
    //             }
    //         }
    //     }
    //     document.body.addEventListener('click', closeDropdown)

    //     return () => document.body.removeEventListener('click', closeDropdown)
    // })

    useEffect(() => {
        const closeDropdown = e => {

            if(idBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['id']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'id') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    id: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(ipBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['ip']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'ip') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    ip: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(roomBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['room']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'room') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    room: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(lineBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['line']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'line') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    line: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(placeBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['place']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'place') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    place: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(modelBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['model']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'model') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    model: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(snBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['sn']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'sn') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    sn: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(macBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['mac']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'mac') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    mac: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(workerBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['worker']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'worker') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    worker: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(workerEditBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['worker_edit']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'worker_edit') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    worker_edit: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(temperatureBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['temperature']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'temperature') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    temperature: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(hashrateBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['hashrate']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'hashrate') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    hashrate: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(hashrateIdealBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['hashrate_ideal']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'hashrate_ideal') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    hashrate_ideal: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(chainNumBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['chain_num']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'chain_num') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    chain_num: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(runtimeBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['runtime']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'runtime') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    runtime: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else if(nameBtnRef.current.contains(e.target))   {
                const tagValue = filterVisiblePopup['name']
                Object.keys(filterVisiblePopup).forEach(function(key) {
                    if(key !== 'name') {
                        filterVisiblePopup[key].isVisible = false
                    }
                });
                setFilterVisiblePopup({
                    ...filterVisiblePopup,
                    name: {
                        ...tagValue,
                        isVisible: true
                    }
                })
            } else {
                if(e.target.className !== 'input')  {
                    //Object.keys(filterVisiblePopup).forEach(function(key) {
                        setFilterVisiblePopup({
                            id: {
                                isVisible: false,
                            },
                            ip: {
                                isVisible: false,
                            },
                            room: {
                                isVisible: false,
                            },
                            line: {
                                isVisible: false,
                            },
                            place: {
                                isVisible: false,
                            },
                            model: {
                                isVisible: false,
                            },
                            sn: {
                                isVisible: false,
                            },
                            mac: {
                                isVisible: false,
                            },
                            worker: {
                                isVisible: false,
                            },
                            worker_edit: {
                                isVisible: false,
                            },
                            temperature: {
                                isVisible: false,
                            },
                            fan_speed: {
                                isVisible: false,
                            },
                            hashrate: {
                                isVisible: false,
                            },
                            hashrate_ideal: {
                                isVisible: false,
                            },
                            chain_num: {
                                isVisible: false,
                            },
                            is_online: {
                                isVisible: false,
                            },
                            runtime: {
                                isVisible: false,
                            },
                            pools_url: {
                                isVisible: false,
                            },
                            pool_rejected: {
                                isVisible: false,
                            },
                            chain_asic: {
                                isVisible: false,
                            },
                            name: {
                                isVisible: false,
                            },
                            compile_time: {
                                isVisible: false,
                            }
                        })
                    //});
                }
            }
        }
        document.body.addEventListener('click', closeDropdown)

        return () => document.body.removeEventListener('click', closeDropdown)
    })

    const getUserList = async () => {
        return await fetch('https://jsonplaceholder.typicode.com/users')
          .then(res => res.json());
    }

    const dataDownloader = async () => {
        const data = await getUserList();
        await setData(data)
        setTimeout(() => {
            csvLinkEl.current.link.click();
        });
    }

    const onShowHashrateHistory = async (range_T='1h') => {
        setIsHashrateHistoryAppear(true)
        setIsHashrateHistoryLoading(true)
        const result = await dispatch(getTotalHashrateHistory(areas[areaIndex].id, range_T))
        if(!result.success) {
            setIsHashrateHistoryLoading(false)
            setIsHashrateHistoryAppear(false)
            return Swal.fire({text:result.message, icon: 'error'});
        }

        let x = []
        let y = []
        result.payload.data.map((obj) => {
            x.push(new Date(Date.parse(obj.time)).toLocaleString())
            y.push(obj.hashrate)
        })

        setTotalHashrateChartData({
            labels:x,
            datasets: [
                {
                  label: 'Hashrate',
                  data: y,
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
            options: {
                maintainAspectRatio: false,
            },
            range_t:range_T
        })

        setTotalHashrateHistory(result.payload.data)
        setIsHashrateHistoryLoading(false)

        tableRef.current.scrollIntoView();
        window.scrollTo(0, 100000);
        tableRef.current.scrollTo(0, 10000)
    }

    const onHideHashrateHistory = async () => {
        setIsHashrateHistoryLoading(false)
        setIsHashrateHistoryAppear(false)
    }

    const onShowDeviceHistory = async (range_T='1h') => {
        setIsDeviceHistoryAppear(true)
        setIsDeviceHistoryLoading(true)
        const result = await dispatch(getTotalDeviceHistory(areas[areaIndex].id, range_T))
        if(!result.success) {
            setIsDeviceHistoryLoading(false)
            setIsDeviceHistoryAppear(false)
            return Swal.fire({text:result.message, icon: 'error'});
        }

        let x = []
        let y = []
        result.payload.data.map((obj) => {
            x.push(new Date(Date.parse(obj.time)).toLocaleString())
            y.push(obj.count)
        })

        setTotalDeviceChartData({
            labels:x,
            datasets: [
                {
                  label: 'Device count',
                  data: y,
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
            options: {
                maintainAspectRatio: false,
            },
            range_t:range_T
        })

        setTotalDeviceHistory(result.payload.data)
        setIsDeviceHistoryLoading(false)
    }

    const onHideDeviceHistory = async () => {
        setIsDeviceHistoryLoading(false)
        setIsDeviceHistoryAppear(false)
    }

    const onHandleFilterToggle = async (tag) => {
        Object.keys(filterContent).forEach(function(key) {
            if(key != tag)  {
                filterContent[key].isVisible = false
            }
        });

        const tagValue = filterContent[tag]

        setFilterContent({
            ...filterContent,
            [tag]: {
                ...tagValue,
                isVisible:!tagValue.isVisible
            }
        })
    }

    useEffect(() => {
        onFilterContentUpdate()
    }, [filterContent]);

    const onFilterSortChange = async (tag, direction) => {
        Object.keys(filterContent).forEach(function(key) {
            if(key != tag)  {
                filterContent[key].sort = SortDirection.Default
            }
        });

        setFilterContent({
            ...filterContent,
            [tag]: {
                ...filterContent[tag],
                sort: direction
            }
        })
    }

    const onFilterRangeAccept = async (tag) => {
        Object.keys(filterContent).forEach(function(key) {
            if(key != tag)  {
                filterContent[key].isRangeOn = false
                filterContent[key].min = 0
                filterContent[key].max = 0
            }
        });

        setFilterContent({
            ...filterContent,
            [tag]: {
                ...filterContent[tag],
                isRangeOn: true,
                min: filterRangeContent[tag].min,
                max: filterRangeContent[tag].max
            }
        })
    }

    const onFilterClear = async (tag) => {
        // Object.keys(filterContent).forEach(function(key) {
        //     filterContent[key].sort = SortDirection.Default
        //     filterContent[key].isRangeOn = false
        //     filterContent[key].min = 0
        //     filterContent[key].max = 0
        //     filterRangeContent[key].min = 0
        //     filterRangeContent[key].max = 0
        // });

        filterContent[tag].sort = SortDirection.Default
        filterContent[tag].isRangeOn = false
        filterContent[tag].min = 0
        filterContent[tag].max = 0
        filterRangeContent[tag].min = 0
        filterRangeContent[tag].max = 0

        setFilterContent({
            ...filterContent,
            id: {
                ...filterContent.id,
                render:!filterContent.id.render
            }
        })
    }

    const onFilterContentUpdate = async () => {
        let content = [...deviceProperties]

        Object.keys(filterContent).forEach(function(key) {
            if(filterContent[key].isRangeOn)    {
                try {
                    let filter_content = content.filter((obj) => (obj[key] >= filterContent[key].min && obj[key] <= filterContent[key].max));
                    if(filter_content.length != 0) {
                        content = [...filter_content]
                    } else  {
                        // если ничего не найдено то пустой список
                        content = [...filter_content]
                    }
                } catch (err) {
                    content = [...deviceProperties]
                }
            }
        })

        Object.keys(filterContent).forEach(function(key) {
            if(filterContent[key].sort == SortDirection.Ascending)    {
                content.sort(function(a,b) {
                    if(key == 'model' || key == 'mac')   {
                        return a[key].localeCompare(b[key]) 
                    } 
                    return a[key] - b[key]
                });
            } else if(filterContent[key].sort == SortDirection.Descending)    {
                content.sort(function(a,b) {
                    if(key == 'model' || key == 'mac')   {
                        return b[key].localeCompare(a[key]) 
                    } 
                    return b[key] - a[key]
                });
            } 
        });
        setDevicePropertiesSource(content)
    }

    const isFilterOn = (tag) => {
        //console.log(''+tag+') filterContent[tag].sort '+filterContent[tag].sort+' isRangeOn = '+filterContent[tag].isRangeOn)
        return (filterContent[tag].sort !== SortDirection.Default || filterContent[tag].isRangeOn)
    }

    const onRebootDevice = async (mac) => {
        Swal.fire({
            title: 'Вы уверены?',
            text: "Устройство "+mac+" будет перезагружено",
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Да, перезагрузить'
          }).then((result) => {
            if (result.isConfirmed) {
                onRebootDevice2(mac)
            }
          })
    }

    const onRebootDevice2 = async (mac) => {
        const result = await dispatch(rebootDevice(areas[areaIndex].id, mac))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        } else  {
            //const task_id = result.payload.data['task_id']
            onRebootDeviceRequest('task_id')
        }
    }

    const onRebootDeviceRequest = (task_id) => {
        let timerInterval
        Swal.fire({
        title: 'Ожидаем завершение задачи ',
        html: '<b></b>',
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
                b.textContent = (Swal.getTimerLeft()/1000).toFixed(0)
            }, 1000)
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                onRebootDevice3(task_id)
            } else if(result.dismiss == Swal.DismissReason.backdrop)    {
                //onRebootDevice3()
            }
        })
    }

    const onRebootDevice3 = async (task_id) => {
        // const result = await dispatch(getTaskInfo(task_id))
        // if(!result.success) {
        //     return Swal.fire({text:result.message, icon: 'error'});
        // } else  {
        //     const status = result.payload.data['status']
        //     const error = result.payload.data['error']

        //     if(status == 'failure' || status == 'unknown')    {
        //         Swal.fire(
        //             'Ошибка!',
        //             ''+error,
        //             'error'
        //           )
        //     } else  {
        //         Swal.fire(
        //             'Выполнено!',
        //             'Устройство было перезагружено.',
        //             'success'
        //           )
        //     }
        // }
        Swal.fire(
                    'Выполнено!',
                    'Устройство было перезагружено.',
                    'success'
                  )
    }

    const onPingDevice = async (mac) => {
        let timerInterval
        Swal.fire({
            title: 'Ping',
            html: '<b></b> milliseconds.',
            timer: 1500,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        }).then((result) => {
            onPingDeviceRequest(mac)
        })
    }

    const onPingDeviceRequest = async (mac) => {
        const result = await dispatch(pingDevice(areas[areaIndex].id, mac))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        } else  {
            // ...
        }
    }

    const onChangeBeingMode = async (mac, isSleep) => {
        Swal.fire({
            title: 'Вы уверены?',
            text: (isSleep) ? "Устройство "+mac+" будет переведено в спящий режим": "Устройство "+mac+" будет разбужено",
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Да'
          }).then((result) => {
            if (result.isConfirmed) {
                onChangeBeingMode2(mac, isSleep)
            }
          })
    }

    const onChangeBeingMode2 = async (mac, isSleep) => {
        const result = await dispatch(changeBeingModeForDevice(areas[areaIndex].id, mac, isSleep))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        } else  {
            Swal.fire(
                'Выполнено!',
                (isSleep)?'Устройство было переведено в спящий режим.':'Устройство активировано.',
                'success'
            )
        }
    }

    const onSwitchArea = async (index) => {
        setAreaIndex(index)
    }

    const changeFieldNameByMac = async (name, mac, text) => {

        let title = ''
        switch(name)    {
            case 'room':
                title = 'Введите название помещения';
                break;
            case 'line':
                title = 'Введите номер линии';
                break;
            case 'sn':
                title = 'Введите серийный номер устройства';
                break;
            case 'place':
                title = 'Введите название стеллажа, полки и места через точку';
                break;
            case 'worker_edit':
                title = 'Введите название воркера';
                break;
            default:
                title = 'Введите что-то';
        }

        const { value: newTextData } = await Swal.fire({
            title: title,
            input: 'text',
            inputValue: (text == null) ? '' : text,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Вам нужно что-то написать!'
                }
            }
        })

        if(newTextData) {
            setDevicePropertiesSource(prevState => {
                const eduObjIdx = prevState.findIndex(obj => obj.mac === mac)
                if(eduObjIdx >= 0)  {
                    prevState[eduObjIdx][name] = newTextData
                }
                return [
                    ...prevState
                ]
            })

            const result = await dispatch(updateDeviceField(areas[areaIndex].id, mac, name, newTextData))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
        }
    }

    const isWarningColumn = (name) => {
        for(const device of devicePropertiesSource)   {
            const meta = JSON.parse(device.meta)
            if(meta.hasOwnProperty(name))   {
                return true
            }
        }
        return false
    }

    const handleRebootModal = status => {
        setRebootDeviceShow(status); 
    }

    const handleGetAreaUuid = status => {
        return areas[areaIndex]['id']
    }

    // transform to th/s
    const hashrateTransformation = (hashrate, rateUnit) => {
        let _multiply = 0
        switch (rateUnit)   {
            case 'mh/s':
                _multiply = 1000000;
                break;
            case 'gh/s':
                _multiply = 1000;
                break;
            default:
                _multiply = 1000;
        }

        const tfHash = hashrate / _multiply;
        return tfHash;
    }

    const cretaeTH = (key, btnRef, title, withFilter=false, withReootButton=false) => {
        const isWarning = isWarningColumn(key)
        const renderTitle = (isWarning) ? '⚠ '+title : title
        
        return (
            <div className={(filterVisiblePopup[key].isVisible) ? "dropdown is-active mt-4": "dropdown mt-4"} style={{backgroundColor:'#ffffff'}}>
                <div className="dropdown-trigger">
                    <button ref={btnRef} className={(isWarning)?"button is-ghost has-text-danger":"button is-ghost"} aria-haspopup="true" aria-controls="dropdown-menu">
                        <abbr title="Index">{
                            (isFilterOn(key)) ? 
                                (<div>
                                    <strong>{renderTitle}</strong>
                                    {
                                        ((filterContent[key].sort == SortDirection.Ascending)?
                                            <FaSortAlphaDown style={{marginLeft:'10px', verticalAlign:'middle'}}/>
                                            : (filterContent[key].sort == SortDirection.Descending)?
                                                <FaSortAlphaUpAlt style={{marginLeft:'10px', verticalAlign:'middle'}}/>
                                                : <></>)                                    
                                    }
                                    {
                                        ((filterContent[key].isRangeOn)?
                                            <MdFilterListAlt style={{marginLeft:'10px', verticalAlign:'middle'}}/>
                                            : <></>)
                                    }
                                </div>) :
                                (<>{renderTitle}</>)
                            }
                        </abbr>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu1" role="menu">
                    <div className="dropdown-content">
                        <Link className="dropdown-item" onMouseDown={() => onFilterSortChange(key, SortDirection.Ascending)}>
                            Сортировать от А до Я
                        </Link>
                        <Link className="dropdown-item" onMouseDown={() => onFilterSortChange(key, SortDirection.Descending)}>
                            Сортировать от Я до А
                        </Link>
                        <hr className="dropdown-divider"></hr>
                        {(withFilter)? (
                            <div>
                                <div className="dropdown-item">
                                    <input className="input" type="number" placeholder="От" value={filterRangeContent[key].min || ''} onChange={(e) => setFilterRangeContent({...filterRangeContent, [key]:{...filterRangeContent[key], min:e.target.value}})}></input>
                                    <input className="input" type="number" placeholder="До" value={filterRangeContent[key].max || ''} onChange={(e) => setFilterRangeContent({...filterRangeContent, [key]:{...filterRangeContent[key], max:e.target.value}})}></input>
                                </div>
                                <Link className="dropdown-item" onMouseDown={() => onFilterRangeAccept(key)}>
                                    Применить
                                </Link>
                            </div>
                        ):<></>}
                        {(withReootButton)? (
                            <div>
                                <Link className="dropdown-item has-text-primary" onMouseDown={() => {setRebootDeviceShow({visible:true, mode:'normal'})}}>
                                    Switch group to Normal mode
                                </Link>
                                <Link className="dropdown-item has-text-danger" onMouseDown={() => {setRebootDeviceShow({visible:true, mode:'sleep'})}}>
                                    Switch group to Sleep mode
                                </Link>
                            </div>
                        ):<></>}
                        <Link className="dropdown-item " onMouseDown={() => onFilterClear(key)}>
                            Очистить
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    const formatTime = (value) => {
        var
        d = Math.trunc(value / 60    / 60   / 24).toString().padStart(2, '0'),
        h = Math.trunc(value % 86400 / 60   / 60).toString().padStart(2, '0'),
        m = Math.trunc(value % 86400 % 3600 / 60).toString().padStart(2, '0'),
        s = Math.trunc(value % 86400 % 3600 % 60).toString().padStart(2, '0');

        return (d > 0) ? d + ' days ' + h + ':' + m + ':' + s : h + ':' + m + ':' + s
    }

    const toTableContainerHeight = (value) => {
        const delta = 54
        return value - (value*delta)/100 + 'px'
    }

    //is-flex is-flex-direction-column
    return (
        <div className="table-container" style={{height:'93vh', overflowY:'scroll'}} >
            <div style={{position:'sticky', left:'0', top:'-10px', background: '#ffffff', zIndex:1}}>
                <div className='columns is-desktop is-fullwidth'>
                    <div className='column is-full'>
                        <div className="columns is-desktop is-centered">
                            <div className="column is-6 is-offset-0">
                                
                                {(areas.length > 1)?(
                                    <nav className="breadcrumb has-bullet-separator is-centered mt-3" aria-label="breadcrumbs">
                                        <ul>
                                            {
                                                areas.map((item, index) => {
                                                    return (
                                                        <li key={index} className={(areaIndex == index) ? "is-active": ""}>
                                                            <a href="#" onClick={()=>{onSwitchArea(index)}}>{item.name}</a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </nav>):(
                                        null
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{position:'sticky', left:'0', background: '#FFFFFF'}}>
                <nav className="mt-1 level is-size-4-mobile is-size-4-tablet is-size-4-desktop">
                    <div className="level-item has-text-centered mt-5">
                        <div>
                            <p className="heading">эталонный хешрейт</p>
                            <p className="title has-text-link">{isAreaLoading ? (<div className='is-flex is-justify-content-center'><div className="loader is-loading"/></div>) : <a onClick={()=>{onShowHashrateHistory('1d')}}>{referenceHashrate}</a>}</p>
                            <p className="subtitle mt-1">TH/s</p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered mt-5">
                        <div>
                            <p className="heading">общий хешрейт</p>
                            <p className="title">{isAreaLoading ? (<div className='is-flex is-justify-content-center'><div className="loader is-loading"/></div>) : aggHashrate}</p>
                            <p className="subtitle mt-1">TH/s</p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered mt-5">
                        <div>
                            <p className="heading">средний хешрейт</p>
                            <p className="title">{isAreaLoading ? (<div className='is-flex is-justify-content-center'><div className="loader is-loading"/></div>) : areaAggHashrate}</p>
                            <p className="subtitle mt-1">TH/s</p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered mt-5">
                        <div>
                            <p className="heading">отклонение по хешрейту</p>
                            <p className="title">{isAreaLoading ? (<div className='is-flex is-justify-content-center'><div className="loader is-loading"/></div>) : missingHashrate}</p>
                            <p className="subtitle mt-1">TH/s</p>
                        </div>
                    </div>
                </nav>
                <nav className="mt-3 level is-size-4-mobile is-size-4-tablet is-size-4-desktop">
                    <div className="level-item has-text-centered">
                        <div>
                            <p className="heading">эталонное количество</p>
                            <p className="title has-text-link">{isAreaLoading ? (<div className='is-flex is-justify-content-center'><div className="loader is-loading"/></div>) : <a onClick={()=>{onShowDeviceHistory('1d')}}>{allDeviceCount}</a>}</p>
                            <p className="subtitle mt-1"></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered">
                        <div>
                            <p className="heading">фактичское количество</p>
                            <p className="title">{isAreaLoading ? (<div className='is-flex is-justify-content-center'><div className="loader is-loading"/></div>) : areaDeviceCount}</p>
                            <p className="subtitle mt-1"></p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered">
                        <div>
                            <p className="heading">отклонение по количеству</p>
                            <p className="title">{isAreaLoading ? (<div className='is-flex is-justify-content-center'><div className="loader is-loading"/></div>) : allDeviceCount - areaDeviceCount}</p>
                            <p className="subtitle mt-1"></p>
                        </div>
                    </div>
                </nav>
            </div>
            <table className="table is-striped is-bordered mt-3 is-fullwidth is-hoverable">
                <thead>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('id', idBtnRef, 'Объект', false, true)
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('ip', ipBtnRef, 'IP')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('room', roomBtnRef, 'Помещение')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('line', lineBtnRef, 'Линия')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('place', placeBtnRef, 'Место')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('model', modelBtnRef, 'Модель')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('sn', snBtnRef, 'S/N')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('mac', macBtnRef, 'MAC address')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('worker_edit', workerEditBtnRef, 'Worker')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('worker', workerBtnRef, 'ИМЯ')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('temperature', temperatureBtnRef, 'Температура', true)
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('fan_speed', fanSpeedBtnRef, 'FAN SPEED')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('hashrate', hashrateBtnRef, 'Хешрейт', true)
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('hashrate_ideal', hashrateIdealBtnRef, 'Хешрейт ideal', true)
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('chain_num', chainNumBtnRef, 'Кол-во хешплат', true)
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('is_online', isOnlineBtnRef, 'Онлайн')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('runtime', runtimeBtnRef, 'Runtime')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('pools_url', poolsUrlBtnRef, 'ПУЛ/ПОРТ')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('pool_rejected', poolRejectedBtnRef, 'ШАРЫ REJECTED')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('chain_asic', chainAsicBtnRef, 'Чипы work:rejected')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('name', nameBtnRef, 'ИМЯ ПУЛА')
                        }
                    </th>
                    <th style={{position:'sticky', top:'40px', background: '#FFFFFF'}}>
                        {
                            cretaeTH('compile_time', compileTimeBtnRef, 'ПРОШИВКА')
                        }
                    </th>
                </thead>
                <tbody ref={tableRef}>
                    {(isDevicePropertiesLoading && !isAreaLoading) ? (
                        devices.map(device => {
                            return (
                                <tr key={device.id}>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                    <td><div className="loader is-loading"></div></td>
                                </tr>
                            )
                        })
                    ) : (
                        devicePropertiesSource.map(device => {
                            const meta = JSON.parse(device.meta)

                            return (
                                <tr key={device.id}>
                                    <td className='is-size-7 has-text-centered is-vcentered'>{device.location_name}</td>
                                    <td className='is-size-7 has-text-centered is-vcentered'>{device.ip}</td>
                                    <td className='is-size-7 has-text-centered is-vcentered'>
                                        <a onClick={()=>{changeFieldNameByMac('room', device['mac'], device['room'])}}>{(device['room'] == null) ? 'unknown' : device['room']}</a>
                                    </td>
                                    <td className='is-size-7 has-text-centered is-vcentered'>
                                        <a onClick={()=>{changeFieldNameByMac('line', device['mac'], device['line'])}}>{(device['line'] == null) ? 'unknown' : device['line']}</a>
                                    </td>
                                    <td className='is-size-7 has-text-centered is-vcentered'>
                                        <a onClick={()=>{changeFieldNameByMac('place', device['mac'], device['place'])}}>{(device['place'] == null) ? 'unknown' : device['place']}</a>
                                    </td>
                                    <td className={meta.hasOwnProperty("model") ? fieldWarningColor : fieldNoWarningColor}>{device.model}</td>
                                    <td className='is-size-7 has-text-centered is-vcentered'>
                                        <a onClick={()=>{changeFieldNameByMac('sn', device['mac'], device['sn'])}}>{(device['sn'] == null) ? 'unknown' : device['sn']}</a>
                                    </td>
                                    <td className='is-size-7 has-text-centered is-vcentered'>
                                        <a onClick={()=>{setSelectedDevice({
                                            area_id: areas[areaIndex].id,
                                            mac: device['mac']
                                        })}}>{device['mac']}</a>
                                    </td>
                                    
                                    <td className='is-size-7 has-text-centered is-vcentered'>
                                        <a onClick={()=>{changeFieldNameByMac('worker_edit', device['mac'], device['worker_edit'])}}>{(device['worker_edit'] == null) ? 'unknown' : device['worker_edit']}</a>
                                    </td>
                                    <td className={meta.hasOwnProperty("worker") ? fieldWarningColor : fieldNoWarningColor}>{device.worker}</td>
                                    <td className={meta.hasOwnProperty("temperature") ? fieldWarningColor : fieldNoWarningColor}>{device.temperature} ℃</td>
                                    <td className={meta.hasOwnProperty("fan_speed") ? fieldWarningColor : fieldNoWarningColor}>
                                        {device['fan_speed']}
                                        {/* <div className="content">
                                            <ol type="1">
                                                {
                                                    (device['fan_speed'] != null)?
                                                        device['fan_speed'].split('|').map((txt, index) => {
                                                            return (
                                                                <li key={index}>{txt}</li>
                                                            )
                                                        }):<></>
                                                }
                                            </ol>
                                        </div> */}
                                    </td>
                                    <td className={meta.hasOwnProperty("hashrate") ? fieldWarningColor : fieldNoWarningColor}>{hashrateTransformation(device.hashrate, device.rate_unit)} TH/s</td>
                                    <td className={meta.hasOwnProperty("hashrate_ideal") ? fieldWarningColor : fieldNoWarningColor}>{hashrateTransformation(device.hashrate_ideal, device.rate_unit)} TH/s</td>
                                    <td className={meta.hasOwnProperty("chain_num") ? fieldWarningColor : fieldNoWarningColor}>{device.chain_num}</td>
                                    <td className='is-size-7 has-text-centered is-vcentered'>{device.is_online ? '🟢' : '🔴'}</td>
                                    <td className='is-size-7 has-text-centered is-vcentered'>{formatTime(device.runtime)}</td>
                                    <td className={meta.hasOwnProperty("pools_url") ? fieldWarningColor : fieldNoWarningColor}>
                                        {/* {device['pools_url']} */}
                                        <div className="content">
                                            <ol type="1">
                                                {
                                                    (device['pools_url'] != null)?
                                                        device['pools_url'].split('|').map((txt, index) => {
                                                            return (
                                                                <li key={index}>{txt.substr(14)}</li>
                                                            )
                                                        }):<></>
                                                }
                                            </ol>
                                        </div>
                                    </td>
                                    <td className={meta.hasOwnProperty("pool_rejected") ? fieldWarningColor : fieldNoWarningColor}>
                                        {device['pool_rejected']}
                                        {/* <div className="content">
                                            <ol type="1">
                                                {
                                                    (device['pool_rejected'] != null)?
                                                        device['pool_rejected'].split('|').map((txt, index) => {
                                                            return (
                                                                <li key={index}>{txt}</li>
                                                            )
                                                        }) : <></>
                                                }
                                            </ol>
                                        </div> */}
                                    </td>
                                    <td className={meta.hasOwnProperty("chain_asic") ? fieldWarningColor : fieldNoWarningColor}>
                                        {device['chain_asic']}
                                        {/* <div className="content">
                                            <ol type="1">
                                                {
                                                    (device['chain_asic'] != null)?
                                                        device['chain_asic'].split('|').map((txt, index) => {
                                                            return (
                                                                <li key={index}>{txt}</li>
                                                            )
                                                        }) : <></>
                                                }
                                            </ol>
                                        </div> */}
                                    </td>
                                    <td className={meta.hasOwnProperty("name") ? fieldWarningColor : fieldNoWarningColor}>{device.name}</td>
                                    <td className={meta.hasOwnProperty("compile_time") ? fieldWarningColor : fieldNoWarningColor}>{device.compile_time}</td>
                                    {
                                        isTechUser(currentUser.access_type)?(
                                            <td className=''>
                                                <div className='columns has-text-centered'>
                                                    <button className="button is-small is-danger mt-1" style={{width:'100%'}} onClick={()=>{onRebootDevice(device.mac)}}>Reboot</button>
                                                    <button className="button is-small is-info mt-1" style={{width:'100%'}} onClick={()=>{onPingDevice(device.mac)}}>Ping</button>
                                                    <button className="button is-small is-success mt-1" style={{width:'100%'}} onClick={()=>{onChangeBeingMode(device.mac, false)}}>Normal</button>
                                                    <button className="button is-small is-warning mt-1 mb-2" style={{width:'100%'}} onClick={()=>{onChangeBeingMode(device.mac, true)}}>Sleep</button>
                                                </div>
                                            </td>
                                        ): (null)
                                    }
                                </tr>
                            )
                        })
                    )}
                </tbody>
            </table>

            <div className={isDeviceHistoryAppear ? "modal is-active" : "modal"}>
                <div className="modal-background" onClick={onHideDeviceHistory}></div>
                <div className="modal-content" style={{backgroundColor:'white', height:'60%', width:'80%'}}>
                    <div className="box is-fullheight">
                        {(isDeviceHistoryLoading)? (
                            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
                            <Oval
                                height={80}
                                width={80}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                            <p className="mt-2">Загрузка</p>
                            </div>
                        ): (
                            <div>
                                <div className="tabs is-centered">
                                    <ul>
                                        <li className={(totalDeviceChartData.range_t == '1h')?'is-active':''}><a onClick={()=>{onShowDeviceHistory('1h')}}>Час</a></li>
                                        <li className={(totalDeviceChartData.range_t == '1d')?'is-active':''}><a onClick={()=>{onShowDeviceHistory('1d')}}>День</a></li>
                                        <li className={(totalDeviceChartData.range_t == '1w')?'is-active':''}><a onClick={()=>{onShowDeviceHistory('1w')}}>Неделя</a></li>
                                        <li className={(totalDeviceChartData.range_t == '1M')?'is-active':''}><a onClick={()=>{onShowDeviceHistory('1M')}}>Месяц</a></li>
                                    </ul>
                                </div>
                            <div>
                                <Line options={
                                    {
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                            title: {
                                                display: false,
                                                text: 'Device count',
                                            }
                                        },
                                    }
                                } data={totalDeviceChartData} height={500}/>
                            </div>
                            </div>
                        )}
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={onHideDeviceHistory}></button>
            </div>

            <DeviceContext.Provider value={{selectedDevice, setSelectedDevice}}>
                <DeviceHashrateHistory data={{}} onFinish={()=>{}}/>
            </DeviceContext.Provider>

            <RebootDeviceModal handle_area_uuid={handleGetAreaUuid} visible_settings={rebootDeviceShow} handleModal={handleRebootModal} />

        </div>
    );
}
