import { React, Component, useState, useEffect, useContext, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Oval } from  'react-loader-spinner'
import { getAreaCounters, recalculateCounterConsumption } from '../../store/actions';
import Swal from 'sweetalert2';
import { ConsumptionContext } from './AddConsumptions';
import { IoMdArrowDropdown } from 'react-icons/io';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb'
import moment from 'moment';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import { createConsumption, updateConsumption } from '../../store/actions/device';

export default function AddConsumptionModal({data}) {

    const dispatch = useDispatch();
    const {consumptionAddModalVisible, setConsumptionAddModalVisible} = useContext(ConsumptionContext)
    const [isCountersLoading, setIsCountersLoading] = useState(false);

    const idBtnRef = useRef()

    const [name, setName] = useState('');
    const [fromDateTime, setFromDateTime] = useState(moment().subtract(1, 'day'));
    const [toDateTime, setToDateTime] = useState(moment());
    const [consumption, setConsumption] = useState(0);
    const [isInfinitely, setIsInfinitely] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);

    const [isProcessing, setIsProcessing] = useState(false);

    useEffect( () => { 
        if(consumptionAddModalVisible)  {
            if(Object.keys(data.selectedConsumption).length > 0)    {
                setName(data.selectedConsumption.name)
                setConsumption(data.selectedConsumption.consumption)
                setFromDateTime(moment.utc(data.selectedConsumption.start_date * 1000))
                setToDateTime(moment.utc(data.selectedConsumption.end_date * 1000))
                setIsInfinitely(data.selectedConsumption.is_infinitely)
                setIsEditMode(true)
            }
        }
    }, [consumptionAddModalVisible]);

    const closeAll = () => {
        setName('')
        setConsumption(0)
        setFromDateTime(moment().subtract(1, 'day'))
        setToDateTime(moment())
        setIsInfinitely(true)
        setIsEditMode(false)
        setConsumptionAddModalVisible(false)
    }

    const onChangeConsumption = () => {
        if(name.length == 0)    {
            return Swal.fire({text:'Введите название расхода', icon: 'error'});
        }
        if(consumption == 0 || consumption == undefined)  {
            return Swal.fire({text:'Введите расход', icon: 'error'});
        }

        var startDateString = fromDateTime.format('YYYY-MM-DDTHH:00:00');
        var endDateString = toDateTime.format('YYYY-MM-DDTHH:00:00');

        const updateConsumptionRequest = async (area_id, name, consumption, startDate, endDate) => {
            setIsProcessing(true)
            const result = await dispatch(updateConsumption({
                id: area_id,
                name: name,
                consumption: consumption,
                start_date: startDate,
                end_date: endDate,
                is_infinitely: isInfinitely
            }))
            setIsProcessing(false)
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            closeAll()
        }

        updateConsumptionRequest(
            data.selectedConsumption['id'],
            name,
            consumption,
            startDateString,
            endDateString
        )
    }

    const onAddConsumption = () => {
        if(name.length == 0)    {
            return Swal.fire({text:'Введите название расхода', icon: 'error'});
        }
        if(consumption == 0 || consumption == undefined)  {
            return Swal.fire({text:'Введите расход', icon: 'error'});
        }

        var startDateString = fromDateTime.format('YYYY-MM-DDTHH:00:00');
        var endDateString = toDateTime.format('YYYY-MM-DDTHH:00:00');

        const addConsumptionRequest = async (area_id, name, consumption, startDate, endDate) => {
            setIsProcessing(true)
            const result = await dispatch(createConsumption({
                area_id: area_id,
                name: name,
                consumption: consumption,
                start_date: startDate,
                end_date: endDate,
                is_infinitely: isInfinitely
            }))
            setIsProcessing(false)
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            closeAll()
        }

        addConsumptionRequest(
            data.areas[data.selectedAreaIndex]['id'],
            name,
            consumption,
            startDateString,
            endDateString
        )
    }

    return (
        <div className={consumptionAddModalVisible ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    closeAll()
                }}>
            </div>
            <div className="modal-content">
                <div className="box is-fullheight">
                    <div className='is-flex is-flex-direction-column is-justify-content-space-around'>
                            <div className='content is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
                                {(isEditMode)?(
                                    <h3>Изменить расход</h3>
                                ):(
                                    <h3>Новый доп. расход</h3>
                                )}
                            </div>
                            <div className="field mt-4">
                                <div className="control">
                                    <input className="input is-medium" 
                                        type="text" 
                                        placeholder="Название" 
                                        value={name || ''} 
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="field mt-4">
                                <div className="control">
                                    <input className="input is-medium" 
                                        type="number" 
                                        placeholder="Расход (кВт/ч)" 
                                        value={consumption || ''} 
                                        onChange={(e) => {
                                            const eConsValue = parseFloat(e.target.value)
                                            console.log(eConsValue)
                                            if(isNaN(eConsValue))   {
                                                setConsumption(0)
                                            } else  {
                                                setConsumption(eConsValue)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                    <DateTimePicker 
                                        views={['year', 'month', 'day', 'hours']} 
                                        label="С какой даты" format='DD.MM.YYYY HH:00' 
                                        className="mt-3"
                                        value={fromDateTime}
                                        onChange={(newValue) => setFromDateTime(newValue)}/>
                                    <DateTimePicker 
                                        views={['year', 'month', 'day', 'hours']} 
                                        label="До какой даты" 
                                        format='DD.MM.YYYY HH:00'
                                        value={toDateTime}
                                        onChange={(newValue) => setToDateTime(newValue)}
                                        readOnly={isInfinitely}/>
                                </DemoContainer>
                            </LocalizationProvider>
                            
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox checked={isInfinitely} onChange={()=>{setIsInfinitely(!isInfinitely)}} />
                                } label="До ручной остановки" />
                            </FormGroup>

                            {(isEditMode) ? (
                                <button className={(isProcessing) ? "button is-danger mt-4 is-loading" : "button is-danger mt-4"} onClick={()=>{onChangeConsumption()}}>Изменить</button>
                            ) : (
                                <button className={(isProcessing) ? "button is-danger mt-4 is-loading" : "button is-danger mt-4"} onClick={()=>{onAddConsumption()}}>Добавить</button>
                            )}
                        </div>
                </div>
            </div>
        </div>
    )
}