import { React, Component, useState, useEffect, createContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import Pagination from 'bulma-pagination-react';
import moment from 'moment';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

import { getIsAreasProcessing } from '../../store/selectors';
import { getCounterConsumptionRange } from '../../store/actions';
import { DatePicker, MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers';


export default function CounterEnergy({areas}) {
    const dispatch = useDispatch();

    const date_switch_list = [
        {title:'Вчера', is_active: true},
        {title:'Неделя', is_active: false},
        {title:'Месяц', is_active: false},
        {title:'Кастом', is_active: false}
    ]

    const [counterConsumptions, setCounterConsumptions] = useState([]);
    const [dateList, setDateList] = useState(date_switch_list);
    const [dateIndex, setDateIndex] = useState(0);
    const [dateRange, setDateRange] = useState({start:'', end:''});
    const [timeRangeModalVisible, setTimeRangeModalVisible] = useState(false)
    const [dateRangeFrom, setDateRangeFrom] = useState(moment().subtract(1, 'day'));
    const [dateRangeTo, setDateRangeTo] = useState(moment());

    const fetchCounterConsumptionRange = async (arr, index, id, startDate, endDate) => {
        let newArr = [...arr]
        newArr[index].isLoading = true
        setCounterConsumptions(newArr)

        const result = await dispatch(getCounterConsumptionRange(id, startDate, endDate))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        }

        const totalConsumption = result.payload.data['total_consumption'] ?? 0
        const totalAmount = result.payload.data['total_amount'] ?? 0
        const hasWarning = result.payload.data['has_warning'] ?? false
        const counterList = result.payload.data.items ?? []

        let counterCons = Array()
        counterList.forEach((counter, index) => {
            counterCons.push(counter.consumption)
        })

        newArr = [...arr]
        newArr[index].total_consumption = totalConsumption
        newArr[index].total_amount = totalAmount
        newArr[index].has_warning = hasWarning
        newArr[index].counters = counterCons
        newArr[index].isLoading = false
        setCounterConsumptions(newArr)
    }

    useEffect( () => {
        moment.locale('de');

        let area_consumptions = Array() 

        areas.forEach((area, index) => {
            area_consumptions.push({
                area_id: area.id,
                area_name: area.name,
                has_warning: false,
                total_consumption: 0,
                total_amount: 0,
                counters:[],
                isLoading: true
            })
        })

        // area_consumptions.forEach((area, index) => {
        //     fetchCounterConsumptionRange(area_consumptions, index, area['area_id'], dateRange.start, dateRange.end)
        // })

        setCounterConsumptions(area_consumptions)
        setDateRangeByIndex(0)
    }, [areas]);

    useEffect( () => {
        counterConsumptions.forEach((area, index) => {
            fetchCounterConsumptionRange(counterConsumptions, index, area['area_id'], dateRange.start, dateRange.end)
        })
    }, [dateRange]);

    const onSwitchStage = async (index) => {
        if(index == 3)  {
            setTimeRangeModalVisible(true)
            return
        }

        setDateList([
            {title:'Вчера', is_active: (index == 0)},
            {title:'Неделя', is_active: (index == 1)},
            {title:'Месяц', is_active: (index == 2)},
            {title:'Кастом', is_active: (index == 3)}
        ])
        setDateIndex(index)
        setDateRangeByIndex(index)
    }

    const setDateRangeByIndex = async (index) => {
        var now = new Date();

        let startDate;
        let endDate;
        switch(index){
            case 0:
                // весь день вчера
                startDate = moment(now).subtract(1, 'days')
                endDate = moment(now).subtract(1, 'days')
                break
            case 1:
                // 7 дней до всего вчера
                startDate = moment(now).subtract(7, 'days')
                endDate = moment(now).subtract(1, 'days')
                break
            case 2:
                //30 дней до всего вчера
                startDate = moment(now).subtract(30, 'days')
                endDate = moment(now).subtract(1, 'days')
                break
            default:
                startDate = moment(now).subtract(1, 'days')
                endDate = moment(now).subtract(1, 'days')
        }

        
        var startDateString = startDate.format('YYYY-MM-DDT00:00:00');
        var endDateString = endDate.format('YYYY-MM-DDT23:59:59');
        setDateRange({start:startDateString, end:endDateString})
    }

    const setDateRangeByTime = async (from, to) => {
        setDateList([
            {title:'Вчера', is_active: false},
            {title:'Неделя', is_active: false},
            {title:'Месяц', is_active: false},
            {title:'Кастом', is_active: false}
        ])
        setDateIndex(-1)

        var startDateString = from.format('YYYY-MM-DDT00:00:00');
        var endDateString = to.format('YYYY-MM-DDT23:59:59');
        setDateRange({start:startDateString, end:endDateString})

        console.log(startDateString)
        console.log(endDateString)
    }

    return (
        <>
        <div className="column is-full">
            <div className="columns is-multiline is-centered">
                <div className='column is-full'>
                    <div className="card mt-4 mx-4 is-vcentered mt-1" style={{height:'40px'}}>
                        <nav className="breadcrumb has-bullet-separator is-centered pt-2" aria-label="breadcrumbs">
                            <ul>
                                {
                                    dateList.map((item, index) => {
                                        return (
                                            <li key={index} className={(item.is_active) ? "is-active": ""}>
                                                <a href="#" onClick={()=>{onSwitchStage(index)}}>{item.title}</a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
                {
                    counterConsumptions.map((item, index) => {
                        return (
                            <div key={index} className="column is-one-fifth">
                                <div className="box" >
                                    <p className={(item['has_warning'])?"title is-5 has-text-danger":"title is-5"}>
                                        {
                                            (item['has_warning'])?'⚠ '+item['area_name']:item['area_name']
                                        }
                                    </p>
                                    {
                                        (item['isLoading'] ? (
                                            <div className='hero is-flex is-centered'>
                                                <div className="container has-text-centered mt-4"> 
                                                    <Oval
                                                        height={40}
                                                        width={40}
                                                        color="#4fa94d"
                                                        wrapperStyle={{}}
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#4fa94d"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}/>
                                                </div>
                                            </div>
                                        ): (
                                            <div>
                                                <p className="subtitle is-6 mt-3">Total: {item['total_consumption']/1000} кВт/ч</p>
                                                <p className="subtitle is-6">Total: {(item['total_amount']/1000).toFixed(5)} руб.</p>
                                                {
                                                    (item['counters'].length > 0) ? (
                                                        <div className="content">
                                                            <ol type="1">
                                                                {
                                                                    item['counters'].map((item, index) => {
                                                                        return (
                                                                            <li key={index}>{item/1000} кВч</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ol>
                                                        </div>
                                                    ): (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <div className={timeRangeModalVisible ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    setDateRangeByTime(dateRangeFrom, dateRangeTo)
                    setTimeRangeModalVisible(false)
                }}>
            </div>
            <div className="modal-content">
                <div className="box">
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                        <DemoItem label="От">
                            <MobileDatePicker value={dateRangeFrom} onChange={(newValue) => setDateRangeFrom(newValue)} format="DD.MM.YYYY"/>
                        </DemoItem>
                        <DemoItem label="До">
                            <MobileDatePicker value={dateRangeTo} onChange={(newValue) => setDateRangeTo(newValue)} format="DD.MM.YYYY"/>
                        </DemoItem>
                    </LocalizationProvider>
                </div>
            </div>
        </div>
        </>
    );
}

