import { React, Component, useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Route, Redirect } from "react-router-dom";
import { motion } from "framer-motion";

import { userMe } from '../store/actions';
import { getCurrentUser } from "../store/selectors";

const PrivateRoute = ({ component: Component, name}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => getCurrentUser(state))

    const config = {
      type: "spring",
      damping: 20,
      stiffness: 100,
    };

    // useEffect( () => { 
    //     async function fetchData() {
    //         await dispatch(userMe())
    //     }
    //     fetchData();
    // }, []);
    
    return (
      <motion.div
        transition={config}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -20, opacity: 0 }}
      >
        <Component/>
      </motion.div>
    );
  };
  
  export default PrivateRoute;