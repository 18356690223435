import HttpRequest from "./HttpRequest";

class UserProvider extends HttpRequest {
    // createUser (username, password) {
    //     return this.post('/user/signout', {username, password})
    // }

    async login(username, password) {
        return await this.post('/user/login', {username, password})
    }

    async fetchMe()   {
        return await this.get('/user/me')
    }

    async fetchUserList()   {
        return await this.get(`/user/list`)
    }

    async changeUserAreas(areas)   {
        return await this.put('/user', areas)
    }

    async deleteUser(id)    {
        return await this.delete('/user', {
            user_id:id
        })
    }

    async createUser(username, accessType, areas, telegramAcc)   {
        return await this.post('/user/add', {
            username:username,
            access_type:accessType,
            areas:areas,
            telegram_acc: telegramAcc,
        })
    }

    async fetchUserAlerts(area_id, user_id)   {
        return await this.get('/user/alerts', {
            area_id:area_id, user_id:user_id
        })
    }

    async setUserAlerts(area_id, user_id, alerts)   {
        let body = {
            area_id: area_id,
            user_id: user_id,
            alerts: alerts
        }
        return await this.put('/user/alerts', body)
    }
}

export default UserProvider;