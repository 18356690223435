import { React, Component, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { accessName } from '../../utils/access';

// export const accessName = (accessType) => {
//     switch(accessType){
//         case 0: return "GOD"
//         case 1: return "OWNER"
//         case 2: return "ADMIN"
//         case 3: return "TECH"
//         case 4: return "FINANCE"
//         default:
//             return "UNKNOWN"
//     }
// }

function Tab ({user}) {
    const _tabs = []
    const [tabIndex, setTabIndex] = useState(0);
    const [tabs, setTabs] = useState(_tabs);
    const isSelected = (d, tab) => d.code === tab;

    const clickTab = (event, key) => {
        setTabIndex(key)
    }

    const navigate = useNavigate();

    useEffect(() => {
        //const accessName = accessName(user.access_type)
        const accessName = user.access_type
        if(accessName == 0 || accessName == 1) {    //GOD, OWNER
            setTabs(
                [{code: 0, label:'Dashboard', link:'/'},
                {code: 1, label:'Devices', link:'/devices'},
                {code: 2, label:'Profitability', link:'/profit'},
                {code: 3, label:'Counters', link:'/counters'},
                {code: 4, label:'Phase', link:'/phase'},
                {code: 5, label:'Areas', link:'/areas'},
                {code: 6, label:'Users', link:'/users'},
                {code: 7, label:'Options', link:'/options'},
                {code: 8, label:'Status Page', link:'/uptime'}]
            )
        } else if(accessName == 2) {    //ADMIN
            setTabs(
                [{code: 0, label:'Dashboard', link:'/'},
                {code: 1, label:'Devices', link:'/devices'},
                {code: 2, label:'Counters', link:'/counters'},
                {code: 3, label:'Phase', link:'/phase'},
                {code: 4, label:'Areas', link:'/areas'},
                {code: 5, label:'Options', link:'/options'},
                {code: 6, label:'Status Page', link:'/uptime'}]
            )
        } else if(accessName == 3) {    //TECH
            setTabs(
                [{code: 0, label:'Dashboard', link:'/'},
                {code: 1, label:'Devices', link:'/devices'},
                {code: 2, label:'Counters', link:'/counters'},
                {code: 3, label:'Phase', link:'/phase'},
                {code: 4, label:'Status Page', link:'/uptime'}]
            )
        } else if(accessName == 4) {    //FINANCE
            setTabs(
                [{code: 0, label:'Dashboard', link:'/'},
                {code: 1, label:'Devices', link:'/devices'},
                {code: 2, label:'Profitability', link:'/profit'},
                {code: 3, label:'Counters', link:'/counters'},
                {code: 4, label:'Phase', link:'/phase'},
                {code: 5, label:'Status Page', link:'/uptime'}]
                )
        }
        navigate('/', { replace: true });
    }, [])

    return (
        <div className="tabs is-boxed is-centered" id="nav">
          <ul>
              {tabs.map(d => {
                  const is_selected = isSelected(d, tabIndex);
                  return (
                        <li key={d.code}
                            className={is_selected ? "is-active" : ''}
                            onClick={event => clickTab(event, d.code)}>
                                 <Link className={is_selected ? "has-text-black" : 'has-text-white'} to={d.link}>
                                    <span>
                                        {d.label}
                                    </span>
                                </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default Tab;