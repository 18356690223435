import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Box,
  CircularProgress,
  OutlinedInput,
  Chip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAsicLogs } from '../../store/actions/device';
import Swal from 'sweetalert2';
import saveAs from 'file-saver';

const files = [
  { name: 'file1.txt', content: 'Содержимое файла 1' },
  { name: 'file2.txt', content: 'Содержимое файла 2' },
  { name: 'file3.txt', content: 'Содержимое файла 3' },
];

const DeviceLogViewer = ({open, onClose}) => {
    const dispatch = useDispatch();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };

    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([])
    const [fileListIndex, setFileListIndex] = useState(0)

    const [fileContentLoading, setFileContentLoading] = useState(false)
    const [fileContent, setFileContent] = useState('')

    useEffect(()=>{
        const fetchDeviceLogs = async (area_uuid, device_ip, log_name) => {
            setLoading(true)
            setFileContentLoading(true)
            const result = await dispatch(getAsicLogs(area_uuid, device_ip, log_name))
            if(!result.success) {
                handleClose()
                return Swal.fire({text:result.message, icon: 'error'});
            }
            
            setFileList(result.payload.data['files'])
            setLoading(false)

            const encodedText = result.payload.data['log']
            const decodedText = atob(encodedText)
            const plainText = new TextDecoder('utf-8').decode(new Uint8Array([...decodedText].map(char => char.charCodeAt(0))));
            setFileContent(plainText)
            setFileContentLoading(false)
        }

        if(open.open)   {
            fetchDeviceLogs(open.area_uuid, open.device_ip, 'miner.log')
        }
    }, [open])

    const handleClose = () => {
        setFileList([])
        setFileListIndex(0)
        setFileContent('')
        onClose()
    };

    const handleChangeFile = (index) => {
        const fetchDeviceLogs = async (area_uuid, device_ip, log_name) => {
            setFileContentLoading(true)

            const result = await dispatch(getAsicLogs(area_uuid, device_ip, log_name))
            if(!result.success) {
                handleClose()
                return Swal.fire({text:result.message, icon: 'error'});
            }

            const encodedText = result.payload.data['log']
            const decodedText = atob(encodedText)
            const plainText = new TextDecoder('utf-8').decode(new Uint8Array([...decodedText].map(char => char.charCodeAt(0))));
            setFileContent(plainText)
            setFileContentLoading(false)
        }

        fetchDeviceLogs(open.area_uuid, open.device_ip, fileList[index].file_name)
    };

    const handleSave = () => {
        const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, `log_${fileList[fileListIndex].name}.txt`);
    };

    return (
        <Dialog open={open.open} onClose={handleClose} fullWidth maxWidth="md">
            {loading ? (
                <DialogContent>
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                    </Box>
                    </DialogContent>
            ):(
                <div>
                <DialogTitle>
                    <FormControl sx={{ m: 1 }} fullWidth>
                        <InputLabel id="demo-controlled-open-select-label">Log</InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            value={fileList}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            value={(fileList.length > 0) ? fileList[fileListIndex]?.name : ""}
                            onChange={(event) => {
                                const fileName = event.target.value;
                                const index = fileList.findIndex((file) => file.name === fileName);
                                setFileListIndex(index)
                                if(index !== -1)    {
                                    handleChangeFile(index)
                                }
                            }}
                            MenuProps={MenuProps}>
                                {fileList.map((file) => (
                                    <MenuItem
                                        key={file.name}
                                        value={file.name}>
                                            {file.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </DialogTitle>
                <DialogContent>
                    {fileContentLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{
                            bgcolor: 'background.paper',
                            border: '0px solid #000',
                            maxHeight: '80vh',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Typography id="modal-modal-description" sx={{
                                flex: 1,
                                overflowY: 'auto',
                                whiteSpace: 'pre-wrap',
                            }}>
                                {fileContent}
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{mt:1}}>
                        <Button  onClick={handleClose} color="error" variant="contained">
                            Закрыть
                        </Button>
                        <Button onClick={handleSave} color="primary" variant="contained">
                            Сохранить
                        </Button>
                    </DialogActions>
                </div>
            )}
        </Dialog>
    );
};

export default DeviceLogViewer;
