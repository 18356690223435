import { React, Component, useState, useEffect, createContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import Pagination from 'bulma-pagination-react';
import moment from 'moment';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

import { getIsAreasProcessing } from '../../store/selectors';
import { getPhaseConsumptionRange, getPhaseConsumptionSamplingRange } from '../../store/actions';
import RocketCalendar from '../../components/Calendar/RocketCalendar';

export default function PhaseStats({areas}) {
    const dispatch = useDispatch();

    const date_switch_list = [
        {title:'Часы', is_active: true, sampling:'1h'},
        {title:'Дни', is_active: false, sampling:'1d'},
        {title:'Недели', is_active: false, sampling:'1w'},
        {title:'Месяцы', is_active: false, sampling:'1M'},
        {title:'Года', is_active: false, sampling:'1Y'}
    ]

    const phase_type_switch_list = [
        {title:'Напряжение', is_active: true, phase_type:'PT_U'},
        {title:'Ток', is_active: false, phase_type:'PT_I'},
        {title:'Мощность', is_active: false, phase_type:'PT_P'}
    ]

    const COLORS = [
        '#4dc9f6',
        '#f67019',
        '#f53794',
        '#537bc4',
        '#acc236',
        '#166a8f',
        '#00a950',
        '#58595b',
        '#8549ba'
    ];

    const [counterConsumptions, setCounterConsumptions] = useState({});
    const [dateList, setDateList] = useState(date_switch_list);
    const [dateIndex, setDateIndex] = useState(0);
    const [phaseTypeList, setPhaseTypeList] = useState(phase_type_switch_list);
    const [phaseTypeIndex, setPhaseTypeIndex] = useState(0);
    const [counterIndex, setCounterIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isChartLoading, setIsChartLoading] = useState(true);

    const [areaIndex, setAreaIndex] = useState(0);
    const [startDateValue, setStartDateValue] = useState(moment().subtract(1, 'day').toDate())
    const [endDateValue, setEndDateValue] = useState(new Date())
    const [calendarVisible, setCalendarVisible] = useState({visible:false, mode:'start', date:startDateValue});

    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);

    const fetchCounterConsumptionRange = async (id, phase_type, startDate, endDate) => {
        setIsLoading(true)
        const result = await dispatch(getPhaseConsumptionRange(id, phase_type, startDate, endDate))
        setIsLoading(false)
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        }

        setCounterConsumptions(result.payload.data)
    }

    const fetchCounterConsumptionSamplingRange = async (id, phase_type, sampling, counter_sn, startDate, endDate) => {
        setIsChartLoading(true)
        const result = await dispatch(getPhaseConsumptionSamplingRange(id, phase_type, sampling, counter_sn, startDate, endDate))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        }

        const samplingData = result.payload.data
        console.log(samplingData)
        
        let times = Array()
        let dataSample = Array()

        let p1 = Array()
        let p2 = Array()
        let p3 = Array()

        let k = 1
        switch(phase_type)  {
            case 'PT_U':
                k = 100
                break
            case 'PT_I':
                k = 1000
                break
            case 'PT_P':
                k = 100000
                break
        }

        Object.keys(samplingData).map((value, index) => {
            const date = new Date(value*1000).toUTCString()
            const humanTime = moment(date).utc().format((dateIndex == 0)?"DD/MM/YYYY HH:00":"DD/MM/YYYY")

            times.push(humanTime)

            const counterConsumptionList = samplingData[value]
            p1.push((counterConsumptionList['p1'] / k).toFixed(2))
            p2.push((counterConsumptionList['p2'] / k).toFixed(2))
            p3.push((counterConsumptionList['p3'] / k).toFixed(2))
        })

        setChartLabels(times)

        dataSample = [
            {
                label: 'Фаза 1',
                data: p1,
                stack: 'Stack 1',
                backgroundColor:COLORS[0]
            },
            {
                label: 'Фаза 2',
                data: p2,
                stack: 'Stack 2',
                backgroundColor:COLORS[1]
            },
            {
                label: 'Фаза 3',
                data: p3,
                stack: 'Stack 3',
                backgroundColor:COLORS[2]
            }
        ]
        setChartData(dataSample)

        setIsChartLoading(false)
    }

    useEffect( () => {
        const startDateString = moment(startDateValue).format('YYYY-MM-DDT00:00:00');
        const endDateString = moment(endDateValue).format('YYYY-MM-DDT23:59:59');
        let sampling = dateList[dateIndex]['sampling']
        let phase_type = phaseTypeList[phaseTypeIndex]['phase_type']

        setIsChartLoading(true)

        fetchCounterConsumptionRange(areas[areaIndex].id, phase_type, startDateString, endDateString)
    }, [areaIndex, startDateValue, endDateValue, phaseTypeIndex]);

    // как только загрузился counterConsumptions
    useEffect( () => {
        const startDateString = moment(startDateValue).format('YYYY-MM-DDT00:00:00');
        const endDateString = moment(endDateValue).format('YYYY-MM-DDT23:59:59');
        let sampling = dateList[dateIndex]['sampling']
        let phase_type = phaseTypeList[phaseTypeIndex]['phase_type']

        if(counterConsumptions.hasOwnProperty('items') && counterConsumptions['items'].length > 0)  {
            const counter_sn = counterConsumptions['items'][counterIndex].serial_number
            fetchCounterConsumptionSamplingRange(areas[areaIndex].id, phase_type, sampling, counter_sn, startDateString, endDateString)
        }
    }, [counterConsumptions, counterIndex, dateIndex]);

    const onSwitchStage = async (index) => {
        setDateList([
            {title:'Часы', is_active: (index == 0), sampling:'1h'},
            {title:'Дни', is_active: (index == 1), sampling:'1d'},
            {title:'Недели', is_active: (index == 2), sampling:'1w'},
            {title:'Месяцы', is_active: (index == 3), sampling:'1M'},
            {title:'Года', is_active: (index == 4), sampling:'1Y'}
        ])
        setDateIndex(index)
    }

    const onChangeValueCalendar = async (settings) => {
        if(settings.mode == 'start')    {
            setStartDateValue(settings.date)
        } else if(settings.mode == 'end')   {
            setEndDateValue(settings.date)
        }

        setCalendarVisible(settings)
    }

    const onSwitchArea = async (index) => {
        setCounterIndex(0)
        setCounterConsumptions({})

        setAreaIndex(index)
    }

    const onSwitchPhaseType = async (index) => {
        setPhaseTypeList([
            {title:'Напряжение', is_active: (index == 0), phase_type:'PT_U'},
            {title:'Ток', is_active: (index == 1), phase_type:'PT_I'},
            {title:'Мощность', is_active: (index == 2), phase_type:'PT_P'}
        ])
        setPhaseTypeIndex(index)
    }

    const transformValueByPhaseType = (value, phase_type_index) => {
        switch(phase_type_index)  {
            case 0:
                return (value / 100).toFixed(2)
                break
            case 1:
                return (value / 1000).toFixed(2)
                break
            case 2:
                return (value / 100000).toFixed(2)
                break
        }
    }

    const getDimensionByPhaseType = (phase_type_index) => {
        switch(phase_type_index)  {
            case 0:
                return 'Вольт'
                break
            case 1:
                return 'Ампер'
                break
            case 2:
                return 'кВт'
                break
        }
    }

    return (
        <>
        <div className="column is-full">
            <div className="columns is-multiline is-centered">
                <div className='column is-full'>
                    <div className="card mt-1 mx-4" style={{height:'60px'}}>
                        <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-center' style={{height:'100%'}}>
                            <button className="button is-outlined is-centered mr-5" onClick={()=>{
                                setCalendarVisible({visible:true, mode:'start', date:startDateValue})
                            }}>{moment(startDateValue).format('YYYY-MM-DD')}</button>
                            <button className="button is-outlined is-centered" onClick={()=>{
                                setCalendarVisible({visible:true, mode:'end', date:endDateValue})
                            }}>{moment(endDateValue).format('YYYY-MM-DD')}</button>
                        </div>
                    </div>
                    <div className="card mx-4 mt-4" style={{height:'40px'}}>
                        <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-center' style={{height:'100%'}}>
                            <nav className="breadcrumb has-bullet-separator is-centered" aria-label="breadcrumbs">
                                    <ul>
                                        {
                                            areas.map((item, index) => {
                                                return (
                                                    <li key={index} className={(areaIndex == index) ? "is-active": ""}>
                                                        <a href="#" onClick={()=>{onSwitchArea(index)}}>{item.name}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="card mt-4 mx-4 is-vcentered mt-1" style={{height:'40px'}}>
                        <nav className="breadcrumb has-bullet-separator is-centered pt-2" aria-label="breadcrumbs">
                            <ul>
                                {
                                    dateList.map((item, index) => {
                                        return (
                                            <li key={index} className={(item.is_active) ? "is-active": ""}>
                                                <a href="#" onClick={()=>{onSwitchStage(index)}}>{item.title}</a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                    <div className="card mt-4 mx-4 is-vcentered mt-1" style={{height:'40px'}}>
                        <nav className="breadcrumb has-bullet-separator is-centered pt-2" aria-label="breadcrumbs">
                            <ul>
                                {
                                    phaseTypeList.map((item, index) => {
                                        return (
                                            <li key={index} className={(item.is_active) ? "is-active": ""}>
                                                <a href="#" onClick={()=>{onSwitchPhaseType(index)}}>{item.title}</a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                    {
                        (counterConsumptions.hasOwnProperty('items') && counterConsumptions['items'].length > 0) ? (
                            <div className="card mt-4 mx-4 is-vcentered mt-1" style={{height:'40px'}}>
                                <nav className="breadcrumb has-bullet-separator is-centered pt-2" aria-label="breadcrumbs">
                                    <ul>
                                        {
                                            counterConsumptions['items'].map((item, index) => {
                                                return (
                                                    <li key={index} className={(counterIndex == index) ? "is-active": ""}>
                                                        <a href="#" onClick={()=>{setCounterIndex(index)}}>{item.serial_number}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </nav>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                </div>
            </div>
            {
                (isLoading) ? (
                    <div className='hero is-flex is-centered'>
                        <div className="container has-text-centered mt-4"> 
                            <Oval
                                height={40}
                                width={40}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}/>
                        </div>
                    </div>
                ) : (
                        (counterConsumptions.hasOwnProperty('items'))?(
                            <div>
                                {
                                    (counterConsumptions.hasOwnProperty('items') && counterConsumptions['items'].length > 0) ? (
                                        counterConsumptions['items'].map((item, index) => {
                                            if(counterIndex == index)   {
                                                return (
                                                    <nav className="level" key={index}>
                                                        <div className="level-item has-text-centered">
                                                            <div>
                                                                <p className="heading">Фаза 1 (№{item.serial_number})</p>
                                                                <p className="title">{transformValueByPhaseType(item.p1, phaseTypeIndex)}</p>
                                                                <p className="subtitle mt-1">{getDimensionByPhaseType(phaseTypeIndex)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="level-item has-text-centered">
                                                            <div>
                                                                <p className="heading">Фаза 2 (№{item.serial_number})</p>
                                                                <p className="title">{transformValueByPhaseType(item.p2, phaseTypeIndex)}</p>
                                                                <p className="subtitle mt-1">{getDimensionByPhaseType(phaseTypeIndex)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="level-item has-text-centered">
                                                            <div>
                                                                <p className="heading">Фаза 3 (№{item.serial_number})</p>
                                                                <p className="title">{transformValueByPhaseType(item.p3, phaseTypeIndex)}</p>
                                                                <p className="subtitle mt-1">{getDimensionByPhaseType(phaseTypeIndex)}</p>
                                                            </div>
                                                        </div>
                                                    </nav>
                                                )
                                            }
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                        ) : (
                            <div className='hero is-flex is-centered'>
                                <div className="container has-text-centered mt-4"> 
                                    <>Нет данных</>
                                </div>
                            </div>
                        )
                )
            }

            <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-center' style={{height:'100%'}}>
                {
                (isChartLoading) ? (
                    <div className='hero is-flex is-centered'>
                        <div className="container has-text-centered mt-4"> 
                            <Oval
                                height={40}
                                width={40}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}/>
                        </div>
                    </div>
                ) : (
                    <div style={{width:'80%', height:'80%'}}>
                        <Bar options={
                            {
                                plugins: {
                                    title: {
                                        display: true,
                                        text: 'График потребления'
                                    },
                                },
                                responsive: true,
                                interaction: {
                                    intersect: false,
                                },
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true
                                    }
                                }
                            }
                        } data={{
                            labels: chartLabels,
                            datasets: chartData
                        }}/>
                    </div>
                )
                }
            </div>

            <RocketCalendar visible_settings={calendarVisible} handle={(settings)=>{onChangeValueCalendar(settings)}} />
        </div>
        </>
    );
}

