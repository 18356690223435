import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import storePersist from "./storePersist";

const initialState = storePersist.get("user") ? { user: storePersist.get("user") } : {};
const middleware = [thunk];

let store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)));

export default store;
