import { React, Component, useState, useEffect, createContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';

import { getUserList, getAreasList } from '../../store/actions';
import {
  getIsAreasLoading
} from '../../store/selectors';
import Profit from '../../components/Profit/Profit';
import UserModal from './UserModal';
import AddUserModal from './AddUserModal';
import UserAlerts from './UserAlerts';
import { isBossUser } from '../../utils/access';
import AlertPriority from './AlertPriority';

export const UserContext = createContext('');

//https://mhnpd.github.io/react-loader-spinner/docs/components/oval
//https://cssninja.io/blog/simple-loader-bulma
export default function Users() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [users, setUsers] = useState([]);
    const [areas, setAreas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserAlerts, setSelectedUserAlerts] = useState(null);
    const [addUser, setAddUser] = useState(null);
    const [priority, setPriority] = useState(null);

    useEffect( () => { 
        async function fetchData() {
            const result = await dispatch(getUserList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setUsers(result.payload.data)
            setIsLoading(false)
        }

        setIsLoading(true)
        fetchData();
    }, []);

    useEffect( () => { 
        async function fetchData() {
            const result = await dispatch(getAreasList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setAreas(result.payload.data)
        }
        fetchData();
    }, []);

    const getAccessName = (accessType) =>   {
        switch(accessType)  {
            case 0:
                return "Создатель"
            case 1:
                return "Владелец"
            case 2:
                return "Администратор"
            case 3:
                return "Технический специалист"
            case 4:
                return "Бухгалтер"
            default:
                return "Unknown rule"
        }
    }

    const getUserAreas = (areas) =>   {
        const ret = []
        areas.map(area => {
            ret.push((ret.length > 0) ? ', '+area.name : area.name)
        })
        return ret
    }

    const refresh = () => {
        async function fetchData() {
            const result = await dispatch(getUserList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setUsers(result.payload.data)
            setIsLoading(false)
        }

        async function fetchData2() {
            const result = await dispatch(getAreasList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setAreas(result.payload.data)
        }

        setUsers(null)
        setAreas(null)

        setIsLoading(true)
        fetchData();
        fetchData2()
    }

    return (
        (isLoading) ? (
            <div className="hero is-fullheight is-flex">
                <div className="container has-text-centered mt-4"> 
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                    />
                        <p className="mt-2">Загрузка</p>
                </div>
            </div>
        ):(
            <div className="columns is-multiline">
                    <div className="column is-full">
                        <div className="card mt-4 mx-4">
                            <header className="card-header">
                                <p className="card-header-title">
                                    Пользователи
                                </p>
                                <button className="button is-danger mt-1 mr-2" onClick={()=>{setPriority('set')}}>
                                    Приоритеты
                                </button>
                                <button className="button is-warning mt-1 mr-2" onClick={()=>{setAddUser('add')}}>
                                    Добавить
                                </button>
                            </header>
                            {
                                (users.length > 0) ? (
                                    <div className="card-content">
                                        <table className="table is-fullwidth is-striped is-bordered s-narrow is-hoverable"> 
                                            <thead>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>Telegram</th>
                                                    <th>Access</th>
                                                    <th>Areas</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {(
                                                users.map(user => {
                                                    return (
                                                        <tr key={user.id}>
                                                            <td onClick={()=>{
                                                                setSelectedUser(user)
                                                            }}>{user.username}</td>
                                                            <td onClick={()=>{
                                                                setSelectedUser(user)
                                                            }}>{user.telegram_acc}</td>
                                                            <td onClick={()=>{
                                                                setSelectedUser(user)
                                                            }}>{getAccessName(user.access_type)}</td>
                                                            <td onClick={()=>{
                                                                setSelectedUser(user)
                                                            }}>{getUserAreas(user.areas)}</td>
                                                            <td>
                                                                {
                                                                    ((user.areas.length > 0 || isBossUser(user.access_type)) && user.telegram_acc) ? (
                                                                        <button className="button is-small is-danger mt-1" style={{width:'100%'}} onClick={()=>{setSelectedUserAlerts(user)}}>Alerts</button>
                                                                    ) : (
                                                                        <button className="button is-small is-danger mt-1" style={{width:'100%'}} onClick={()=>{setSelectedUserAlerts(user)}} disabled>Alerts</button>
                                                                    )
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <p className="is-flex is-align-content-center is-justify-content-center py-6">
                                        У вас нет пользователей
                                    </p>
                                )
                            }
                        </div>
                    </div>

                    <UserContext.Provider value={{selectedUser,setSelectedUser}}>
                        <UserModal data={{areas:areas}} onFinish={()=>{refresh()}}/>
                    </UserContext.Provider>

                    <UserContext.Provider value={{addUser,setAddUser}}>
                        <AddUserModal data={{areas:areas}} onFinish={()=>{refresh()}}/>
                    </UserContext.Provider>

                    <UserContext.Provider value={{selectedUserAlerts,setSelectedUserAlerts}}>
                        <UserAlerts data={{areas:areas}} onFinish={()=>{}}/>
                    </UserContext.Provider>

                    <UserContext.Provider value={{priority,setPriority}}>
                        <AlertPriority data={{areas:areas}} onFinish={()=>{}}/>
                    </UserContext.Provider>
            </div>
        )
    );
}
  