import { React, Component, useState, useEffect, createContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import Pagination from 'bulma-pagination-react';

import { getIsAreasProcessing } from '../../store/selectors';
import { getReportProfitableHistory, getReportProfitableShort } from '../../store/actions';
import CounterList from '../Counter/CounterList' 
import CounterModal from '../Counter/CounterModal';
import CounterHistoryModal from './CounterHistoryModal';

export const ProfitHistoryContext = createContext('');

export default function Profit({data}) {
    const dispatch = useDispatch();

    const [title, setTitle] = useState(data.name);
    const [uuid] = useState(data.id);
    const [profitableHistory, setProfitableHistory] = useState([]);
    const [poolData, setPoolData] = useState([]);
    const [profitableHistoryPagesMeta, setProfitableHistoryPagesMeta] = useState({offset:0, limit: 24, count:0, pages:0})
    const [profitableHistoryCurrentPage, setProfitableHistoryCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingProfitableShort, setIsLoadingProfitableShort] = useState(false);
    const [profitableShort, setProfitableShort] = useState([]);
    const [usdRubRate, setUsdRubRate] = useState(0);

    const [selectedPoolItem, setSelectedPoolItem] = useState(null);

    useEffect( () => { 
        const fetchAreasProfitableHistory = async () => {
            const result = await dispatch(getReportProfitableHistory(
                uuid, 
                (profitableHistoryCurrentPage - 1) * profitableHistoryPagesMeta.limit, 
                profitableHistoryPagesMeta.limit)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setProfitableHistoryPagesMeta({
                offset: result.payload.data.offset,
                limit: result.payload.data.limit, 
                count:result.payload.data.count,
                pages: result.payload.data.pages
            })
            setProfitableHistory(result.payload.data.items)
            setPoolData(result.payload.data.pool_data)
            setIsLoading(false)
        }

        setIsLoading(true)
        fetchAreasProfitableHistory();
    }, [profitableHistoryCurrentPage]);

    useEffect( () => { 
        const fetchAreasProfitableShort = async () => {
            const result = await dispatch(getReportProfitableShort(uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setProfitableShort(result.payload.data)
            setIsLoadingProfitableShort(false)
        }

        setIsLoadingProfitableShort(true)
        fetchAreasProfitableShort();
    }, []);

    const toCurrency = (n, curr, LanguageFormat = undefined) =>
        Intl.NumberFormat(LanguageFormat, { style: 'currency', currency: curr }).format(n);

    return (
        <div className="column is-full">
            {/* <div className="card mt-4 mx-4">
                <header className="card-header">
                    <p className="card-header-title">
                        {title}
                        {
                            (isLoading) ? '' : ' (1$ = ' + usdRubRate + '₽)'
                        }
                    </p>
                </header>
                <div className="card-content"> */}
                    {(isLoading) ? (
                        <div className="hero is-fullheight is-flex">
                            <div className="container has-text-centered mt-4"> 
                                <Oval
                                    height={80}
                                    width={80}
                                    color="#4fa94d"
                                    wrapperStyle={{}}
                                    wrapperclassName=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#4fa94d"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                    />
                                <p className="mt-2">Загрузка</p>
                            </div>
                        </div>) : (
                            <div>
                                <nav className="level">
                                    <div className="level-item has-text-centered">
                                        <div>
                                        <p className="heading">Доход сегодня</p>
                                        <p className="title">{(profitableShort.length > 0) ? toCurrency(profitableShort[0].income_today_rub, 'RUB', 'Ru-ru') : 0}</p>
                                        </div>
                                    </div>
                                    <div className="level-item has-text-centered">
                                        <div>
                                        <p className="heading">Расход сегодня</p>
                                        <p className="title">{(profitableShort.length > 0) ? toCurrency(profitableShort[0].outcome_today_rub, 'RUB', 'Ru-ru') : 0}</p>
                                        </div>
                                    </div>
                                    <div className="level-item has-text-centered">
                                        <div>
                                        <p className="heading">Профит сегодня</p>
                                        <p className="title">{(profitableShort.length > 0) ? toCurrency(profitableShort[0].profit_today_rub, 'RUB', 'Ru-ru') : 0}</p>
                                        </div>
                                    </div>
                                    <div className="level-item has-text-centered">
                                        <div>
                                        <p className="heading">Профит вчера</p>
                                        <p className="title">{(profitableShort.length > 0) ? toCurrency(profitableShort[0].profit_yesterday_rub, 'RUB', 'Ru-ru') : 0}</p>
                                        </div>
                                    </div>        
                                </nav>
                                {
                                    (profitableHistory.length > 0) ? (
                                        <table className="table is-fullwidth is-striped is-bordered s-narrow is-hoverable">
                                            <thead>
                                                <tr>
                                                    <th><p className="title is-size-6">USD/RUB</p></th>
                                                    <th><p className="title is-size-6">Дата</p></th>
                                                    {
                                                        poolData.map(pool => {
                                                            return (
                                                                <>
                                                                    <th key={pool.id+'1'}>
                                                                        <p className="title is-size-6">Добыча</p>
                                                                        <p className="subtitle is-size-7">{pool.pool_currency}/{pool.pool_subaccount}</p>
                                                                    </th>
                                                                    <th key={pool.id+'2'}>
                                                                        <p className="title is-size-6">Курс ({pool.pool_currency})</p>
                                                                        <p className="subtitle is-size-7">{pool.pool_currency}/{pool.pool_subaccount}</p>
                                                                    </th>
                                                                    <th key={pool.id+'3'}>
                                                                        <p className="title is-size-6">Доход (USD)</p>
                                                                        <p className="subtitle is-size-7">{pool.pool_currency}/{pool.pool_subaccount}</p>
                                                                    </th>
                                                                    <th key={pool.id+'4'}>
                                                                        <p className="title is-size-6">Доход (RUB)</p>
                                                                        <p className="subtitle is-size-7">{pool.pool_currency}/{pool.pool_subaccount}</p>
                                                                    </th>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    <th><p className="title is-size-6">Общая cумма дохода</p></th>
                                                    <th><p className="title is-size-6">Сумма расхода</p></th>
                                                    <th><p className="title is-size-6">Профит</p></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    profitableHistory.map(item => {
                                                        return (
                                                            <tr key={item.create_time} onClick={()=>{
                                                                setSelectedPoolItem({
                                                                    time:item.create_time
                                                                })
                                                            }}>
                                                                <td className='is-size-7'>{item.usdrub_rate.toFixed(2)} руб.</td>
                                                                <td className='is-size-7'>{new Date(item.create_time*1000).toUTCString()}</td>
                                                                {
                                                                    poolData.map(pool => {
                                                                        const filter_content = item.pools.filter(obj => obj.id === pool.id)
                                                                        if(filter_content.length != 0)  {
                                                                            return (
                                                                                <>
                                                                                    <td key={pool.id+'5'} className='is-size-7'>{filter_content[0].income_currency} {filter_content[0].pool_currency}</td>
                                                                                    <td key={pool.id+'6'} className='is-size-7'>{toCurrency(filter_content[0].currency_rate, 'USD', 'en-us')}</td>
                                                                                    <td key={pool.id+'7'} className='is-size-7'>{toCurrency(filter_content[0].income_usd, 'USD', 'en-us')}</td>
                                                                                    <td key={pool.id+'8'} className='is-size-7'>{toCurrency(filter_content[0].income_rub, 'RUB', 'Ru-ru')}</td>
                                                                                </>
                                                                            )
                                                                        }
                                                                        return (
                                                                            <>
                                                                                <td key={pool.id+'5'}></td>
                                                                                <td key={pool.id+'6'}></td>
                                                                                <td key={pool.id+'7'}></td>
                                                                                <td key={pool.id+'8'}></td>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                                <td className='is-size-7'>{toCurrency(item.income_all_rub, 'RUB', 'Ru-ru')}</td>
                                                                <td className='is-size-7'>{toCurrency(item.outcome_rub, 'RUB', 'Ru-ru')}</td>
                                                                <td className='is-size-7'>{toCurrency(item.profit_rub, 'RUB', 'Ru-ru')}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div></div>
                                    )
                                }
                                <Pagination
                                    pages={profitableHistoryPagesMeta.pages}
                                    currentPage={profitableHistoryCurrentPage}
                                    onChange={(page) => {
                                        setProfitableHistoryCurrentPage(page)
                                    }}
                                    />
                            </div>
                        )}
                {/* </div>
            </div> */}
            <ProfitHistoryContext.Provider value={{selectedPoolItem,setSelectedPoolItem}}>
                <CounterHistoryModal data={{
                        area_uuid: uuid
                    }}/>
            </ProfitHistoryContext.Provider>
        </div>
    );
}
