import { React, Component, useState, useEffect, createContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { getIsAreasProcessing } from '../../store/selectors';
import { updateArea } from '../../store/actions';
import CounterList from '../Counter/CounterList' 
import CounterModal from '../Counter/CounterModal';
import RoomList from '../Rooms/RoomList';
import F2PoolList from '../F2Pool/F2PoolList';

export const AreaContext = createContext('');

export default function Area({data}) {
    const dispatch = useDispatch();
    
    const [title, setTitle] = useState(data.name);
    const [uuid] = useState(data.id);
    const [name, setName] = useState(data.name);
    const [deviceCount, setDeviceCount] = useState(data.device_count);
    const [referenceHashrate, setReferenceHashrate] = useState(data.reference_hashrate);
    const [criticalHashrate, setCriticalHashrate] = useState(data.critical_hashrate);
    const [percentHashrate, setPercentHashrate] = useState(data.percent_hashrate);
    const [referenceHashrateLType, setReferenceHashrateLType] = useState(data.reference_hashrate_ltype);
    const [percentHashrateLType, setPercentHashrateLType] = useState(data.percent_hashrate_ltype);
    const [timeDissapear, setTimeDissapear] = useState(data.time_dissapear);
    const [minTempChip, setMinTempChip] = useState(data.temp_chip_min);
    const [maxTempChip, setMaxTempChip] = useState(data.temp_chip_max);
    const [fanSpeedMin, setFanSpeedMin] = useState(data.fan_speed_min);
    const [fanSpeedMax, setFanSpeedMax] = useState(data.fan_speed_max);
    const [voltageChipMin, setVoltageChipMin] = useState(data.voltage_chip_min);
    const [voltageChipMax, setVoltageChipMax] = useState(data.voltage_chip_max);
    const [tids, setTids] = useState(data.tids);
    const [hhKey, setHHKey] = useState(data.hh_key);
    const [ipRouter, setIPRouter] = useState(data.ip_router);
    const [electricityCost, setElectricityCost] = useState(data.electricity_cost);

    const [influxDeviceHost, setInfluxDeviceHost] = useState(data.device_influx.host);
    const [influxDevicePort, setInfluxDevicePort] = useState(data.device_influx.port);
    const [influxDeviceToken, setInfluxDeviceToken] = useState(data.device_influx.token);
    const [influxDeviceOrg, setInfluxDeviceOrg] = useState(data.device_influx.org);
    const [influxDeviceBucket, setInfluxDeviceBucket] = useState(data.device_influx.bucket);

    const [influxCounterHost, setInfluxCounterHost] = useState(data.counter_influx.host);
    const [influxCounterPort, setInfluxCounterPort] = useState(data.counter_influx.port);
    const [influxCounterToken, setInfluxCounterToken] = useState(data.counter_influx.token);
    const [influxCounterOrg, setInfluxCounterOrg] = useState(data.counter_influx.org);
    const [influxCounterBucket, setInfluxCounterBucket] = useState(data.counter_influx.bucket);

    const [influxBackupHost, setInfluxBackupHost] = useState(data.backup_influx.host);
    const [influxBackupPort, setInfluxBackupPort] = useState(data.backup_influx.port);
    const [influxBackupToken, setInfluxBackupToken] = useState(data.backup_influx.token);
    const [influxBackupOrg, setInfluxBackupOrg] = useState(data.backup_influx.org);
    const [influxBackupBucket, setInfluxBackupBucket] = useState(data.backup_influx.bucket);

    let _f2PoolCurrency = ''
    let _f2PoolSubaccountName = ''
    if(data.f2pool)    {
        _f2PoolCurrency = data.f2pool.currency
        _f2PoolSubaccountName = data.f2pool.subaccount
    } else  {
        _f2PoolCurrency = ''
        _f2PoolSubaccountName = ''
    }
    const [f2PoolCurrency, setF2PoolCurrency] = useState(_f2PoolCurrency);
    const [f2PoolSubaccountName, setF2PoolSubaccountName] = useState(_f2PoolSubaccountName);

    const [selectedCounterUUID, setSelectedCounterUUID] = useState('');

    const isProcessing = useSelector(state => getIsAreasProcessing(state));

    const onSave = () => {
        async function fetchData() {
            if (name.length === 0) {
                return Swal.fire({text:'Пожалуйста введите название площадки', icon: 'error'});
            } else if (Number.isInteger(deviceCount) == false) {
                return Swal.fire({text:'Количество устройств это число', icon: 'error'});
            } else if (!Number.isInteger(referenceHashrate)) {
                return Swal.fire({text:'Эталон хешрейта (S type) это число', icon: 'error'});
            } else if (!Number.isInteger(criticalHashrate)) {
                return Swal.fire({text:'Критичный хешрейт это число', icon: 'error'});
            } else if (!Number.isInteger(percentHashrate)) {
                return Swal.fire({text:'Процент порога это число', icon: 'error'});
            } else if (!Number.isInteger(referenceHashrateLType)) {
                return Swal.fire({text:'Эталон хешрейта (L type) это число', icon: 'error'});
            } else if (!Number.isInteger(percentHashrateLType)) {
                return Swal.fire({text:'Процент порога (L type) это число', icon: 'error'});
            } else if (!Number.isInteger(timeDissapear)) {
                return Swal.fire({text:'Максимальное время простоя это число', icon: 'error'});
            } else if (!Number.isInteger(minTempChip)) {
                return Swal.fire({text:'Минимально разрешенная температура это число', icon: 'error'});
            } else if (!Number.isInteger(maxTempChip)) {
                return Swal.fire({text:'Максимально разрешенная температура это число', icon: 'error'});
            } else if (!Number.isInteger(fanSpeedMin)) {
                return Swal.fire({text:'Минимально разрешенная скорость это число', icon: 'error'});
            } else if (!Number.isInteger(fanSpeedMax)) {
                return Swal.fire({text:'Максимально разрешенная скорость это число', icon: 'error'});
            } else if (!Number.isInteger(voltageChipMin)) {
                return Swal.fire({text:'Минимальное напряжение это число', icon: 'error'});
            } else if (!Number.isInteger(voltageChipMax)) {
                return Swal.fire({text:'Максимальное напряжение это число', icon: 'error'});
            } else if(tids.length == 0)  {
                setTids('')
            } else if (!Number.isInteger(electricityCost)) {
                return Swal.fire({text:'Стоимость электроэнергии это число', icon: 'error'});
            } else if (!Number.isInteger(influxDevicePort)) {
                return Swal.fire({text:'Influx device port это число', icon: 'error'});
            } else if (!Number.isInteger(influxCounterPort)) {
                return Swal.fire({text:'Influx device port это число', icon: 'error'});
            }

            if((f2PoolCurrency.length > 0 && f2PoolSubaccountName.length == 0) || (f2PoolCurrency.length == 0 && f2PoolSubaccountName.length > 0))  {
                return Swal.fire({text:'Ошибка заполнения полей пула', icon: 'error'});
            }

            const result = await dispatch(
                updateArea(
                    uuid, 
                    name, 
                    deviceCount, 
                    referenceHashrate, 
                    criticalHashrate, 
                    percentHashrate,
                    referenceHashrateLType, 
                    percentHashrateLType,
                    timeDissapear, 
                    minTempChip, 
                    maxTempChip,
                    fanSpeedMin,
                    fanSpeedMax,
                    voltageChipMin,
                    voltageChipMax, 
                    tids,
                    hhKey,
                    ipRouter,
                    (electricityCost == 0) ? 0 : electricityCost,
                    f2PoolCurrency,
                    f2PoolSubaccountName,
                    influxDeviceHost,
                    influxDevicePort,
                    influxDeviceToken,
                    influxDeviceOrg,
                    influxDeviceBucket,
                    influxCounterHost,
                    influxCounterPort,
                    influxCounterToken,
                    influxCounterOrg,
                    influxCounterBucket,
                    influxBackupHost,
                    influxBackupPort,
                    influxBackupToken,
                    influxBackupOrg,
                    influxBackupBucket)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setTitle(name)

            return Swal.fire({
                icon: 'success',
                title: `${name} сохранён`,
                showConfirmButton: false,
                timer: 1000
            })
        }
        fetchData()
    }

    return (
        <div className="column is-multiline">
            {/* <div className="card mt-3">
                <header className="card-header">
                    <p className="card-header-title">
                        {title}
                    </p>
                </header>
                <div className="card-content"> */}
                    <div className='box'>
                        <h3 className="title is-3">Общие</h3>
                        <div className="field">
                            <label className="label">Название площадки</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="зона 51" 
                                    value={name || ''} 
                                    onChange={(e) => setName(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Количество устройств</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={deviceCount || ''} 
                                    onChange={(e) => setDeviceCount(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">IP роутера</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="X.X.X.X" 
                                    value={ipRouter || ''} 
                                    onChange={(e) => setIPRouter(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className='box'>
                        <h3 className="title is-3">Оповещения</h3>
                        <div className="field">
                            <label className="label">Критичный хешрейт одного устройства (TH/s)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={criticalHashrate || ''} 
                                    onChange={(e) => setCriticalHashrate(parseFloat(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Эталон хешрейта площадки (S type) (TH/s)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={referenceHashrate || ''} 
                                    onChange={(e) => setReferenceHashrate(parseFloat(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Процент порога (S type) (%)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={percentHashrate || ''} 
                                    onChange={(e) => setPercentHashrate(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Эталон хешрейта площадки (L type) (GH/s)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={referenceHashrateLType || ''} 
                                    onChange={(e) => setReferenceHashrateLType(parseFloat(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Процент порога (L type) (%)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={percentHashrateLType || ''} 
                                    onChange={(e) => setPercentHashrateLType(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Максимальное время простоя устройства (мин.)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={timeDissapear || ''} 
                                    onChange={(e) => setTimeDissapear(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Минимально разрешенная температура (℃)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={minTempChip || ''} 
                                    onChange={(e) => setMinTempChip(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Максимально разрешенная температура (℃)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={maxTempChip || ''} 
                                    onChange={(e) => setMaxTempChip(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Минимально разрешенная скорость</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={fanSpeedMin || ''} 
                                    onChange={(e) => setFanSpeedMin(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Максимально разрешенная скорость</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={fanSpeedMax || ''} 
                                    onChange={(e) => setFanSpeedMax(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Минимальное напряжение (В)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={voltageChipMin || ''} 
                                    onChange={(e) => setVoltageChipMin(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Максимальное напряжение (В)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="0" 
                                    value={voltageChipMax || ''} 
                                    onChange={(e) => setVoltageChipMax(parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Telegram идентификаторы пользователей (через запятую)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="4133323,5432543" 
                                    value={tids || ''} 
                                    onChange={(e) => setTids(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Helping Hand token</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="" 
                                    value={hhKey || ''} 
                                    onChange={(e) => setHHKey(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className='box'>
                        <h3 className="title is-3">Influx devices</h3>
                        <div className="field">
                            <label className="label">Host</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxDeviceHost || ''} 
                                    onChange={(e) => setInfluxDeviceHost(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Port</label>
                            <div className="control">
                                <input className="input" 
                                    type="number" 
                                    placeholder=""
                                    value={influxDevicePort || 0} 
                                    onChange={(e) => setInfluxDevicePort(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Token</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxDeviceToken || ''} 
                                    onChange={(e) => setInfluxDeviceToken(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Org</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxDeviceOrg || ''} 
                                    onChange={(e) => setInfluxDeviceOrg(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Bucket</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxDeviceBucket || ''} 
                                    onChange={(e) => setInfluxDeviceBucket(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className='box'>
                        <h3 className="title is-3">Influx counter</h3>
                        <div className="field">
                            <label className="label">Host</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxCounterHost || ''} 
                                    onChange={(e) => setInfluxCounterHost(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Port</label>
                            <div className="control">
                                <input className="input" 
                                    type="number" 
                                    placeholder=""
                                    value={influxCounterPort || 0} 
                                    onChange={(e) => setInfluxCounterPort(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Token</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxCounterToken || ''} 
                                    onChange={(e) => setInfluxCounterToken(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Org</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxCounterOrg || ''} 
                                    onChange={(e) => setInfluxCounterOrg(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Bucket</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxCounterBucket || ''} 
                                    onChange={(e) => setInfluxCounterBucket(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className='box'>
                        <h3 className="title is-3">Backup influx</h3>
                        <div className="field">
                            <label className="label">Host</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxBackupHost || ''} 
                                    onChange={(e) => setInfluxBackupHost(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Port</label>
                            <div className="control">
                                <input className="input" 
                                    type="number" 
                                    placeholder=""
                                    value={influxBackupPort || 0} 
                                    onChange={(e) => setInfluxBackupPort(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Token</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxBackupToken || ''} 
                                    onChange={(e) => setInfluxBackupToken(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Org</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxBackupOrg || ''} 
                                    onChange={(e) => setInfluxBackupOrg(e.target.value)}/>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Bucket</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder=""
                                    value={influxBackupBucket || ''} 
                                    onChange={(e) => setInfluxBackupBucket(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className='box'>
                        <h3 className="title is-3">Финансы</h3>
                        <div className="field">
                            <label className="label">Стоимость электроэнергии (1 кВт/ч)</label>
                            <div className="control">
                                <input className="input" 
                                    type="text" 
                                    placeholder="(руб.)"
                                    value={electricityCost || ''} 
                                    onChange={(e) => setElectricityCost(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <F2PoolList data={{
                        area_uuid: uuid
                    }} onCounterClick={(counter_uuid)=>{setSelectedCounterUUID(counter_uuid)}}/>
                    <CounterList data={{
                        area_uuid: uuid
                    }} onCounterClick={(counter_uuid)=>{setSelectedCounterUUID(counter_uuid)}}/>
                    <RoomList data={{
                        area_uuid: uuid
                    }} onCounterClick={(counter_uuid)=>{}}/>
                {/* </div> */}
                <footer className="card-footer">
                    {isProcessing ? <button className="button card-footer-item is-ghost is-loading" onClick={onSave}>Сохранить</button> : <button className="button card-footer-item is-ghost" onClick={onSave}>Сохранить</button>}
                </footer>
            {/* </div> */}
            <AreaContext.Provider value={{selectedCounterUUID,setSelectedCounterUUID}}>
                <CounterModal data={{
                        area_uuid: uuid
                    }}/>
            </AreaContext.Provider>
        </div>
    );
}
