import { React, Component, useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';

import { getAreasList } from '../../store/actions';
import {
  getIsAreasLoading
} from '../../store/selectors';
import Area from '../../components/Area/Area';
import { IoMdArrowDropdown } from 'react-icons/io';

export default function Areas() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [areas, setAreas] = useState([]);
    const isLoading = useSelector(state => getIsAreasLoading(state));

    const [selectedAreaIndex, setSelectedAreaIndex] = useState(0);
    const [areaDropDownShow, setAreaDropDownShow] = useState(false);

    const idBtnRef = useRef()

    useEffect( () => { 
        async function fetchData() {
            const result = await dispatch(getAreasList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setAreas(result.payload.data)
        }
        fetchData();
    }, []);

    useEffect(() => {
        const closeDropdown = e => {
            if(idBtnRef.current == null) return;

            if(idBtnRef.current.contains(e.target))   {
            } else  {
                setAreaDropDownShow(false)
            }
        }
        document.body.addEventListener('click', closeDropdown)

        return () => document.body.removeEventListener('click', closeDropdown)
    })

    return (
        (isLoading) ? (
            <div className="hero is-fullheight is-flex">
                <div className="container has-text-centered mt-4"> 
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                    />
                        <p className="mt-2">Загрузка</p>
                </div>
            </div>
        ):(
            <div className="columns is-multiline">
                {/* {
                    areas.map(item => {
                        return (
                            <Area data={item} key={item.id}/>
                        )
                    })
                } */}
                <div className="column is-three-fifths is-offset-one-fifth">
                    <div className="card mx-4 mt-4">
                        <header className="card-header is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                            <div>
                                {(areas.length > 0)?(
                                    <nav className="breadcrumb has-bullet-separator is-centered mt-3 mb-3" aria-label="breadcrumbs">
                                        <ul>
                                            {
                                                areas.map((item, index) => {
                                                    return (
                                                        <li key={index} className={(selectedAreaIndex == index) ? "is-active": ""}>
                                                            <a href="#" onClick={()=>{setSelectedAreaIndex(index)}}>{item.name}</a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </nav>):(
                                        null
                                    )
                                }
                            </div>
                            {/* <div className={(areaDropDownShow)?"dropdown is-active":"dropdown"}>
                                <div className="dropdown-trigger">
                                    <button ref={idBtnRef} className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={()=>{setAreaDropDownShow(!areaDropDownShow)}}>
                                        <span>
                                            {
                                                (areas.length > 0) ? (areas[selectedAreaIndex]['name']) : (null)
                                            }
                                        </span>
                                        <span className="icon is-small">
                                            {
                                                <IoMdArrowDropdown />
                                            }
                                        </span>
                                    </button>
                                </div>
                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                    <div className="dropdown-content">
                                        {
                                            areas.map((item, index) => {
                                                return (
                                                    <a key={index} href="#"
                                                        className={(index == selectedAreaIndex)?"dropdown-item is-active":"dropdown-item"}
                                                        onClick={()=>{
                                                            setSelectedAreaIndex(index)
                                                            setAreaDropDownShow(false)
                                                        }}
                                                    >
                                                        {item['name']}
                                                    </a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div> */}
                        </header>
                        <div className="card-content">
                            {
                                (areas.length > 0) ? (
                                    <Area data={areas[selectedAreaIndex]} key={selectedAreaIndex}/>
                                ) : (null)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}
