import { DEVICE_REQUEST_START, DEVICE_REQUEST_SUCCESS, DEVICE_REQUEST_FAILURE } from './actionTypes'
import DeviceProvider from '../../api/DeviceProvider'
import axios from 'axios';

export const dispatch_request_start = () => {
    return {
        type: DEVICE_REQUEST_START,
        payload: {}
    }
}

export const dispatch_request_success = () => {
    return {
        type: DEVICE_REQUEST_SUCCESS,
        payload: {}
    }
}

export const dispatch_request_failure = () => {
    return {
        type: DEVICE_REQUEST_FAILURE,
        payload: {}
    }
}

export const getDeviceList =  (area_uuid, abortController) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new DeviceProvider(abortController).fetchDeviceList(area_uuid);
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const getDevicePropertiesList =  (area_uuid, abortController) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new DeviceProvider(abortController).fetchDevicePropertiesList(area_uuid);
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const getBlockedDevicePropertiesList =  (area_uuid, abortController) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new DeviceProvider(abortController).fetchBlockedDevicePropertiesList(area_uuid);
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const getTotalHashrateHistory =  (area_uuid, range, cancelTokenInstance) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new DeviceProvider(cancelTokenInstance).fetchTotalHashrateHistory(area_uuid, range);
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const getTotalDeviceHistory =  (area_uuid, range, cancelTokenInstance) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new DeviceProvider(cancelTokenInstance).fetchTotalDeviceHistory(area_uuid, range);
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const getDeviceHashrateHistoryByMac =  (area_uuid, mac, range, cancelTokenInstance) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new DeviceProvider(cancelTokenInstance).fetchDeviceHashrateHistoryByMac(area_uuid, mac, range);
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const rebootDevice =  (area_id, mac) => async dispatch => {
    const res = await new DeviceProvider().rebootDevice(area_id, mac)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const pingDevice =  (area_id, mac) => async dispatch => {
    const res = await new DeviceProvider().pingDevice(area_id, mac)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const changeBeingModeForDevice =  (area_id, mac, mode_type) => async dispatch => {
    const res = await new DeviceProvider().changeBeingMode(area_id, mac, mode_type)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const changeBeingModeForRoom =  (area_id, room_id, isSleep) => async dispatch => {
    const res = await new DeviceProvider().changeBeingModeForRoom(area_id, room_id, isSleep)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getTaskInfo =  (task_id) => async dispatch => {
    const res = await new DeviceProvider().taskInfo(task_id)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const updateDeviceField =  (area_id, mac, field, data) => async dispatch => {
    console.log(area_id, mac, field, data)
    const res = await new DeviceProvider().updateDeviceField(area_id, mac, field, data)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

// --------------

export const createMiningDevice =  (device, cancelTokenInstance) => async dispatch => {
    const res = await new DeviceProvider(cancelTokenInstance).createMiningDevice(device)
    return res
}

export const getMiningDeviceList =  (cancelTokenInstance) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new DeviceProvider(cancelTokenInstance).fetchMiningDeviceList();
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const deleteMiningDevice =  (id, cancelTokenInstance) => async dispatch => {
    const res = await new DeviceProvider(cancelTokenInstance).deleteMiningDevice(id)
    return res
}

export const updateMiningDevice =  (device, cancelTokenInstance) => async dispatch => {
    const res = await new DeviceProvider(cancelTokenInstance).updateMiningDevice(device)
    return res
}

// --------------

export const getConsumptionList =  (area_id, cancelTokenInstance) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new DeviceProvider(cancelTokenInstance).fetchConsumptionList(area_id);
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const deleteConsumption =  (id, cancelTokenInstance) => async dispatch => {
    const res = await new DeviceProvider(cancelTokenInstance).deleteConsumption(id)
    return res
}

export const createConsumption =  (consumption, cancelTokenInstance) => async dispatch => {
    const res = await new DeviceProvider(cancelTokenInstance).createConsumption(consumption)
    return res
}

export const updateConsumption =  (consumption, cancelTokenInstance) => async dispatch => {
    const res = await new DeviceProvider(cancelTokenInstance).updateConsumption(consumption)
    return res
}

// ---------------

export const approveDeviceRequest =  (area_id, host_id, subnet_id, cancelTokenInstance) => async dispatch => {
    const res = await new DeviceProvider(cancelTokenInstance).approveDeviceRequest(area_id, host_id, subnet_id)
    return res
}

export const rejectDeviceRequest =  (area_id, ip, cancelTokenInstance) => async dispatch => {
    const res = await new DeviceProvider(cancelTokenInstance).rejectDeviceRequest(area_id, ip)
    return res
}

export const getAsicLogs =  (area_uuid, asic_ip, log_name, cancelTokenInstance) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new DeviceProvider(cancelTokenInstance).fetchAsicLogs(area_uuid, asic_ip, log_name);
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}