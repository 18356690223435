import { React, Component, useState, useEffect, useContext, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Oval } from  'react-loader-spinner'
import { getAreaCounters, recalculateCounterConsumption } from '../../store/actions';
import Swal from 'sweetalert2';
import { CounterContext } from '../../Pages/Counters/Counters';
import { IoMdArrowDropdown } from 'react-icons/io';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb'
import moment from 'moment';

export default function CounterRecalculateModal({data}) {

    const dispatch = useDispatch();
    const {counterRecalculateVisible, setCounterRecalculateVisible} = useContext(CounterContext)
    const [isCountersLoading, setIsCountersLoading] = useState(false);
    const [counterHistory, setCounterHistory] = useState([]);
    const [selectedCounterIndex, setSelectedCounterIndex] = useState(0);

    const [areaDropDownShow, setAreaDropDownShow] = useState(false);
    const idBtnRef = useRef()

    const [fromDateTime, setFromDateTime] = useState(moment().subtract(1, 'day'));
    const [toDateTime, setToDateTime] = useState(moment());
    const [price, setPrice] = useState(0);

    const [isProcessingRecalculate, setIsProcessingRecalculate] = useState(false);
    
    useEffect( () => { 
        const fetchAreasCounters = async () => {
            const result = await dispatch(getAreaCounters(data.areas[data.selectedAreaIndex]['id']))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setCounterHistory(result.payload.data)
            setIsCountersLoading(false)
        }

        if(counterRecalculateVisible)  {
            setIsCountersLoading(true)
            fetchAreasCounters();
        }

    }, [counterRecalculateVisible]);

    const onRecalculateProcessing = () => {
        console.log(price)
        if(price <= 0 || price == undefined)  {
            return Swal.fire({text:'Введите цену', icon: 'error'});
        }

        const recalculateCounter = async (area_id, counter_sn, startDate, endDate, amount) => {
            setIsProcessingRecalculate(true)
            const result = await dispatch(recalculateCounterConsumption(area_id, counter_sn, startDate, endDate, amount))
            setIsProcessingRecalculate(false)
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            closeAll()
        }

        var startDateString = fromDateTime.format('YYYY-MM-DDTHH:00:00');
        var endDateString = toDateTime.format('YYYY-MM-DDTHH:00:00');

        recalculateCounter(
            data.areas[data.selectedAreaIndex]['id'], 
            counterHistory[selectedCounterIndex]['serial_number'],
            startDateString,
            endDateString,
            price
        )
    }

    const closeAll = () => {
        setPrice(0)
        setFromDateTime(moment().subtract(1, 'day'))
        setToDateTime(moment())
        setCounterRecalculateVisible(false)
    }

    return (
        <div className={counterRecalculateVisible ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    closeAll()
                }}>
            </div>
            <div className="modal-content">
                <div className="box is-fullheight">
                    {(isCountersLoading)? (
                        <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
                            <Oval
                                height={80}
                                width={80}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperclassName=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                            <p className="mt-2">Загрузка</p>
                        </div>
                    ):(
                        <div className='is-flex is-flex-direction-column is-justify-content-space-around'>
                            <div className='content is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
                                <h3>Пересчитать расходы</h3>
                            </div>
                            <div className={(areaDropDownShow)?"dropdown is-active":"dropdown"}>
                                <div className="dropdown-trigger">
                                    <button ref={idBtnRef} className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={()=>{setAreaDropDownShow(!areaDropDownShow)}}>
                                        <span>
                                            {
                                                (counterHistory.length > 0) ? (counterHistory[selectedCounterIndex]['serial_number']) : (null)
                                            }
                                        </span>
                                        <span className="icon is-small">
                                            {
                                                <IoMdArrowDropdown />
                                            }
                                        </span>
                                    </button>
                                </div>
                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                    <div className="dropdown-content">
                                        {
                                            counterHistory.map((item, index) => {
                                                return (
                                                    <a key={index} href="#"
                                                        className={(index == selectedCounterIndex)?"dropdown-item is-active":"dropdown-item"}
                                                        onClick={()=>{
                                                            setSelectedCounterIndex(index)
                                                            setAreaDropDownShow(false)
                                                        }}>
                                                        {item['serial_number']}
                                                    </a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                    <DateTimePicker 
                                        views={['year', 'month', 'day', 'hours']} 
                                        label="С какой даты" format='DD.MM.YYYY HH:00' 
                                        className="mt-3"
                                        value={fromDateTime}
                                        onChange={(newValue) => setFromDateTime(newValue)}/>
                                    <DateTimePicker 
                                        views={['year', 'month', 'day', 'hours']} 
                                        label="До какой даты" 
                                        format='DD.MM.YYYY HH:00'
                                        value={toDateTime}
                                        onChange={(newValue) => setToDateTime(newValue)}/>
                                </DemoContainer>
                            </LocalizationProvider>
                            <div className="field mt-4">
                                <div className="control">
                                    <input className="input is-medium" 
                                        type="number" 
                                        placeholder="Новое значение стоимости (6.74)" 
                                        value={price || ''} 
                                        onChange={(e) => {
                                            const amount = parseFloat(e.target.value)
                                            if(isNaN(amount))   {
                                                setPrice(0)
                                            } else  {
                                                setPrice(amount)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <button className={(isProcessingRecalculate) ? "button is-danger mt-4 is-loading" : "button is-danger mt-4"} onClick={()=>{onRecalculateProcessing()}}>Пересчитать</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}