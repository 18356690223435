// transform to th/s
export const hashrateTransformation = (hashrate, rateUnit) => {
    let _multiply = 0
    switch (rateUnit)   {
        case 'mh/s':
            _multiply = 1000000;
            break;
        case 'gh/s':
            _multiply = 1000;
            break;
        default:
            _multiply = 1000;
    }

    const tfHash = hashrate / _multiply;
    return tfHash;
}