import { React, Component, useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import { userLogout } from '../../store/actions';

const Logout = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        async function asyncLogout() {
            await dispatch(userLogout());
            navigate('/', { replace: true });
        }
        asyncLogout();
    }, [])

    return <></>
}

export default Logout
