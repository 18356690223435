
// https://github.com/wwtvda/scv.ugm/blob/4a9c73a144fee081219afaff604166c9caee4eed/frontend/src/request/successHandler.js
export const successHandler = (response) => {
    console.log('successHandler => '+JSON.stringify(response.data))

    return {
        success: true,
        payload: {...response}
    }
}

// https://github.com/wwtvda/scv.ugm/blob/4a9c73a144fee081219afaff604166c9caee4eed/frontend/src/request/errorHandler.js
export const errorHandler = (error) => {
    console.log('errorHandler => '+error)

    return {
        success: false,
        message: error || 'An unknown error occurred in the app, please try again.'
    }
}
