import { React, Component, useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getAreaCounters, createCounter, deleteCounter } from '../../store/actions';

export default function CounterList({data, onCounterClick}) {

    const portDefaultValue = '/dev/ttyUSB0'

    const dispatch = useDispatch();

    const [counters, setCounters] = useState([]);

    const [serialNumber, setSerialNumber] = useState('');
    const [modelNumber, setModelNumber] = useState('');
    const [port, setPort] = useState(portDefaultValue);
    const [k, setK] = useState('');

    useEffect( () => { 
        const fetchAreasCounters = async () => {
            const result = await dispatch(getAreaCounters(data.area_uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setCounters(result.payload.data)
        }

        fetchAreasCounters();
    }, []);

    const onDeleteCounter = (counter_id) => {
        async function sendDeleteCounter(counter_id) {
            const result = await dispatch(
                deleteCounter(data.area_uuid, 
                    counter_id)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setCounters(counters.filter(item => item.id !== counter_id))
        }
        sendDeleteCounter(counter_id)
    }

    const Counter = ({counter}) => (
        <div className="control" key={counter.id}>
            <div className="tags has-addons">
                <a className="tag is-dark is-link" onClick={()=>{onCounterClick(counter.id)}}>{counter.serial_number}</a>
                <a className="tag is-delete" onClick={()=>{onDeleteCounter(counter.id)}}></a>
            </div>
        </div>
    )

    const isNumeric = (str) => {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const onAddCounter = () => {
        async function sendData() {
            if (serialNumber.length === 0) {
                return Swal.fire({text:'Введите серийный номер счётчика', icon: 'error'});
            } else if (modelNumber.length === 0) {
                return Swal.fire({text:'Введите номер модели', icon: 'error'});
            } else if(!isNumeric(k)) {
                return Swal.fire({text:'Коэффициент трансформации это число', icon: 'error'});
            }

            const kNum = parseFloat(k)

            const result = await dispatch(
                createCounter(data.area_uuid, 
                    serialNumber, 
                    modelNumber,
                    port,
                    kNum)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setCounters([...counters, result.payload.data])
            setSerialNumber('')
            setModelNumber('')
            setPort(portDefaultValue)
            setK('')
        }
        sendData()
    }

    return (
        <div className='box'>
            <h3 className="title is-3">Счетчики</h3>
            <div className="field is-grouped is-grouped-multiline">
                {
                    counters.map(item => {
                        return (
                            <Counter counter={item} key={item.id}/>
                        )
                    })
                }
            </div>

            <div className="field">
                <label className="label">Серийный номер</label>
                <input className="input" 
                    type="text" 
                    placeholder=""
                    value={serialNumber || ''} 
                    onChange={(e) => setSerialNumber(e.target.value)}/>
            </div>

            <div className="field">
                <label className="label">Номер модели</label>
                <input className="input" 
                    type="text" 
                    placeholder=""
                    value={modelNumber || ''} 
                    onChange={(e) => setModelNumber(e.target.value)}/>
            </div>

            <div className="field">
                <label className="label">Порт</label>
                <input className="input" 
                    type="text" 
                    placeholder=""
                    value={port || ''} 
                    onChange={(e) => setPort(e.target.value)}/>
            </div>

            <div className="field">
                <label className="label">Коэффициент трансформации</label>
                <input className="input" 
                    type="text" 
                    placeholder=""
                    value={k || ''} 
                    onChange={(e) => setK(e.target.value)}/>
            </div>

            <div className="control">
                <button className="button is-primary" onClick={onAddCounter}>Добавить</button>
            </div>
        </div>
    )
}