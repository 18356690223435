import { AREAS_REQUEST_START, AREAS_REQUEST_SUCCESS, AREAS_REQUEST_FAILURE, AREA_REQUEST_START, AREA_REQUEST_END } from './actionTypes'
import AreasProvider from '../../api/AreasProvider'

export const dispatch_request_start = () => {
    return {
        type: AREAS_REQUEST_START,
        payload: {}
    }
}

export const dispatch_request_success = () => {
    return {
        type: AREAS_REQUEST_SUCCESS,
        payload: {}
    }
}

export const dispatch_request_failure = () => {
    return {
        type: AREAS_REQUEST_FAILURE,
        payload: {}
    }
}

export const getAreasList =  () => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new AreasProvider().fetchAreasList()
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const updateArea =  (
    id, 
    name, 
    deviceCount, 
    referenceHashrate, 
    criticalHashrate, 
    percentHashrate,
    referenceHashrateLType,
    percentHashrateLType, 
    timeDissapear,
    minTempChip, 
    maxTempChip, 
    fanSpeedMin,
    fanSpeedMax,
    voltageChipMin,
    voltageChipMax, 
    tids,
    hhKey,
    ipRouter,
    electricity_cost, 
    f2PoolCurrency, 
    f2PoolSubaccountName,
    influxDeviceHost,
    influxDevicePort,
    influxDeviceToken,
    influxDeviceOrg,
    influxDeviceBucket,
    influxCounterHost,
    influxCounterPort,
    influxCounterToken,
    influxCounterOrg,
    influxCounterBucket,
    influxBackupHost,
    influxBackupPort,
    influxBackuprToken,
    influxBackupOrg,
    influxBackupBucket) => async dispatch => {

    dispatch({
        type: AREA_REQUEST_START,
        payload: {}
    });

    const res = await new AreasProvider().updateArea(id, 
        name, 
        deviceCount, 
        referenceHashrate, 
        criticalHashrate, 
        percentHashrate,
        referenceHashrateLType,
        percentHashrateLType,  
        timeDissapear, 
        minTempChip, 
        maxTempChip,
        fanSpeedMin,
        fanSpeedMax,
        voltageChipMin,
        voltageChipMax,   
        tids,
        hhKey,
        ipRouter,
        electricity_cost, 
        f2PoolCurrency, 
        f2PoolSubaccountName,
        influxDeviceHost,
        influxDevicePort,
        influxDeviceToken,
        influxDeviceOrg,
        influxDeviceBucket,
        influxCounterHost,
        influxCounterPort,
        influxCounterToken,
        influxCounterOrg,
        influxCounterBucket,
        influxBackupHost,
        influxBackupPort,
        influxBackuprToken,
        influxBackupOrg,
        influxBackupBucket)
    if (res.success)    {
        dispatch({
            type: AREA_REQUEST_END,
            payload: {}
        });
    } else  {
        dispatch({
            type: AREA_REQUEST_END,
            payload: {}
        });
    }

    return res
}

export const getAreaPools =  (uuid) => async dispatch => {
    const res = await new AreasProvider().fetchAreaPools(uuid)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const createPool =  (uuid, currency, subaccount, currencyType, serviceType) => async dispatch => {
    const res = await new AreasProvider().createPool(uuid, currency, subaccount, currencyType, serviceType)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const deletePool =  (area_id, pool_id) => async dispatch => {
    const res = await new AreasProvider().deletePool(area_id, pool_id)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getAreaCounters =  (uuid) => async dispatch => {
    const res = await new AreasProvider().fetchAreaCounters(uuid)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const createCounter =  (uuid, serial, model, port, k) => async dispatch => {
    const res = await new AreasProvider().createCounter(uuid, serial, model, port, k)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const deleteCounter =  (area_id, counter_id) => async dispatch => {
    const res = await new AreasProvider().deleteCounter(area_id, counter_id)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getAreaCounterByUUID =  (counter_id) => async dispatch => {
    const res = await new AreasProvider().fetchAreaCounterByUUID(counter_id)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const createCounterK =  (area_uuid, counter_uuid, k) => async dispatch => {
    const res = await new AreasProvider().createCounterK(area_uuid, counter_uuid, k)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getReportProfitableHistory =  (area_uuid, offset, limit) => async dispatch => {
    const res = await new AreasProvider().reportProfitableHistory(area_uuid, offset, limit)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getReportProfitableCounterHistory =  (area_uuid, time) => async dispatch => {
    const res = await new AreasProvider().reportProfitableCounnterHistory(area_uuid, time)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getReportProfitableShort =  (area_uuid) => async dispatch => {
    const res = await new AreasProvider().reportProfitableShort(area_uuid)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getCounterHistory =  (area_uuid, offset, limit) => async dispatch => {
    const res = await new AreasProvider().counterHistory(area_uuid, offset, limit)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getRoomRanges =  (uuid) => async dispatch => {
    const res = await new AreasProvider().fetchRoomRanges(uuid)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const createRoom =  (uuid, firstIP, lastIP, name) => async dispatch => {
    const ipRange = firstIP + '-' + lastIP
    const res = await new AreasProvider().createRoom(uuid, firstIP, lastIP, ipRange, name)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const deleteRoom =  (area_id, room_id) => async dispatch => {
    const res = await new AreasProvider().deleteRoom(area_id, room_id)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getCounterConsumptionRange =  (area_uuid, startDate, endDate) => async dispatch => {
    const res = await new AreasProvider().counterConsumptionRange(area_uuid, startDate, endDate)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getCounterConsumptionSamplingRange =  (area_uuid, sampling, startDate, endDate) => async dispatch => {
    const res = await new AreasProvider().counterConsumptionSamplingRange(area_uuid, sampling, startDate, endDate)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getProfitConsumptionRange =  (area_uuid, startDate, endDate) => async dispatch => {
    const res = await new AreasProvider().profitConsumptionRange(area_uuid, startDate, endDate)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getProfitableConsumptionSamplingRange =  (area_uuid, sampling, startDate, endDate) => async dispatch => {
    const res = await new AreasProvider().profitConsumptionSamplingRange(area_uuid, sampling, startDate, endDate)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getPhaseExtendedConsumptionRange =  (area_uuid, startDate, endDate) => async dispatch => {
    const res = await new AreasProvider().phaseExtendedConsumptionRange(area_uuid, startDate, endDate)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getPhaseConsumptionRange =  (area_uuid, phase_type, startDate, endDate) => async dispatch => {
    const res = await new AreasProvider().phaseConsumptionRange(area_uuid, phase_type, startDate, endDate)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getPhaseConsumptionSamplingRange =  (area_uuid, phase_type, sampling, counter_sn, startDate, endDate) => async dispatch => {
    const res = await new AreasProvider().phaseConsumptionSamplingRange(area_uuid, phase_type, sampling, counter_sn, startDate, endDate)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getAlertsUserList =  (area_id) => async dispatch => {
    const res = await new AreasProvider().fetchAlertsUserList(area_id);
    if (res.success)    {
       
    } else  {

    }

    return res
}

export const setAlertsDataList =  (area_id, data) => async dispatch => {
    const res = await new AreasProvider().setAlertData(area_id, data);
    if (res.success)    {
       
    } else  {

    }

    return res
}

export const getDashboardShortDataByRange =  (startDate, endDate) => async dispatch => {
    const res = await new AreasProvider().dashboardShortDataByRange(startDate, endDate)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getDashboardAreasStatusByRange =  (startDate, endDate) => async dispatch => {
    const res = await new AreasProvider().dashboardAreasStatusByRange(startDate, endDate)
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const getAreaHashrateHistory =  (area_uuid, range) => async dispatch => {

    const res = await new AreasProvider().fetchAreaHashrateHistory(area_uuid, range);
    if (res.success)    {
        
    } else  {
        
    }

    return res
}

export const recalculateCounterConsumption =  (area_uuid, counter_sn, startDate, endDate, amount) => async dispatch => {
    const res = await new AreasProvider().counterConsumptionRecalculate(area_uuid, counter_sn, startDate, endDate, amount)
    return res
}

export const getMonitors =  () => async dispatch => {
    const res = await new AreasProvider().fetchMonitors()
    return res
}

export const getMonitorBeats =  (monitor_id) => async dispatch => {
    const res = await new AreasProvider().fetchMonitorBeats(monitor_id)
    return res
}

export const getGGU =  () => async dispatch => {
    const res = await new AreasProvider().fetchGGU()
    return res
}

export const getGGUBeats =  (ggu_name) => async dispatch => {
    const res = await new AreasProvider().fetchGGUBeats(ggu_name)
    return res
}

export const addStatusPageEvent =  (area_id, monitor_id, type, timeStart, timeEnd, description) => async dispatch => {
    const res = await new AreasProvider().createStatusPageEvent(area_id, monitor_id, type, timeStart, timeEnd, description);
    return res
}


export const getMaintenanceList =  () => async dispatch => {
    const res = await new AreasProvider().fetchMaintenanceList()
    return res
}
export const getMonitorList =  () => async dispatch => {
    const res = await new AreasProvider().fetchMonitorList()
    return res
}
export const addMaintenance =  (area_id, title, description, startDate, endDate, monitors) => async dispatch => {
    const res = await new AreasProvider().addMaintenance(area_id, title, description, startDate, endDate, monitors);
    return res
}
export const deleteMaintenance =  (area_id, maintenance_id) => async dispatch => {
    const res = await new AreasProvider().deleteMaintenance(area_id, maintenance_id)
    return res
}


export const fetchDeviceCountMonitor =  (area_id, from_timestamp=0, to_timestamp=0) => async dispatch => {
    const res = await new AreasProvider().fetchDeviceCountMonitor(area_id, from_timestamp, to_timestamp)
    return res
}
