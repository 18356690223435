
const DeviceList = ({devices, onClickDevice}) => {
    return (
        
        <div className="columns is-multiline">
            {
                devices.map(device => {
                    return (
                        <div className="column is-one-third" key={device.id}>
                            <div className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        <span>#{device.id+1} (<span className="is-underlined">{device.mac}</span>)</span>
                                    </p>
                                </header>
                                <div className="card-content">
                                    <div className="content has-text-centered">
                                        <span className="is-size-4">{Number((device.hashrate).toFixed(2))} GH/s</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default DeviceList;