export const REQUEST_START = 'REQUEST_START';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';

export const RESET_STATE = 'RESET_STATE';

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

export const DEVICE_REQUEST_START = 'DEVICE_REQUEST_START';
export const DEVICE_REQUEST_SUCCESS = 'DEVICE_REQUEST_SUCCESS';
export const DEVICE_REQUEST_FAILURE = 'DEVICE_REQUEST_FAILURE';

export const AREAS_REQUEST_START = 'AREAS_REQUEST_START';
export const AREAS_REQUEST_SUCCESS = 'AREAS_REQUEST_SUCCESS';
export const AREAS_REQUEST_FAILURE = 'AREAS_REQUEST_FAILURE';
export const AREA_REQUEST_START = 'AREA_REQUEST_START';
export const AREA_REQUEST_END = 'AREA_REQUEST_END';