import {
    AREAS_REQUEST_FAILURE,
    AREAS_REQUEST_START,
    AREAS_REQUEST_SUCCESS,
    AREA_REQUEST_START,
    AREA_REQUEST_END
} from '../actions/actionTypes';
import storePersist from "../storePersist";

export const initialState = {
    isLoading: false,
    errorMessage: null,
    isProcessing: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case AREAS_REQUEST_START:
            return {
                ...state,
                isLoading: true,
                errorMessage: null,
            };
        case AREAS_REQUEST_SUCCESS:
            const data = {
                ...state,
                isLoading: false,
                errorMessage: null
            };
            return data
        case AREAS_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: null,
            };
        case AREA_REQUEST_START:
            return {
                ...state,
                isProcessing: true
            };
        case AREA_REQUEST_END:
            return {
                ...state,
                isProcessing: false
            };

        default:
            return state;
    }
};