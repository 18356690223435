import { React, Component, useState, useEffect, createContext, useMemo, useRef, useCallback } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import Pagination from 'bulma-pagination-react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Box, Grid, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import { BarChart, BarPlot } from '@mui/x-charts/BarChart';
import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

import {dataset} from './dataset'
import { fetchDeviceCountMonitor } from '../../store/actions';
import MonitorModal from './MonitorModal';

export default function Monitors({dataProps}) {
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [series, setSeries] = useState([]);
    const [referenceDeviceCount, setReferenceDeviceCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect( () => { 
        async function fetchDeviceCountMonitorRequest(area_id) {
            setIsLoading(true)
            const result = await dispatch(fetchDeviceCountMonitor(area_id))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setData(result.payload.data['data'])

            const modelNames = result.payload.data['models']
            let ser = Array()
            modelNames.map((title) => {
                ser.push({
                    dataKey: title,
                    label: title,
                    stack: 'A',
                    stackOffset: 'none',
                    stackOrder: 'ascending',
                    valueFormatter
                })
            })
            
            setSeries(ser)
            setReferenceDeviceCount(result.payload.data.reference_device_count)
            setIsLoading(false)
        }

        const areaUuid = dataProps.areas[dataProps.areaIndex]?.id
        if(areaUuid != undefined)   {
            fetchDeviceCountMonitorRequest(areaUuid)
        }
        
    }, [dataProps]);

    const valueFormatter = (value) => `${value} шт.`;

    const chartSetting = {
        yAxis: [
            {
                id: 'yAxis',
                label: 'количество (шт.)',
            },
        ],
        series: series,
        height: 400,
        sx: {
            [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
                transform: 'translateX(-10px)',
            },
        },
        bottomAxis:{
            tickLabelStyle: {
                angle: 90,
                textAnchor: 'start',
                fontSize: 10,
            },
        },
    };

    const [openMonitorModal, setOpenMonitorModal] = useState({
        open: false,
        data: null,
        area_id: null
    });
    const handleOpenMonitorModal = useCallback((event, params) => {
        console.log('Clicked bar:', params);
        const dataIndex = params.dataIndex

        const areaUuid = dataProps.areas[dataProps.areaIndex]?.id
        if(areaUuid == undefined)
            return

        setOpenMonitorModal({
            open: true,
            data: data[dataIndex],
            area_id: areaUuid
        });
    }, [data]);
    const handleCloseMonitorModal = useCallback(() => {
        setOpenMonitorModal({
            open: false,
            data: null,
            area_id: null
        });
    }, []);

    return (
        (isLoading) ? (
            <div className="hero is-fullheight is-flex">
                <div className="container has-text-centered mt-4"> 
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                    />
                        <p className="mt-2">Загрузка</p>
                </div>
            </div>
        ):(
            <div style={{ width: '100%' }}>
                {
                    (data.length == 0) ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            height="100px" // Задайте высоту по вашему усмотрению
                            >
                                <Typography variant="body1" style={{ marginTop: '16px' }}>
                                    На данный момент нет данных для этой площадки
                                </Typography>
                        </Box>
                    ) : (
                        <BarChart
                            dataset={data}
                            xAxis={[
                                { scaleType: 'band', dataKey: 'time', tickPlacement:'middle', tickLabelPlacement:'middle' },
                            ]}
                            {...chartSetting}
                        >
                            <BarPlot onItemClick={handleOpenMonitorModal} />

                            {(referenceDeviceCount > 0) ? (
                                <ChartsReferenceLine
                                    axisId="yAxis"
                                    y={referenceDeviceCount}  // Значение, на котором будет нарисована горизонтальная линия
                                    label={`Эталон (${referenceDeviceCount})`}
                                    labelAlign="end"
                                    lineStyle={{ stroke: 'red', strokeWidth: 2 }}
                                    labelStyle={{ fill: 'red', fontSize: 12 }}
                                />
                            ): null}
                            
                        </BarChart>
                    )
                }
                <MonitorModal open={openMonitorModal} onClose={handleCloseMonitorModal}/>
            </div>
        )
    );
}

