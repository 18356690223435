import { React, Component, useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tab from '../../components/Tab/Tab';

const MainMenu = ({user}) => {
    const [isBurgerSelected, setBurgerSelected] = useState(false);

    const onBurgerClick = () => {
        setBurgerSelected(!isBurgerSelected)
    }

    return (
        <>
            <nav className="navbar is-info">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/">
                        {
                        <img src={process.env.PUBLIC_URL + '/rocket_icon.png'}  alt=""/>
                        }
                        <span className='ml-2'>FWRocketMonitor</span>
                    </a>
                    <div className={isBurgerSelected ? 'navbar-burger burger is-active' : 'navbar-burger burger'} data-target="navbarExampleTransparentExample" onClick={onBurgerClick}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="navbar-item is-centered" style={{backgroundColor:'transparent', left:'10px'}}>
                    {
                        <Tab user={user}/>
                    }
                </div>
                <div id="navbarExampleTransparentExample" className={isBurgerSelected ? 'navbar-menu is-active' : 'navbar-menu'}>
                    <div className="navbar-end">
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a className="navbar-link" href="/documentation/overview/start/">
                                Account
                            </a>
                            <div className="navbar-dropdown is-boxed">
                                <a className="navbar-item" href="/">
                                    Settings
                                </a>
                                <hr className="navbar-divider"/>
                                <a className="navbar-item" href="/logout">
                                    Logout
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
        
    )
};

export default MainMenu;
