import { React, Component, useState, useEffect, useContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

import { DeviceContext } from './Dashboard';
import { getDevicePropertiesList } from '../../store/actions';
import { Line, Chart } from 'react-chartjs-2';

export default function AreaDevicesModal({data, onFinish}) {

    const headers = [
        { label: "Объект", key: "location_name" },
        { label: "IP", key: "ip" },
        { label: "Помещение", key: "room" },
        { label: "Линия", key: "line" },
        { label: "Место", key: "place" },
        { label: "Модель", key: "model" },
        { label: "S/N", key: "sn" },
        { label: "MAC address", key: "mac" },
        { label: "Worker", key: "worker" },
        { label: "ИМЯ", key: "worker_edit" },
        { label: "Температура", key: "temperature" },
        { label: "FAN SPEED", key: "fan_speed" },
        { label: "Хешрейт", key: "hashrate" },
        { label: "Хешрейт ideal", key: "hashrate_ideal" },
        { label: "Кол-во хешплат", key: "chain_num" },
        { label: "Онлайн", key: "is_online" },
        { label: "Runtime", key: "runtime" },
        { label: "ПУЛ/ПОРТ", key: "pools_url" },
        { label: "ШАРЫ REJECTED", key: "pool_rejected" },
        { label: "Чипы work:rejected", key: "chain_asic" },
        { label: "ИМЯ ПУЛА", key: "name" },
        { label: "ПРОШИВКА", key: "compile_time" },
      ];

    const dispatch = useDispatch();
    const {selectedArea, setSelectedArea} = useContext(DeviceContext)

    const [historyLoading, setHistoryLoading] = useState(false)
    const [deviceProperties, setDeviceProperties] = useState([]);

    useEffect( () => { 
        async function fetchData(area_id) {
            setHistoryLoading(true)

            const result = await dispatch(getDevicePropertiesList(area_id))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            
            const data = result.payload.data
            let ret = []
            data.map(item => {
                const meta = JSON.parse(item.meta)
                
                if(Object.keys(meta).length > 0 && item['is_online'] == true)  {
                    ret.push(item)
                }
            })
            setDeviceProperties(ret)

            setHistoryLoading(false)
        }
    
        if(selectedArea !== null)   {
            fetchData(selectedArea.area_id)
        }
    }, [selectedArea]);

    const fieldNoWarningColor = 'is-size-7 has-text-centered is-vcentered'
    const fieldWarningColor = 'is-size-7 has-text-centered is-vcentered has-text-danger'

    // transform to th/s
    const hashrateTransformation = (hashrate, rateUnit) => {
        let _multiply = 0
        switch (rateUnit)   {
            case 'mh/s':
                _multiply = 1000000;
                break;
            case 'gh/s':
                _multiply = 1000;
                break;
            default:
                _multiply = 1000;
        }

        const tfHash = hashrate / _multiply;
        return tfHash;
    }

    const formatTime = (value) => {
        var
        d = Math.trunc(value / 60    / 60   / 24).toString().padStart(2, '0'),
        h = Math.trunc(value % 86400 / 60   / 60).toString().padStart(2, '0'),
        m = Math.trunc(value % 86400 % 3600 / 60).toString().padStart(2, '0'),
        s = Math.trunc(value % 86400 % 3600 % 60).toString().padStart(2, '0');

        return (d > 0) ? d + ' days ' + h + ':' + m + ':' + s : h + ':' + m + ':' + s
    }

    return (
        <div className={selectedArea !== null ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    setDeviceProperties([])
                    setSelectedArea(null)
                }}>
            </div>
            <div className="modal-content" style={{backgroundColor:'white', height:'70%', width:'95%'}}>
                <div style={{backgroundColor:'white', width:'100%', height:'100%'}}>
                    {
                        (historyLoading) ? (
                            <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center" style={{backgroundColor:'transparent', height:'100%'}}>
                                    <Oval
                                        height={80}
                                        width={80}
                                        color="blue"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="white"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                        />
                            </div>
                        ):(
                            <div className="table-container">
                                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                            {
                                                headers.map((item, index) => {
                                                    return (
                                                        <th key={index}><p className='is-size-6'>{item['label']}</p></th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            deviceProperties.map((device, index) => {
                                                const meta = JSON.parse(device.meta)

                                                return (
                                                    <tr key={device.id}>
                                                        <td className='is-size-7 has-text-centered is-vcentered'>{device.location_name}</td>
                                                        <td className='is-size-7 has-text-centered is-vcentered'>{device.ip}</td>
                                                        <td className='is-size-7 has-text-centered is-vcentered'>{(device['room'] == null) ? 'unknown' : device['room']}</td>
                                                        <td className='is-size-7 has-text-centered is-vcentered'>{(device['line'] == null) ? 'unknown' : device['line']}</td>
                                                        <td className='is-size-7 has-text-centered is-vcentered'>{(device['place'] == null) ? 'unknown' : device['place']}</td>
                                                        <td className={meta.hasOwnProperty("model") ? fieldWarningColor : fieldNoWarningColor}>{device.model}</td>
                                                        <td className='is-size-7 has-text-centered is-vcentered'>{(device['sn'] == null) ? 'unknown' : device['sn']}</td>
                                                        <td className='is-size-7 has-text-centered is-vcentered'>{device['mac']}</td>
                                            
                                                        <td className='is-size-7 has-text-centered is-vcentered'>{(device['worker_edit'] == null) ? 'unknown' : device['worker_edit']}</td>
                                                        <td className={meta.hasOwnProperty("worker") ? fieldWarningColor : fieldNoWarningColor}>{device.worker}</td>
                                                        <td className={meta.hasOwnProperty("temperature") ? fieldWarningColor : fieldNoWarningColor}>{device.temperature} ℃</td>
                                                        <td className={meta.hasOwnProperty("fan_speed") ? fieldWarningColor : fieldNoWarningColor}>
                                                            {device['fan_speed']}
                                                        </td>
                                                        <td className={meta.hasOwnProperty("hashrate") ? fieldWarningColor : fieldNoWarningColor}>{hashrateTransformation(device.hashrate, device.rate_unit)} TH/s</td>
                                                        <td className={meta.hasOwnProperty("hashrate_ideal") ? fieldWarningColor : fieldNoWarningColor}>{hashrateTransformation(device.hashrate_ideal, device.rate_unit)} TH/s</td>
                                                        <td className={meta.hasOwnProperty("chain_num") ? fieldWarningColor : fieldNoWarningColor}>{device.chain_num}</td>
                                                        <td className='is-size-7 has-text-centered is-vcentered'>{device.is_online ? '🟢' : '🔴'}</td>
                                                        <td className='is-size-7 has-text-centered is-vcentered'>{formatTime(device.runtime)}</td>
                                                        <td className={meta.hasOwnProperty("pools_url") ? fieldWarningColor : fieldNoWarningColor}>
                                                            <div className="content">
                                                                <ol type="1">
                                                                    {
                                                                        (device['pools_url'] != null)?
                                                                            device['pools_url'].split('|').map((txt, index) => {
                                                                                return (
                                                                                    <li key={index}>{txt.substr(14)}</li>
                                                                                )
                                                                            }):<></>
                                                                    }
                                                                </ol>
                                                            </div>
                                                        </td>

                                                        <td className={meta.hasOwnProperty("pool_rejected") ? fieldWarningColor : fieldNoWarningColor}>
                                                            {device['pool_rejected']}
                                                        </td>
                                                        <td className={meta.hasOwnProperty("chain_asic") ? fieldWarningColor : fieldNoWarningColor}>
                                                            {device['chain_asic']}
                                                        </td>
                                                        <td className={meta.hasOwnProperty("name") ? fieldWarningColor : fieldNoWarningColor}>{device.name}</td>
                                                        <td className={meta.hasOwnProperty("compile_time") ? fieldWarningColor : fieldNoWarningColor}>{device.compile_time}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}