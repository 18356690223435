import { REQUEST_START, REQUEST_SUCCESS, REQUEST_FAILURE, RESET_STATE, AUTH_LOGOUT_SUCCESS } from './actionTypes'
import AuthProvider from '../../api/AuthProvider'

export const dispatch_request_start = () => {
    return {
        type: REQUEST_START,
        payload: {}
    }
}

export const dispatch_request_success = (payload) => {
    return {
        type: REQUEST_SUCCESS,
        payload: payload
    }
}

export const dispatch_request_failure = () => {
    return {
        type: REQUEST_FAILURE,
        payload: {}
    }
}

export const dispatch_reset_state = () => {
    return {
        type: RESET_STATE,
        payload: {}
    }
}

export const test =  (username, password) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new AuthProvider().login(username, password);
    if (res.success)    {
        dispatch(dispatch_request_success(res.payload.data));
    } else  {
        dispatch(dispatch_request_failure());
    }
    return res
}

export const userLogin =  (username, password) => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new AuthProvider().login(username, password);
    if (res.success)    {
        dispatch(dispatch_request_success(res.payload.data));
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const userMe =  () => async dispatch => {
    dispatch(dispatch_request_start());

    const res = await new AuthProvider().fetchMe();
    if (res.success)    {
        dispatch(dispatch_request_success());
    } else  {
        dispatch(dispatch_request_failure());
    }

    return res
}

export const userLogout =  () => async dispatch => {
    dispatch(
        {
            type: AUTH_LOGOUT_SUCCESS,
            payload: {}
        }
    )
}

export const getUserList =  () => async dispatch => {
    const res = await new AuthProvider().fetchUserList();
    if (res.success)    {
       
    } else  {

    }

    return res
}

export const changeUserAreas =  (areas) => async dispatch => {
    const res = await new AuthProvider().changeUserAreas(areas);
    if (res.success)    {
       
    } else  {

    }

    return res
}

export const deleteUser =  (id) => async dispatch => {
    const res = await new AuthProvider().deleteUser(id);
    if (res.success)    {
       
    } else  {

    }

    return res
}

export const createUser =  (username, accessType, areas, telegramAcc) => async dispatch => {
    const res = await new AuthProvider().createUser(username, accessType, areas, telegramAcc);
    if (res.success)    {
       
    } else  {

    }

    return res
}

export const getUserAlerts =  (area_id, user_id) => async dispatch => {
    const res = await new AuthProvider().fetchUserAlerts(area_id, user_id);
    if (res.success)    {
       
    } else  {

    }

    return res
}

export const setUserAlerts =  (area_id, user_id, alerts) => async dispatch => {
    const res = await new AuthProvider().setUserAlerts(area_id, user_id, alerts);
    if (res.success)    {
       
    } else  {

    }

    return res
}