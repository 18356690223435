import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Stack,
} from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo/DemoContainer';
import moment from 'moment';

const EventDialog = React.memo(({ open, onClose }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');

  const handleSave = () => {
    if (description.length > 50) {
      setError('Описание не должно превышать 50 символов');
      return;
    } else if(description.length == 0) {
      setError('Описание является обязательным полем');
      return;
    }
    // Дополнительная логика сохранения данных
    onClose({
      monitorIndex: open.monitorIndex,
      startTime: (moment(startDate).valueOf()/1000),
      endTime: (moment(endDate).valueOf()/1000),
      description
    });
  };

  const handleClose = () => {
    onClose({
      monitorIndex: 0,
      startTime: 0,
      endTime: 0,
      description: ''
    });
  };

  useEffect(() => {
    setDescription('')
    setError('')
    setStartDate(moment.utc(open.startTime * 1000))
    setEndDate(moment.utc(open.endTime * 1000))
  }, [open])

  return (
    <Dialog open={open.open} onClose={handleClose}>
      <DialogTitle>Создать событие</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
          <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
            <Stack direction="row" spacing={2} alignItems="center">
              <DemoItem>
                <DateTimePicker
                  label="Дата начала"
                  format='DD.MM.YYYY HH:mm' 
                  timeSteps={{ minutes: 1 }}
                  value={startDate}
                  onChange={setStartDate}
                />
              </DemoItem>
              <DemoItem>
                <DateTimePicker
                  label="Дата окончания"
                  format='DD.MM.YYYY HH:mm' 
                  timeSteps={{ minutes: 1 }}
                  value={endDate}
                  onChange={setEndDate}
                />
              </DemoItem>
            </Stack>
          </DemoContainer>
        </LocalizationProvider>
        <TextField
          label="Описание"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          error={!!error}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default EventDialog;
