import { React, Component, useState, useEffect, useContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';

import { DeviceContext } from './DevicesDashboard';
import { getDeviceHashrateHistoryByMac } from '../../store/actions';
import { Line } from 'react-chartjs-2';

export default function DeviceHashrateHistory({data, onFinish}) {

    const dispatch = useDispatch();
    const {selectedDevice, setSelectedDevice} = useContext(DeviceContext)

    const [historyLoading, setHistoryLoading] = useState(false)
    const [selectedTimeRange, setSelectedTimeRange] = useState("1d")
    const [totalHashrateChartData, setTotalHashrateChartData] = useState({
        labels: [],
        datasets: [
        ],
        options: {}
    });

    useEffect( () => { 
        if(selectedDevice !== null)   {
            setSelectedTimeRange("1d")
        } else  {
            setSelectedTimeRange("fsgfdgfdsfg")
            setTotalHashrateChartData({})
        }
    }, [selectedDevice]);

    useEffect( () => { 
        async function fetchData(area_id, mac, range) {
            setHistoryLoading(true)

            const result = await dispatch(getDeviceHashrateHistoryByMac(area_id, mac, range))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            const hhList = result.payload.data

            let x = []
            let y = []
            hhList.map((obj) => {
                x.push(new Date(Date.parse(obj.time)).toLocaleString())
                y.push(obj.hashrate)
            })

            setTotalHashrateChartData({
                labels: x,
                datasets: [
                    {
                        label: ''+selectedDevice.mac,
                        data: y,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                ],
                options: {
                    maintainAspectRatio: false,
                }
            })

            setHistoryLoading(false)
        }
        
        if(selectedDevice !== null)   {
            fetchData(selectedDevice.area_id, selectedDevice.mac, selectedTimeRange)
        }
    }, [selectedTimeRange]);

    return (
        <div className={(selectedDevice !== null) ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    setSelectedDevice(null)
                }}>
            </div>
            <div className="modal-content" style={{backgroundColor:'white', height:'60%', width:'80%'}}>
                <div style={{backgroundColor:'white', width:'100%', height:'100%'}}>
                    <div>
                        <div className="tabs is-centered">
                            <ul>
                                <li className={(selectedTimeRange == '1h')?'is-active':''}><a onClick={()=>{setSelectedTimeRange("1h")}}>Час</a></li>
                                <li className={(selectedTimeRange == '1d')?'is-active':''}><a onClick={()=>{setSelectedTimeRange('1d')}}>День</a></li>
                                <li className={(selectedTimeRange == '1w')?'is-active':''}><a onClick={()=>{setSelectedTimeRange('1w')}}>Неделя</a></li>
                                <li className={(selectedTimeRange == '1M')?'is-active':''}><a onClick={()=>{setSelectedTimeRange('1M')}}>Месяц</a></li>
                                 <li className={(selectedTimeRange == '1Y')?'is-active':''}><a onClick={()=>{setSelectedTimeRange('1Y')}}>Год</a></li>
                            </ul>
                        </div>
                    </div>
                    {
                        (historyLoading) ? (
                            <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center" style={{backgroundColor:'transparent', height:'100%'}}>
                                    <Oval
                                        height={80}
                                        width={80}
                                        color="blue"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="white"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                        />
                            </div>
                        ):(
                            <div style={{width:'100%', height:'90%'}}>
                                <Line options={
                                    {
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                            title: {
                                                display: false,
                                                text: 'Hashrate',
                                            }
                                        },
                                    }
                                } data={totalHashrateChartData}/>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}