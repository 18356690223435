import { React, Component, useState, useEffect, createContext, useMemo, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import Pagination from 'bulma-pagination-react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, MenuItem, Tooltip } from '@mui/material';

import { getIsAreasProcessing } from '../../store/selectors';
import { MRT_ActionMenuItem, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { deleteConsumption, getConsumptionList } from '../../store/actions/device';
import { Delete, Edit } from '@mui/icons-material';
import CounterRecalculateModal from '../../components/Counter/CounterRecalculateModal';
import AddConsumptionModal from './AddConsumptionModel';

export const ConsumptionContext = createContext('');

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

export default function AddConsumption({areas}) {
    const dispatch = useDispatch();

    const [areaIndex, setAreaIndex] = useState(0);
    const [data, setData] = useState([]);
    const [consumptionAddModalVisible, setConsumptionAddModalVisible] = useState(false);
    const prevConsumptionAddModalVisible = usePrevious(consumptionAddModalVisible)
    const [selectedConsumption, setSelectedConsumption] = useState({});
    const [value, setValue] = useState(0);
    
    useEffect( () => { 
        const fetchData = async (area_id) => {
            const result = await dispatch(getConsumptionList(area_id))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setData(result.payload.data)
        }

        fetchData(areas[areaIndex].id)
    }, [areas, value, areaIndex]);

    useEffect( () => { 
        if(prevConsumptionAddModalVisible && !consumptionAddModalVisible)    {
            console.log('Closed')
            setValue(value => value + 1);
        }
    }, [consumptionAddModalVisible]);

    const onSwitchArea = async (index) => {
        setAreaIndex(index)
    }

    const columns = useMemo(() => [
        {
            id: 'name',
            header: 'Название',
            accessorFn: (row) => row['name'],
            filterVariant: 'text',
        },
        {
            id: 'consumption',
            header: 'Расход (кВт/ч)',
            accessorFn: (row) => row['consumption'],
            filterVariant: 'text',
        },
        {
            id: 'start_date',
            header: 'Дата начала',
            accessorFn: (row) => new Date(row['start_date']*1000).toUTCString(),
            filterVariant: 'text',
        },
        {
            id: 'end_date',
            header: 'Дата завершения',
            accessorFn: (row) => {
                if(row['is_infinitely'])    {
                    return '-'
                }
                const endDate = new Date(row['end_date']*1000).toUTCString()
                return endDate
            },
            filterVariant: 'text',
        }
    ], [])

    // https://www.material-react-table.dev/?path=/story/features-memo-mode-examples--no-memos
    const table = useMaterialReactTable({
        columns,
        data: data,
        enablePagination: false,
        enableRowNumbers: true,
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        enableColumnFilterModes: true,
        enableFacetedValues: true,
        enableColumnResizing: true,
        layoutMode: 'grid',

        renderTopToolbarCustomActions: ({table}) => {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        gap: '16px',
                        padding: '8px',
                        flexWrap: 'wrap',
                        flexDirection:'row',
                        justifyContent:'space-between'
                    }}>
                    <Tooltip title="Create new device">
                        <IconButton onClick={()=>{
                            setSelectedConsumption({})
                            setConsumptionAddModalVisible(true)
                        }}>
                            <AddBoxIcon />
                        </IconButton>
                    </Tooltip>
                </Box>)
        },

        enableRowActions: true,
        renderRowActionMenuItems: ({ closeMenu, row, table }) => [
            <MRT_ActionMenuItem //or just use a normal MUI MenuItem component
                icon={<Edit />}
                key="edit"
                label="Edit"
                onClick={() => {
                    setSelectedConsumption(row.original)
                    setConsumptionAddModalVisible(true)
                    closeMenu()
                }}
                table={table}
                />,
            <MRT_ActionMenuItem
                icon={<Delete />}
                key="delete"
                label="Delete"
                onClick={() => {
                    onDeleteConsumption(row.original.id)
                    closeMenu()
                }}
                table={table}
                />,
        ],
    })

    const onDeleteConsumption = (uuid) => {
        async function sendDeleteConsumption(uuid) {
            const result = await dispatch(deleteConsumption(uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setData(data.filter(item => item.id !== uuid))
        }
        sendDeleteConsumption(uuid)
    }

    return (
        <>
            <div className="column is-full">
                <div className="card">
                    <header className="card-header is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                        <div>
                            {(areas.length > 0)?(
                                <nav className="breadcrumb has-bullet-separator is-centered mt-3 mb-3" aria-label="breadcrumbs">
                                    <ul>
                                        {
                                            areas.map((item, index) => {
                                                return (
                                                    <li key={index} className={(areaIndex == index) ? "is-active": ""}>
                                                        <a href="#" onClick={()=>{onSwitchArea(index)}}>{item.name}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </nav>):(
                                    null
                                )
                            }
                        </div>
                    </header>
                    <div className="card-content">
                        <MaterialReactTable table={table}/>
                    </div>
                </div>
            </div>

            <ConsumptionContext.Provider value={{consumptionAddModalVisible, setConsumptionAddModalVisible}}>
                <AddConsumptionModal data={{
                    areas: areas,
                    selectedAreaIndex: areaIndex,
                    selectedConsumption: selectedConsumption,
                }}/>
            </ConsumptionContext.Provider>
        </>
    );
}

