import React, { useEffect } from "react"
import { connect, useSelector, useDispatch } from 'react-redux';

import AuthRouter from "./AuthRouter";
import AppRouter from "./AppRouter";
import MainMenu from "../Layout/MainMenu/MainMenu";
import { getCurrentUser } from "../store/selectors";
import { useNavigate } from 'react-router-dom';

export default function Router() {
    const isLoggedIn = false;
    const navigate = useNavigate();
    const user = useSelector(state => getCurrentUser(state))

    useEffect(()=>{
        if (user.id === null)   {
            navigate('/', { replace: true });
        }
    }, [user])
    
    if (user.id === null)
        return (
            <>
                <AuthRouter />
            </>
        );
    else
        return (
            <>
                <MainMenu user={user}/>
                <AppRouter user={user}/>
            </>
        );
}