import { React, Component, useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getAreaCounters, createCounter, deleteCounter, getRoomRanges, deleteRoom, createRoom } from '../../store/actions';

export default function RoomList({data, onCounterClick}) {

    const dispatch = useDispatch();

    const [counters, setCounters] = useState([]);

    const [serialNumber, setSerialNumber] = useState('');
    const [modelNumber, setModelNumber] = useState('');
    const [k, setK] = useState('');

    useEffect( () => { 
        const fetchAreasCounters = async () => {
            const result = await dispatch(getRoomRanges(data.area_uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setCounters(result.payload.data)
        }

        fetchAreasCounters();
    }, []);

    const onDeleteCounter = (room_id) => {
        async function sendDeleteCounter(counter_id) {
            const result = await dispatch(
                deleteRoom(data.area_uuid, 
                    room_id)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setCounters(counters.filter(item => item.id !== room_id))
        }
        sendDeleteCounter(room_id)
    }

    const Counter = ({counter}) => (
        <div className="control" key={counter.id}>
            <div className="tags has-addons">
                <a className="tag is-dark is-link" onClick={()=>{}}>{counter.name + ' (' + counter.ip_range + ')'}</a>
                <a className="tag is-delete" onClick={()=>{onDeleteCounter(counter.id)}}></a>
            </div>
        </div>
    )

    const onAddCounter = () => {
        async function sendData() {
            if(k.length === 0) {
                return Swal.fire({text:'Введите имя', icon: 'error'});
            } else if (serialNumber.length === 0) {
                return Swal.fire({text:'Введите IP first', icon: 'error'});
            } else if (modelNumber.length === 0) {
                return Swal.fire({text:'Введите IP last', icon: 'error'});
            }
            
            const invalidIp = serialNumber
                .split(".")
                .map(ip => Number(ip) >= 0 && Number(ip) <= 255)
                .includes(false);
                if(invalidIp){
                    return Swal.fire({text:'Неверный формат IP first', icon: 'error'});
                }

            const invalidIp2 = modelNumber
                .split(".")
                .map(ip => Number(ip) >= 0 && Number(ip) <= 255)
                .includes(false);
                if(invalidIp2){
                    return Swal.fire({text:'Неверный формат IP last', icon: 'error'});
                }

            const result = await dispatch(
                createRoom(data.area_uuid, 
                    serialNumber, 
                    modelNumber,
                    k)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setCounters([...counters, result.payload.data])
            setSerialNumber('')
            setModelNumber('')
            setK('')
        }
        sendData()
    }

    return (
        <div className='box'>
            <h3 className="title is-3">IP</h3>
            <div className="field is-grouped is-grouped-multiline">
                {
                    counters.map(item => {
                        return (
                            <Counter counter={item} key={item.id}/>
                        )
                    })
                }
            </div>

            <div className="field">
                <label className="label">Имя</label>
                <input className="input" 
                    type="text" 
                    placeholder=""
                    value={k || ''} 
                    onChange={(e) => setK(e.target.value)}/>
            </div>

            <div className="field">
                <label className="label">IP first</label>
                <input className="input" 
                    type="text" 
                    placeholder="127.0.0.1"
                    value={serialNumber || ''} 
                    onChange={(e) => setSerialNumber(e.target.value)}/>
            </div>

            <div className="field">
                <label className="label">IP last</label>
                <input className="input" 
                    type="text" 
                    placeholder="127.0.0.255"
                    value={modelNumber || ''} 
                    onChange={(e) => setModelNumber(e.target.value)}/>
            </div>

            <div className="control">
                <button className="button is-primary" onClick={onAddCounter}>Добавить</button>
            </div>
        </div>
    )
}