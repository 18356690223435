import { React, Component, useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';

import { getDeviceList } from '../../store/actions';
import {
  getIsDevicesLoading
} from '../../store/selectors';
import DeviceList from '../../components/DeviceList/DeviceList';

//https://mhnpd.github.io/react-loader-spinner/docs/components/oval
//https://cssninja.io/blog/simple-loader-bulma
const Devices = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [devices, setDevices] = useState([]);
    const isLoading = useSelector(state => getIsDevicesLoading(state));

    useEffect( () => { 
        async function fetchData() {
            const result = await dispatch(getDeviceList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setDevices(result.payload.data)
        }
        fetchData();
    }, []);

    const test = ['ferwfregdfsgfdksjgfdkgkjfdsgjkfdg', 'ferwfrefrer', 'frewrerfddfdfd', 'fdadfd', 'ferwfrefrer', 'frewrerfddfdfd', 'fdadfd',
                    'ferwfrefrer', 'frewrerfddfdfd', 'fdadfd', 'ferwfrefrer', 'frewrerfddfdfd', 'fdadfd', 'ferwfrefrer', 'frewrerfddfdfd', 'fdadfd', 'ferwfrefrer', 'frewrerfddfdfd', 'fdadfd']

    // https://mhnpd.github.io/react-loader-spinner/docs/components/oval/
    return (
        (isLoading) ? (
            <div className="hero is-fullheight is-flex">
                <div className="container has-text-centered mt-4"> 
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
                    <p className="mt-2">Загрузка</p>
                </div>
            </div>
        ):(
            <DeviceList devices={devices} onClickDevice={()=>{}} />
        )
    )
}

const mapStateToProps = (state) => {
    return {
        isLoading: getIsDevicesLoading(state),
    };
};
  
export default connect(mapStateToProps, { getDeviceList })(Devices);
  