import { React, Component, useState, useEffect, createContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import Pagination from 'bulma-pagination-react';

import { getIsAreasProcessing } from '../../store/selectors';
import { getCounterHistory } from '../../store/actions';
import CounterList from '../Counter/CounterList' 
import CounterModal from '../Counter/CounterModal';

export default function CounterStat({data}) {
    const dispatch = useDispatch();

    const [title, setTitle] = useState(data.name);
    const [uuid] = useState(data.id);
    const [counterHistory, setCounterHistory] = useState([]);
    const [counterHistoryPagesMeta, setCounterHistoryPagesMeta] = useState({offset:0, limit: 24, count:0, pages:0})
    const [counterHistoryCurrentPage, setCounterHistoryCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingProfitableShort, setIsLoadingProfitableShort] = useState(false);
    const [profitableShort, setProfitableShort] = useState([]);

    const [selectedPoolItem, setSelectedPoolItem] = useState(null);

    useEffect( () => { 
        const fetchAreasProfitableHistory = async () => {
            const result = await dispatch(getCounterHistory(
                uuid,
                (counterHistoryCurrentPage - 1) * counterHistoryPagesMeta.limit, 
                counterHistoryPagesMeta.limit)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setCounterHistoryPagesMeta({
                offset: result.payload.data.offset,
                limit: result.payload.data.limit, 
                count:result.payload.data.count,
                pages: result.payload.data.pages
            })
            setCounterHistory(result.payload.data.items)
            setIsLoading(false)
        }

        setIsLoading(true)
        fetchAreasProfitableHistory();
    }, [counterHistoryCurrentPage]);

    return (
        <div className="column is-full">
            {/* <div className="card mt-4 mx-4">
                <header className="card-header">
                    <p className="card-header-title">
                        {title}
                    </p>
                </header>
                <div className="card-content"> */}
                    {(isLoading) ? (
                        <div className="hero is-fullheight is-flex">
                            <div className="container has-text-centered mt-4"> 
                                <Oval
                                    height={80}
                                    width={80}
                                    color="#4fa94d"
                                    wrapperStyle={{}}
                                    wrapperclassName=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#4fa94d"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                    />
                                <p className="mt-2">Загрузка</p>
                            </div>
                        </div>) : (
                            <div>
                                {
                                    (counterHistory.length > 0) ? (
                                        <table className="table is-fullwidth is-striped is-bordered s-narrow is-hoverable">
                                            <thead>
                                                <tr>
                                                <th>Серийный номер</th>
                                                <th>Расход</th>
                                                <th>k</th>
                                                <th>Стоимость электричества</th>
                                                <th>Сумма</th>
                                                <th>Дата</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {(
                                                counterHistory.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className='is-size-7'>{item.serial_number}</td>
                                                            <td className='is-size-7'>{(item.consumption_delta/1000)} кВт/ч</td>
                                                            <td className='is-size-7'>{item.k}</td>
                                                            <td className='is-size-7'>{item.electricity_cost} руб.</td>
                                                            <td className='is-size-7'>{item.amount.toFixed(5)} руб.</td>
                                                            <td className='is-size-7'>{new Date(item.create_time*1000).toUTCString()}</td>
                                                        </tr>
                                                    )
                                                })
                                            )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div></div>
                                    )
                                }
                                <Pagination pages={counterHistoryPagesMeta.pages}
                                    currentPage={counterHistoryCurrentPage}
                                    onChange={(page) => {
                                        setCounterHistoryCurrentPage(page)
                                    }}/>
                            </div>
                        )}
                {/* </div>
            </div> */}
        </div>
    );
}
