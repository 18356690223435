import { React, Component, useState, useEffect, useContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { UserContext } from './Users';
import { Oval } from  'react-loader-spinner'
import { getUserAlerts, setUserAlerts } from '../../store/actions';
import Swal from 'sweetalert2';
import { FaCheck } from "react-icons/fa6";
import { isBossUser } from '../../utils/access';

export default function UserAlerts({data, onFinish}) {

    const dispatch = useDispatch();
    const {selectedUserAlerts,setSelectedUserAlerts} = useContext(UserContext)

    const [areas, setAreas] = useState([])
    const [telegramAcc, setTelegramAcc] = useState('')
    const [selectedAreaTabIndex, setSelectedAreaTabIndex] = useState(0)

    const [alertsLoading, setAlertsLoading] = useState(false)

    const [alerts, setAlerts] = useState([])

    const [onSaveProcess, setOnSaveProcess] = useState(false)
    const [onSaveProcessSuccess, setOnSaveProcessSuccess] = useState(false)
    const [onSaveProcessFail, setOnSaveProcessFail] = useState(false)

    useEffect( () => { 
        if(selectedUserAlerts !== null)   {
            setSelectedAreaTabIndex(0)
            resetSaveProcess()

            const ids = []

            if(isBossUser(selectedUserAlerts.access_type))  {
                data.areas.map(area => {
                    ids.push({
                        id: area.id,
                        name: area.name
                    })
                })
            } else  {
                selectedUserAlerts.areas.map(tt => {
                    ids.push({
                        id: tt.id,
                        name: tt.name,
                    })
                })
            }
            setAreas(ids)
        }
    }, [selectedUserAlerts]);

    useEffect( () => { 
        async function fetchData(area_id, user_id) {
            resetSaveProcess()
            setAlertsLoading(true)

            const result = await dispatch(getUserAlerts(area_id, user_id))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            
            setAlertsLoading(false)

            const alertList = result.payload.data
            setAlerts(alertList)
        }
    
        if(selectedUserAlerts !== null && areas.length > 0)   {
            fetchData(areas[selectedAreaTabIndex].id, selectedUserAlerts.id)
        }
    }, [areas, selectedAreaTabIndex]);

    const resetSaveProcess = () => {
        setOnSaveProcessSuccess(false)
        setOnSaveProcessFail(false)
    }

    const setCheckedState = (index) => {
        resetSaveProcess()

        let newArr = [...alerts]
        newArr[index].is_checked = !newArr[index].is_checked
        setAlerts(newArr)
    }

    const onSave = () => {
        async function setData(area_id, user_id, alerts) {
            setOnSaveProcess(true)
            const result = await dispatch(setUserAlerts(area_id, user_id, alerts))
            setOnSaveProcess(false)
            if(!result.success) {
                setOnSaveProcessFail(true)
                return Swal.fire({text:result.message, icon: 'error'});
            } else  {
                setOnSaveProcessSuccess(true)
            }
        }

        setData(areas[selectedAreaTabIndex].id, selectedUserAlerts.id, alerts)
    }

    const Button = ({className, title}) => {
        return (
            <button className={className} onClick={()=>{onSave()}}>
                {title}
            </button>
        )
    }

    return (
        <div className={selectedUserAlerts !== null ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    setSelectedUserAlerts(null)
                }}>
            </div>
            <div className="modal-content" style={{backgroundColor:'transparent'}}>
                <nav className="panel is-danger">
                    <p className="panel-heading">
                        Alerts {(selectedUserAlerts !== null) ? '('+selectedUserAlerts.username+')' : ''}
                    </p>
                    
                    <p className="panel-tabs" style={{backgroundColor:'white'}}>
                        {
                            areas.map((area, index) => {
                                return (
                                    <a className={(index == selectedAreaTabIndex)?'is-active':''} key={index} onClick={()=>{setSelectedAreaTabIndex(index)}}>
                                        {area.name}
                                    </a>
                                )
                            })
                        }
                    </p>

                    <div style={{backgroundColor:'white'}}>
                        {
                            (alertsLoading) ? (
                                <div className="hero is-flex">
                                    <div className="container has-text-centered mt-4"> 
                                    <Oval
                                        height={80}
                                        width={80}
                                        color="blue"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="white"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                        />
                                    </div>
                                </div>
                            ):(
                                alerts.map((alert, index) => {
                                    return (
                                        <label className="panel-block" key={index}>
                                            <input type="checkbox" checked={alert.is_checked} onChange={()=>{setCheckedState(index)}}/>
                                            {alert.desc}
                                        </label>
                                    )
                                })
                            )
                        }
                        <div className="panel-block">
                            {(alertsLoading) ? (
                                <></>
                            ):(
                                (onSaveProcess) ? (
                                    <Button className="button is-link is-outlined is-fullwidth is-loading" title="Сохранить"/>
                                ) :
                                (onSaveProcessSuccess) ? (
                                    <Button className="button is-link is-outlined is-fullwidth is-success" title={
                                        <>
                                            <span className="icon is-small">
                                                <FaCheck/>
                                            </span>
                                            <span>Сохранено</span>
                                        </>
                                    }/>
                                ) :
                                (onSaveProcessFail) ? (
                                    <Button className="button is-link is-outlined is-fullwidth is-danger" title="Ошибка сохранения"/>
                                ) : (
                                    <Button className="button is-link is-outlined is-fullwidth" title="Сохранить"/>
                                )
                            )}
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}