
import { React, Component, useState, useEffect, useContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Oval } from  'react-loader-spinner'
import { changeBeingModeForDevice, getRoomRanges } from '../../store/actions';
import Swal from 'sweetalert2';
import { changeBeingModeForRoom } from '../../store/actions/device';

export default function RebootDeviceModal({handle_area_uuid, visible_settings, handleModal}) {
    const dispatch = useDispatch();
    const [isRoomRangesLoading, setIsRoomRangesLoading] = useState(false);
    const [roomRangesList, setRoomRangesList] = useState([]);
    const [isRequestSending, setRequestSending] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect( () => { 
        const fetchRoomRanges = async (area_uuid) => {
            const result = await dispatch(getRoomRanges(area_uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setRoomRangesList(result.payload.data)

            setIsRoomRangesLoading(false)
        }

        if(visible_settings.visible)  {
            setSelectedIndex(0)
            setRequestSending(false)
            setRoomRangesList([])

            setIsRoomRangesLoading(true)
            fetchRoomRanges(handle_area_uuid());
        }
    }, [visible_settings]);

    const onReboot = () => {
        async function sendData() {
            const isSleep = (visible_settings.mode == 'sleep')?true:false
            const group_uuid = roomRangesList[selectedIndex]['id']
            const result = await dispatch(changeBeingModeForRoom(handle_area_uuid(), group_uuid, isSleep))
            setRequestSending(false)
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            } else  {
                Swal.fire(
                    'Выполнено!',
                    (visible_settings.mode == 'sleep')?'Устройства были переведены в спящий режим.':'Устройства активированы.',
                    'success'
                )
                handleModal({visible:false, mode:visible_settings.mode})
            }
        }
        setRequestSending(true)
        sendData()
    }

    return (
        <div className={visible_settings.visible ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                   handleModal({visible:false, mode:visible_settings.mode})
                }}>
            </div>
            <div className="modal-content">
                <div className="box is-fullheight has-text-centered">
                    <h3 className="subtitle is-3 has-text-centered">{(visible_settings.mode == 'normal')?'Switch to Normal mode':'Switch to Sleep mode'}</h3>
                    {
                        (isRoomRangesLoading)? (
                            <div className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>
                                <Oval
                                    height={80}
                                    width={80}
                                    color="#4fa94d"
                                    wrapperStyle={{}}
                                    wrapperclassName=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#4fa94d"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                />
                                <p className="mt-2">Загрузка</p>
                            </div>
                        ): ( 
                            (roomRangesList.length > 0) ? (
                                <div>
                                    <div className={(visible_settings.mode == 'normal')?"select is-primary"+((isRequestSending)?" is-loading":"") : "select is-danger"+((isRequestSending)?" is-loading":"")}>
                                        <select onChange={(e) => {
                                            const { name, value } = e.target;
                                            const idx = roomRangesList.findIndex(( room ) => (room['name'] + ' (' + room['ip_range']+')') === value);
                                            if(idx != -1)  {
                                                setSelectedIndex(idx)
                                            }
                                        }}>
                                            {(
                                                roomRangesList.map((room, index) => {
                                                    return (
                                                        <option key={index}>{room['name'] + ' (' + room['ip_range']+')'}</option>
                                                    )
                                                })
                                            )}
                                        </select>
                                    </div>
                                    <button className={
                                        (visible_settings.mode == 'normal') ? "button is-primary ml-3"+((isRequestSending)?" is-loading":"") : "button is-danger ml-3"+((isRequestSending)?" is-loading":"")
                                    } onClick={onReboot}>
                                        Switch
                                    </button>
                                </div>
                            ):(<>
                                <h1 className="subtitle is-5 has-text-centered">Не указан ни один диапазон для данной площадки</h1>
                            </>)
                        )
                    }
                </div>
            </div>
        </div>
    )
}