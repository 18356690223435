import axios from 'axios';
import { API_URL } from '../config/config'
import { successHandler, errorHandler } from './responseHandlers'
import storePersist from "../store/storePersist";
import { getCurrentUser } from '../store/selectors';

import store from '../store/configureStore';
import { userLogout } from '../store/actions';

//https://github.com/PatarapongTaotong/exerack-activity-tracker/blob/c58d3ce038cb3beffd0717ce7fa106826a9df7c5/src/Resources/HttpRequest.js
//https://github.com/nooklab/template-react-ts-basic-front/blob/ad9616d4a77124f5425d4ab42c5df767e56612b6/src/providers/DataProvider.js

// for error handling
//https://github.com/wwtvda/scv.ugm/tree/4a9c73a144fee081219afaff604166c9caee4eed/frontend/src/request

//const BASE_URL = "http://fwrocketmonitor.eu-central-1.elasticbeanstalk.com/api/v1"

const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 80000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Cache-Control': 'no-cache',
        "Access-Control-Allow-Origin": "*"
    },
    validateStatus: function(status) { return status >= 200 && status < 300}
})

axiosInstance.interceptors.request.use((request) => {
    // Здесь можете сделать что-нибудь с перед отправкой запроса
    // const myConfig = config;
    // myConfig.data = config.data ? humps.decamelizeKeys(config.data) : config.data;
    // myConfig.params = config.params ? humps.decamelizeKeys(config.params) : config.params;

    // const {
    //     user: { current_user },
    // } = store.getState();

    // console.log(current_user.access_token)
    // if (current_user != null) {
    //     console.log(current_user.access_token)
    //     //config.headers.Authorization = `Bearer ${authorization_token}`;
    // }

    const state = store.getState();

    if (getCurrentUser(state) != null)  {
        const token_type = getCurrentUser(state).token_type
        const authorization_token = getCurrentUser(state).access_token
        request.headers.Authorization = `${token_type} ${authorization_token}`;
    } else  {
        request.headers.Authorization = '';
    }

    //console.log('!!!!!'+JSON.stringify(state))
    console.log('REQUEST '+request.baseURL+request.url)
    console.log('REQUEST DATA '+JSON.stringify(request.data))

    return request;
}, (error) => {
    // Сделайте что-нибудь с ошибкой запроса
    console.log('REQUEST ERROR: ' + error)
    return Promise.reject(error);
})

axiosInstance.interceptors.response.use((response) => {
    // Любой код состояния, находящийся в диапазоне 2xx, вызывает срабатывание этой функции
    // Здесь можете сделать что-нибудь с ответом
    // const newResponse = response;
    // newResponse.data = humps.camelizeKeys(response.data);
    return response;
}, (error) => {
    console.log('RESPONSE ERROR: '+ error)
    if(axios.isCancel(error))   {
        return Promise.reject('cancel_request')
    }
    // Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции
    // Здесь можете сделать что-то с ошибкой ответа
    if(error.response.status === 401) {
        //storePersist.clear()
        store.dispatch(userLogout())
        //Add Logic to 
        //1. Redirect to login page or 
        //2. Request refresh token
    }

    //console.log(error)

    // const newError = new Error(error);
    // if (error?.response?.data) {
    //     return Promise.reject(error.response.data);
    // }
    // const errorFail = {
    //     code: 503,
    //     message: 'Access denied'
    // }
    //return errorFail;
    return Promise.reject(error.response.data.errors[0]);
})

// export const setAuth = (token) => {
//     if (token) {
//       instance.defaults.headers.common["Authorization"] = token;
//     } else {
//       delete instance.defaults.headers.common["Authorization"];
//     }
//   };

export default class HttpRequest {
    // constructor () {
    //     this.axiosInstance = axios.create({
    //         baseURL: BASE_URL,
    //         timeout: 40000,
    //         headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json; charset=UTF-8',
    //             'Cache-Control': 'no-cache',
    //             "Access-Control-Allow-Origin": "*"
    //         },
    //         validateStatus: function(status) { return status >= 200 && status < 300}
    //     })
        
    //     // this.axiosInstance.interceptors.request.use((request) => {
    //     //     const state = store.getState();
        
    //     //     if (getCurrentUser(state) != null)  {
    //     //         const token_type = getCurrentUser(state).token_type
    //     //         const authorization_token = getCurrentUser(state).access_token
    //     //         request.headers.Authorization = `${token_type} ${authorization_token}`;
    //     //     } else  {
    //     //         request.headers.Authorization = '';
    //     //     }
    //     //     return request;
    //     // }, (error) => {
    //     //     // Сделайте что-нибудь с ошибкой запроса
    //     //     return Promise.reject(error);
    //     // })
        
    //     // this.axiosInstance.interceptors.response.use((response) => {
           
    //     //     return response;
    //     // }, (error) => {
    //     //     // Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции
    //     //     // Здесь можете сделать что-то с ошибкой ответа
    //     //     if(error.response.status === 401) {
    //     //         storePersist.clear()
    //     //         // console.log('!!!!!!')
    //     //         //Add Logic to 
    //     //         //1. Redirect to login page or 
    //     //         //2. Request refresh token
    //     //     }
    //     //     return Promise.reject(error);
    //     // })
    // }

    setHeader (header) {
        this.axiosInstance.defaults.headers.common[header.key] =header.value;
        this.axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
    }

    accessToken()   {
        
    }

    async get (endpoint, data, config) {
        const params = {
            params: data
        }

        const newParams = Object.assign(params, config);
        console.log(newParams)
        try {
            return await axiosInstance.get(endpoint, newParams).then(response => {
                return successHandler(response)
            })
            .catch(error => {
                return errorHandler(error)
            })
            .finally(function () {
                console.log('finally...')
            });
        } catch(err)    {
            console.log('There was a problem or request was cancelled.')
        }
    }

    async post (endpoint, data, config) {
        return await axiosInstance.post(endpoint, data, config).then(response => {
            return successHandler(response)
        })
        .catch(error => {
            return errorHandler(error)
        })
        .finally(function () {
            console.log('finally...')
        });
    }

    async put (endpoint, data, config) {
        return await axiosInstance.put(endpoint, data, config).then(response => {
            return successHandler(response)
        })
        .catch(error => {
            return errorHandler(error)
        })
        .finally(function () {
            console.log('finally...')
        });
    }

    async delete (endpoint, data, config) {
        const params = {
            params: data
        }
        const newParams = Object.assign(params, config);

        return await axiosInstance.delete(endpoint, params).then(response => {
            return successHandler(response)
        })
        .catch(error => {
            return errorHandler(error)
        })
        .finally(function () {
            console.log('finally...')
        });
    }

    patch (endpoint, data, config) {
        return this.axiosInstance.patch(endpoint, data, config);
    }

    remove (endpoint, data, config) {
        const params = { data };
        const newParams = Object.assign(params, config);
        return this.axiosInstance.delete(endpoint, newParams);
    }

    // delete (endpoint, id, config) {
    //     const params = {
    //         params: { id }
    //     }

    //     const newParams = Object.assign(params, config);
    //     return this.axiosInstance.delete(endpoint, newParams);
    // }
}

function dispatch(arg0) {
    throw new Error('Function not implemented.');
}
