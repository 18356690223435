import {
    REQUEST_FAILURE,
    REQUEST_START,
    REQUEST_SUCCESS,
    RESET_STATE,
    AUTH_LOGOUT_SUCCESS
} from '../actions/actionTypes';
import storePersist from "../storePersist";

export const initialState = {
    isLoading: false,
    errorMessage: null,
    
    currentUser: {
        id: null,
        username: null,
        access_type: null,
        access_token: null,
        token_type: null,
    }
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST_START:
            return {
                ...state,
                isLoading: true,
                errorMessage: null,
            };
        case REQUEST_SUCCESS:
            const data = {
                ...state,
                isLoading: false,
                errorMessage: null,
                currentUser: {
                    id: payload.id,
                    username: payload.username,
                    access_type: payload.access_type,
                    access_token: payload.access_token,
                    token_type: payload.token_type,
                }
            };
            storePersist.set("user", data);
            return data
        case REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorMessage: null,
            };
        case AUTH_LOGOUT_SUCCESS:
            console.log('!!!!')
            storePersist.remove('user')
            return initialState;
        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
};