import { React, Component, useState, useEffect, useContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { UserContext } from './Users';
import { Oval } from  'react-loader-spinner'
import { createUser } from '../../store/actions';
import Swal from 'sweetalert2';

export default function AddUserModal({data, onFinish}) {

    const dispatch = useDispatch();
    const {addUser,setAddUser} = useContext(UserContext)

    const [areas, setAreas] = useState([])
    const [selectedOption, setSelectedOption] = useState("OWNER")
    const [username, setUsername] = useState([])
    const [telegramAcc, setTelegramAcc] = useState(null)

    useEffect( () => { 
        if(areas !== null)   {
            const ids = []
            data.areas.map(area => {
                ids.push({
                    id: area.id,
                    name: area.name,
                    checked: false
                })
            })
            setAreas(ids)
        }
    }, []);

    const updateCheckStatus = (index) => {
        let newArr = areas.map((area, currentIndex) => {
            return currentIndex == index
                ? { ...area, checked: !area.checked}
                : area
        })
        setAreas(newArr)
    }

    const onAdd = () => {
        async function onSaveUser() {
            if(username.length < 5) {
                return Swal.fire({text:'Введите логин пользователя', icon: 'error'});
            }
    
            const arr = []
            areas.map((area, index) => {
                if(area.checked)    {
                    arr.push(area.id) 
                }
            })
    
            const result = await dispatch(
                createUser(username, selectedOption, arr, telegramAcc)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
    
            setAddUser(null)
            onFinish()
        }

        onSaveUser()
    }

    const onValueChange = (rule) => {
        setSelectedOption(rule)
    }

    return (
        <div className={addUser !== null ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    setAddUser(null)
                }}>
            </div>
            <div className="modal-content">
                {
                    (addUser !== null && areas !== null) ? (
                        <div className="box is-fullheight">
                            <div className="field">
                                <label className="label">Логин</label>
                                <div class="control">
                                    <input class="input" 
                                        type="text" 
                                        placeholder="минимум 5 символов" 
                                        value={username || ''} 
                                        onChange={(e) => setUsername(e.target.value)}/>
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Telegram аккаунт</label>
                                <div class="control">
                                    <input class="input" 
                                        type="text" 
                                        placeholder="Без @" 
                                        value={telegramAcc || ''} 
                                        onChange={(e) => setTelegramAcc(e.target.value)}/>
                                </div>
                            </div>
                            <p className='is-size-5 mt-3'>Роль:</p>
                            <div className="control mt-2 is-flex is-justify-content-space-around">
                                <label class="radio">
                                    <input type="radio" 
                                        name="role" 
                                        checked={selectedOption === "OWNER"}
                                        onChange={()=>{onValueChange("OWNER")}}/>
                                    Владелец
                                </label>
                                <label class="radio">
                                    <input type="radio" 
                                        name="role"
                                        checked={selectedOption === "ADMIN"}
                                        onChange={()=>{onValueChange("ADMIN")}}/>
                                    Администратор
                                </label>
                                <label class="radio">
                                    <input type="radio" 
                                        name="role" 
                                        checked={selectedOption === "TECH"}
                                        onChange={()=>{onValueChange("TECH")}}/>
                                    Тех. специалист
                                </label>
                                <label class="radio">
                                    <input type="radio" 
                                        name="role" 
                                        checked={selectedOption === "FINANCE"}
                                        onChange={()=>{onValueChange("FINANCE")}}/>
                                    Бухгалтер
                                </label>
                            </div>
                            <p className='is-size-5 mt-3'>Доступные зоны:</p>
                            <div className="control mt-2 is-flex is-justify-content-space-around">
                                {(
                                    areas.map((area, index) => {
                                        return (
                                            <label class="checkbox" key={area.id}>
                                                <input type="checkbox" checked={area.checked} onChange={()=>{updateCheckStatus(index)}}/>
                                                {area.name}
                                            </label>
                                        )
                                    })
                                )}
                            </div>
                            <div className="buttons is-flex mt-5 is-centered">
                                <button class="button is-success" onClick={onAdd}>Добавить</button>
                            </div>
                        </div>
                    ) : (null)
                }
            </div>
        </div>
    )
}