import { React, Component, useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';

import { getAreasList } from '../../store/actions';
import {
  getIsAreasLoading
} from '../../store/selectors';
import CounterStat from '../../components/CounterStat/CounterStat';
import PhaseAreas from './PhaseAreas';
import PhaseStats from './PhaseStats';

export default function PhaseScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const counter_switch_list = [
        {title:'Показания', is_active: true},
        {title:'Статистика', is_active: false}
    ]

    const [areas, setAreas] = useState([]);
    const isLoading = useSelector(state => getIsAreasLoading(state));
    const [stageList, setStageList] = useState(counter_switch_list);
    const [stageIndex, setStageIndex] = useState(0);

    useEffect( () => { 
        async function fetchData() {
            const result = await dispatch(getAreasList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setAreas(result.payload.data)
        }
        fetchData();
    }, []);

    const onSwitchStage = async (index) => {
        setStageList([
            {title:'Показания', is_active: (index == 0)},
            {title:'Статистика', is_active: (index == 1)}
        ])
        setStageIndex(index)
    }

    return (
        (isLoading) ? (
            <div className="hero is-fullheight is-flex">
                <div className="container has-text-centered mt-4"> 
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                    />
                        <p className="mt-2">Загрузка</p>
                </div>
            </div>
        ):(
            <div className="columns is-multiline is-centered">
                {
                    (areas.length > 0)?(
                        (<nav className="breadcrumb has-bullet-separator is-centered mt-6" aria-label="breadcrumbs">
                            <ul>
                            {
                                stageList.map((item, index) => {
                                    return (
                                        <li key={index} className={(item.is_active) ? "is-active": ""}>
                                            <a href="#" onClick={()=>{onSwitchStage(index)}}>{item.title}</a>
                                        </li>
                                    )
                                })
                            }
                            </ul>
                        </nav>)
                    ):(null)
                }

                {
                    (stageIndex == 0) ? (
                        <PhaseAreas areas={areas}/>
                    ) : (stageIndex == 1) ? (
                        <PhaseStats areas={areas}/>
                    ) : (<></>)
                }
            </div>
        )
    );
}
  