import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Stack,
  Box,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo/DemoContainer';
import moment from 'moment';
import { addMaintenance, getMonitorList } from '../../store/actions/areas';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';

import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function LoadingButton({ loading, children, ...props }) {
  return (
    <Button {...props} disabled={loading}>
      {loading && <CircularProgress size={24} style={{ marginRight: 8 }} />}
      {children}
    </Button>
  );
}

const MaintenanceAddDialog = React.memo(({ open, onClose }) => {
    const dispatch = useDispatch();
    
    const [isMonitorLoading, setIsMonitorLoading] = useState(false)
    const [monitors, setMonitors] = useState([])
    const [selectedMonitorName, setSelectedMonitorName] = useState([]);

    const [title, setTitle] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [description, setDescription] = useState('');
    const [errorTitle, setErrorTitle] = useState('');
    const [error, setError] = useState('');
    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateTo, setErrorDateTo] = useState(false);
    const [errorSelectMonitors, setErrorSelectMonitors] = useState('');

    const [isMaintenanceSave, setIsMaintenanceSave] = useState(false)

    const addMaintenanceRequest = async (area_id, title, description, startDate, endDate, monitors) => {
        setIsMaintenanceSave(true)
        const result = await dispatch(addMaintenance(area_id, title, description, startDate, endDate, monitors))
        if(!result.success) {
            setErrorSelectMonitors(result.message)
        }

        setIsMaintenanceSave(false)
        return result.success
    }

    const handleSave = async () => {
        console.log(startDate)
        if(title.length == 0)   {
            setErrorTitle('Название является обязательным полем');
            return;
        } else  {
            setErrorTitle('');
        }

        if (description.length > 50) {
            setError('Описание не должно превышать 50 символов');
            return;
        } else if(description.length == 0) {
            setError('Описание является обязательным полем');
            return;
        } else  {
            setError('');
        }

        if(startDate == null)   {
            console.log(startDate)
            setErrorSelectMonitors('Укажите дату начала')
            return
        } else  {
            setErrorSelectMonitors('')
        }

        if(endDate == null)   {
            setErrorSelectMonitors('Укажите дату окончания')
            return
        } else  {
            setErrorSelectMonitors('')
        }

        if(selectedMonitorName.length == 0) {
            setErrorSelectMonitors('Необхоимо привязать мониторы')
            return
        } else  {
            setErrorSelectMonitors('')
        }

        let monitor_ids = []
        selectedMonitorName.map((sMonitor) => {
            const index = monitors.findIndex(monitor => monitor.name === sMonitor);
            if(index > -1)  {
                monitor_ids.push(monitors[index].id)
            }
        })
        
        const mData = {
            title: title,
            description: description,
            startDate: (moment(startDate).add(3, 'hours').valueOf()/1000),
            endDate: (moment(endDate).add(3, 'hours').valueOf()/1000),
            monitors: monitor_ids
        }

        const ret = await addMaintenanceRequest('', mData.title, mData.description, mData.startDate, mData.endDate, mData.monitors)
        if(!ret)
            return
        
        onClose(true);
    };

    const handleClose = () => {
        onClose(false);
    };

    useEffect(() => {
        setSelectedMonitorName([])
        setTitle('')
        setDescription('')
        setStartDate(null)
        setEndDate(null)
        setErrorDateFrom(false)
        setErrorDateTo(false)
        setError('')
        setErrorTitle('')
        setErrorSelectMonitors('')
        setIsMaintenanceSave(false)
        // setStartDate(moment.utc(open.startTime * 1000))
        // setEndDate(moment.utc(open.endTime * 1000))
    }, [open])

    useEffect( () => { 
        const fetchData = async () => {
            setIsMonitorLoading(true)
            const result = await dispatch(getMonitorList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setMonitors(result.payload.data)
            setIsMonitorLoading(false)
        }

        if(open.open)   {
            fetchData()
        }
    }, [open]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedMonitorName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Dialog open={open.open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Создать событие тех. работ</DialogTitle>
            <DialogContent>
                <TextField
                    label="Название"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    fullWidth
                    margin="normal"
                    rows={1}
                    error={!!errorTitle}
                    helperText={errorTitle}
                />
                <TextField
                    label="Описание"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={2}
                    error={!!error}
                    helperText={error}
                />
                <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                    <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%'}}>
                            <Box sx={{ flex: 1 }}>
                                <DateTimePicker
                                    label="Дата начала"
                                    format='DD.MM.YYYY HH:mm' 
                                    timeSteps={{ minutes: 1 }}
                                    value={startDate}
                                    onChange={setStartDate}
                                    sx={{ width: '100%' }}
                                />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <DateTimePicker
                                    label="Дата окончания"
                                    format='DD.MM.YYYY HH:mm' 
                                    timeSteps={{ minutes: 1 }}
                                    value={endDate}
                                    onChange={setEndDate}
                                    sx={{ width: '100%' }}
                                />
                            </Box>
                        </Stack>
                    </DemoContainer>
                </LocalizationProvider>
                <FormControl sx={{ marginTop: '20px' }} error={errorSelectMonitors.length > 0} fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">Мониторы</InputLabel>
                    <Select
                        sx={{ width: '100%' }}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedMonitorName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Мониторы" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {
                            (isMonitorLoading) ? (
                                <MenuItem value="">
                                    <Box display="flex" alignItems="center">
                                        <CircularProgress size={24} />
                                        <span style={{ marginLeft: 8 }}>Загрузка...</span>
                                    </Box>
                                </MenuItem>
                            ) : (
                                monitors.map((monitor) => (
                                    <MenuItem key={monitor.id} value={monitor.name}>
                                        <Checkbox checked={selectedMonitorName.indexOf(monitor.name) > -1} />
                                        <ListItemText primary={monitor.name} />
                                    </MenuItem>
                                ))
                            )
                        }
                    </Select>
                    <FormHelperText>{errorSelectMonitors}</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Отмена
                </Button>
                <LoadingButton onClick={handleSave} color="primary" loading={isMaintenanceSave} variant="contained" startIcon={<SaveIcon />}>
                    Добавить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
});

export default MaintenanceAddDialog;
