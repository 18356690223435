import { React, Component, useState, useEffect, createContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import Pagination from 'bulma-pagination-react';
import moment from 'moment';

import { getIsAreasProcessing } from '../../store/selectors';
import { getPhaseExtendedConsumptionRange } from '../../store/actions';


export default function PhaseAreas({areas}) {
    const dispatch = useDispatch();

    const date_switch_list = [
        {title:'Вчера', is_active: true},
        {title:'Неделя', is_active: false},
        {title:'Месяц', is_active: false}
    ]

    const [counterConsumptions, setCounterConsumptions] = useState([]);
    const [dateList, setDateList] = useState(date_switch_list);
    const [dateIndex, setDateIndex] = useState(0);
    const [dateRange, setDateRange] = useState({start:'', end:''});

    const fetchCounterConsumptionRange = async (arr, index, id, startDate, endDate) => {
        let newArr = [...arr]
        newArr[index].isLoading = true
        setCounterConsumptions(newArr)

        const result = await dispatch(getPhaseExtendedConsumptionRange(id, startDate, endDate))
        if(!result.success) {
            //return Swal.fire({text:result.message, icon: 'error'});
            newArr = [...arr]
            newArr[index].isLoading = false
            newArr[index].status = false
            newArr[index].data = []
            setCounterConsumptions(newArr)
        } else  {
            newArr = [...arr]
            newArr[index].isLoading = false
            newArr[index].status = true
            newArr[index].data = result.payload.data
            setCounterConsumptions(newArr)
        }

        // const u1 = result.payload.data['voltage_0'] ?? 0
        // const u2 = result.payload.data['voltage_1'] ?? 0
        // const u3 = result.payload.data['voltage_2'] ?? 0

        // const a1 = result.payload.data['amperage_0'] ?? 0
        // const a2 = result.payload.data['amperage_1'] ?? 0
        // const a3 = result.payload.data['amperage_2'] ?? 0

        // const p = result.payload.data['p'] ?? 0

        // newArr = [...arr]
        // newArr[index].u1 = u1
        // newArr[index].u2 = u2
        // newArr[index].u3 = u3
        // newArr[index].a1 = a1
        // newArr[index].a2 = a2
        // newArr[index].a3 = a3
        // newArr[index].p = p
        // newArr[index].isLoading = false
        // setCounterConsumptions(newArr)
    }

    useEffect( () => {
        let area_consumptions = Array() 

        areas.forEach((area, index) => {
            area_consumptions.push({
                area_id: area.id,
                area_name: area.name,
                has_warning: false,
                isLoading: false,
                status: false,
                data: []
            })
        })

        setCounterConsumptions(area_consumptions)
        setDateRangeByIndex(0)
    }, [areas]);

    useEffect( () => {
        counterConsumptions.forEach((area, index) => {
            fetchCounterConsumptionRange(counterConsumptions, index, area['area_id'], dateRange.start, dateRange.end)
        })
    }, [dateRange]);

    const onSwitchStage = async (index) => {
        setDateList([
            {title:'Вчера', is_active: (index == 0)},
            {title:'Неделя', is_active: (index == 1)},
            {title:'Месяц', is_active: (index == 2)}
        ])
        setDateIndex(index)
        setDateRangeByIndex(index)
    }

    const setDateRangeByIndex = async (index) => {
        var now = new Date();

        let startDate;
        let endDate;
        switch(index){
            case 0:
                // весь день вчера
                startDate = moment(now).subtract(1, 'days')
                endDate = moment(now)
                break
            case 1:
                // 7 дней до всего вчера
                startDate = moment(now).subtract(7, 'days')
                endDate = moment(now).subtract(1, 'days')
                break
            case 2:
                //30 дней до всего вчера
                startDate = moment(now).subtract(30, 'days')
                endDate = moment(now).subtract(1, 'days')
                break
            default:
                startDate = moment(now).subtract(1, 'days')
                endDate = moment(now).subtract(1, 'days')
        }

        
        var startDateString = startDate.format('YYYY-MM-DDT00:00:00');
        var endDateString = endDate.format('YYYY-MM-DDT23:59:59');
        setDateRange({start:startDateString, end:endDateString})
    }

    return (
        <>
        <div className="column is-full">
            {/* <div className="columns is-multiline is-centered"> */}
                {/* <div className='column is-full'>
                    <div className="card mt-4 mx-4 is-vcentered mt-1" style={{height:'40px'}}>
                        <nav className="breadcrumb has-bullet-separator is-centered pt-2" aria-label="breadcrumbs">
                            <ul>
                                {
                                    dateList.map((item, index) => {
                                        return (
                                            <li key={index} className={(item.is_active) ? "is-active": ""}>
                                                <a href="#" onClick={()=>{onSwitchStage(index)}}>{item.title}</a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                </div> */}
                {/* {
                    counterConsumptions.map((item, index) => {
                        return (
                            <div key={index} className="column is-one-fifth">
                                <div className="box" >
                                    <p className={(item['has_warning'])?"title is-5 has-text-danger":"title is-5"}>
                                        {
                                            (item['has_warning'])?'⚠ '+item['area_name']:item['area_name']
                                        }
                                    </p>
                                    {
                                        (item['isLoading'] ? (
                                            <div className='hero is-flex is-centered'>
                                                <div className="container has-text-centered mt-4"> 
                                                    <Oval
                                                        height={40}
                                                        width={40}
                                                        color="#4fa94d"
                                                        wrapperStyle={{}}
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#4fa94d"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}/>
                                                </div>
                                            </div>
                                        ): (
                                            <div>
                                                <p className="subtitle is-6 mt-3">Мощность: {(item['p'] / 10000).toFixed(2)} кВт/ч</p>
                                                <p className="subtitle is-6 mt-3">Напряжение:</p>
                                                <div className="content">
                                                    <ol type="1">
                                                        <li>{(item.u1 / 100).toFixed(2)} В</li>
                                                        <li>{(item.u2 / 100).toFixed(2)} В</li>
                                                        <li>{(item.u3 / 100).toFixed(2)} В</li>
                                                    </ol>
                                                </div>
                                                <p className="subtitle is-6 mt-3">Ток:</p>
                                                <div className="content">
                                                    <ol type="1">
                                                        <li>{(item.a1 / 100).toFixed(2)} А</li>
                                                        <li>{(item.a2 / 100).toFixed(2)} А</li>
                                                        <li>{(item.a3 / 100).toFixed(2)} А</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    })
                } */}

                {
                    counterConsumptions.map((item, index) => {
                        return (
                            <div key={index} className="card">
                                <header className="card-header">
                                    <p className="card-header-title">
                                        {item.area_name}
                                    </p>
                                </header>
                                <div className="card-content">
                                    {
                                        (item['isLoading'] ? (
                                            <div className='hero is-flex is-centered'>
                                                <div className="container has-text-centered mt-4"> 
                                                    <Oval
                                                        height={40}
                                                        width={40}
                                                        color="#4fa94d"
                                                        wrapperStyle={{}}
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#4fa94d"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}/>
                                                </div>
                                            </div>
                                        ): (
                                            <div className="table-container">
                                                <table className="table is-striped is-narrow is-hoverable is-fullwidth">
                                                    <thead>
                                                            <tr>
                                                                <th>Счетчик</th>
                                                                <th>I1 (A)</th>
                                                                <th>I2 (A)</th>
                                                                <th>I3 (A)</th>
                                                                <th>U1 (В)</th>
                                                                <th>U2 (В)</th>
                                                                <th>U3 (В)</th>
                                                                <th>P1 (кВт)</th>
                                                                <th>P2 (кВт)</th>
                                                                <th>P3 (кВт)</th>
                                                                <th>I (A)</th>
                                                                <th>P (кВт)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                item.data.map((counter, cindex) => {
                                                                    return (
                                                                        <tr key={cindex}>
                                                                            <td>{counter['sn']}</td>
                                                                            <td>{counter['amperage.0'] / 1000}</td>
                                                                            <td>{counter['amperage.1'] / 1000}</td>
                                                                            <td>{counter['amperage.2'] / 1000}</td>
                                                                            <td>{counter['voltage.0'] / 100}</td>
                                                                            <td>{counter['voltage.1'] / 100}</td>
                                                                            <td>{counter['voltage.2'] / 100}</td>
                                                                            <td>{counter['power.0'] / 100000000}</td>
                                                                            <td>{counter['power.1'] / 100000000}</td>
                                                                            <td>{counter['power.2'] / 100000000}</td>
                                                                            <td>{counter['amperage'] / 1000}</td>
                                                                            <td>{counter['power'] / 100000000}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                </table>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            {/* </div> */}
        </div>
        </>
    );
}

