import { React, Component, useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

import { APP_VERSION } from '../../config/config'  
import { userLogin, test } from '../../store/actions';
import {
  getIsAuthLoading
} from '../../store/selectors';

const Login = (props) => {

    // const count = useSelector((state) => state.counter)
    // const user = useSelector((state) => state.user)

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const isLoading = useSelector(state => getIsAuthLoading(state));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const onInputChange = (e) => {
  //   const inputName = e.currentTarget.name;
  //   const inputValue = e.currentTarget.value;
  //   this.setState((prevState) => ({
  //       user: {
  //         ...prevState.user,
  //         [inputName]: inputValue,
  //       },
  //   }));
  // };

//   useEffect(() => {
//     dispatch(dispatch_reset_state);
//   }, [])

  const handleClick = async () => {
    if (username.length === 0) {
      return Swal.fire({text:'Пожалуйста введите логин', icon: 'error'});
    } else if (password.length === 0) {
        return Swal.fire({text:'Пожалуйста введите пароль', icon: 'error'});
    }

    const result = await dispatch(userLogin(username, password));
    if(!result.success) {
      return Swal.fire({text:result.message, icon: 'error'});
    }
  }

  return (
    <div className="hero is-fullheight is-info">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="column is-4 is-offset-4">
            <h3 className="title has-text-white is-2 is-size-4-mobile is-size-4-tablet is-size-4-desktop">FWRocketMonitor</h3>
            <hr className="login-hr"/>
            {/* <p class="subtitle has-text-white">Please login to see our cool stuff!</p> */}

            <div className="box">
                <div className="box">
                    <figure className="image is-128x128 is-inline-block">
                    {
                    <img src={process.env.PUBLIC_URL + '/rocket_logo.jpg'} alt=""></img>
                    }
                    </figure>

                    <form>
                    <div className="field">
                        <div className="control">
                        <input className="input is-medium" 
                                name="username"
                                type="email" 
                                placeholder="Login" 
                                value={username || ''} 
                                onChange={(e) => setUsername(e.target.value)}/>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                        <input className="input is-medium" 
                                name="password"
                                type="password" 
                                placeholder="Password"
                                value={password || ''} 
                                onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                    </div>
                    </form>

                    <button className={(isLoading)?("button is-block is-primary is-medium is-fullwidth mt-5 is-loading"):("button is-block is-info is-medium is-fullwidth mt-5")} 
                    onClick={handleClick}>Login</button>
                </div>

                <div className="title has-text-black is-7">v. {APP_VERSION}</div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
      isLoading: getIsAuthLoading(state),
  };
};

//export default Login;

export default connect(mapStateToProps, { userLogin })(Login);
