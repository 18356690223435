import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/configureStore';
import Router from './router';

function RocketApp() {

  return (
    <BrowserRouter basename="/">
      <Provider store={store}>
        <Router />
      </Provider>
    </BrowserRouter>
  );
}

export default RocketApp;
