import { React, Component, useState, useEffect, useRef, createContext, useReducer } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';

import { getAreasList } from '../../store/actions';
import {
  getIsAreasLoading
} from '../../store/selectors';
import CounterStat from '../../components/CounterStat/CounterStat';
import CounterEnergy from './CounterEnergy';
import CounterStats from './CounterStats';
import { IoMdArrowDropdown } from 'react-icons/io';
import CounterRecalculateModal from '../../components/Counter/CounterRecalculateModal';
import { useForceUpdate } from 'framer-motion';
import AddConsumption from './AddConsumptions';

export const CounterContext = createContext('');

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

export default function Counters() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const counter_switch_list = [
        {title:'Показания', is_active: true},
        {title:'Энергия', is_active: false},
        {title:'Статистика', is_active: false},
        {title:'Доп. расходы', is_active: false}
    ]

    const [areas, setAreas] = useState([]);
    const isLoading = useSelector(state => getIsAreasLoading(state));
    const [stageList, setStageList] = useState(counter_switch_list);
    const [stageIndex, setStageIndex] = useState(0);

    const [selectedAreaIndex, setSelectedAreaIndex] = useState(0);
    const [areaDropDownShow, setAreaDropDownShow] = useState(false);

    const [counterRecalculateVisible, setCounterRecalculateVisible] = useState(false);
    const prevCounterRecalculateVisible = usePrevious(counterRecalculateVisible)

    const idBtnRef = useRef()

    const [value, setValue] = useState(0);

    useEffect( () => { 
        async function fetchData() {
            const result = await dispatch(getAreasList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setAreas(result.payload.data)
        }
        fetchData();
    }, [value]);

    useEffect(() => {
        const closeDropdown = e => {
            if(idBtnRef.current == null) return;

            if(idBtnRef.current.contains(e.target))   {
            } else  {
                setAreaDropDownShow(false)
            }
        }
        document.body.addEventListener('click', closeDropdown)

        return () => document.body.removeEventListener('click', closeDropdown)
    })

    useEffect( () => { 
        if(prevCounterRecalculateVisible && !counterRecalculateVisible)    {
            console.log('Closed')
            setValue(value => value + 1);
        }
    }, [counterRecalculateVisible]);

    const onSwitchStage = async (index) => {
        setStageList([
            {title:'Показания', is_active: (index == 0)},
            {title:'Энергия', is_active: (index == 1)},
            {title:'Статистика', is_active: (index == 2)},
            {title:'Доп. расходы', is_active: (index == 3)}
        ])
        setStageIndex(index)
    }

    return (
        (isLoading) ? (
            <div className="hero is-fullheight is-flex">
                <div className="container has-text-centered mt-4"> 
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                    />
                        <p className="mt-2">Загрузка</p>
                </div>
            </div>
        ):(
            <div className="columns is-multiline is-centered">
                {
                    (areas.length > 0)?(
                        (<nav className="breadcrumb has-bullet-separator is-centered mt-6" aria-label="breadcrumbs">
                            <ul>
                            {
                                stageList.map((item, index) => {
                                    return (
                                        <li key={index} className={(item.is_active) ? "is-active": ""}>
                                            <a href="#" onClick={()=>{onSwitchStage(index)}}>{item.title}</a>
                                        </li>
                                    )
                                })
                            }
                            </ul>
                        </nav>)
                    ):(null)
                }

                {
                    (stageIndex == 0) ? (
                        <div className="column is-full">
                            <div className="card mx-4">
                                <header className="card-header is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                                    <div>
                                        {(areas.length > 0)?(
                                            <nav className="breadcrumb has-bullet-separator is-centered mt-3 mb-3" aria-label="breadcrumbs">
                                                <ul>
                                                    {
                                                        areas.map((item, index) => {
                                                            return (
                                                                <li key={index} className={(selectedAreaIndex == index) ? "is-active": ""}>
                                                                    <a href="#" onClick={()=>{setSelectedAreaIndex(index)}}>{item.name}</a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </nav>):(
                                                null
                                            )
                                        }
                                    </div>
                                    {/* <div className={(areaDropDownShow)?"dropdown is-active":"dropdown"}>
                                        <div className="dropdown-trigger">
                                            <button ref={idBtnRef} className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={()=>{setAreaDropDownShow(!areaDropDownShow)}}>
                                                <span>
                                                    {
                                                        (areas.length > 0) ? (areas[selectedAreaIndex]['name']) : (null)
                                                    }
                                                </span>
                                                <span className="icon is-small">
                                                    {
                                                        <IoMdArrowDropdown />
                                                    }
                                                </span>
                                            </button>
                                        </div>
                                        <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                            <div className="dropdown-content">
                                                {
                                                    areas.map((item, index) => {
                                                        return (
                                                            <a key={index} href="#"
                                                                className={(index == selectedAreaIndex)?"dropdown-item is-active":"dropdown-item"}
                                                                onClick={()=>{
                                                                    setSelectedAreaIndex(index)
                                                                    setAreaDropDownShow(false)
                                                                }}
                                                            >
                                                                {item['name']}
                                                            </a>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div> */}
                                </header>
                                <div className="card-content">
                                    <div>
                                        <button className="button ml-3" onClick={()=>{
                                            setCounterRecalculateVisible(true)
                                        }}>Пересчитать счетчик</button>
                                    </div>
                                    {
                                        (areas.length > 0) ? (
                                            <CounterStat data={areas[selectedAreaIndex]} key={selectedAreaIndex}/>
                                        ) : (null)
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (stageIndex == 1) ? (
                        <CounterEnergy areas={areas}/>
                    ) : (stageIndex == 2) ? (
                        <CounterStats areas={areas}/>
                    ) : (stageIndex == 3) ? (
                        <AddConsumption areas={areas}/>
                    ) : (<></>)
                }
                <CounterContext.Provider value={{counterRecalculateVisible, setCounterRecalculateVisible}}>
                    <CounterRecalculateModal data={{
                        areas: areas,
                        selectedAreaIndex: selectedAreaIndex
                    }}/>
                </CounterContext.Provider>
                {/* <div className={recalculateCounterModalVisible ? "modal is-active" : "modal"}>
                    <div className="modal-background" onClick={()=>{
                            setRecalculateCounterModalVisible(false)
                        }}>
                    </div>
                    <div className="modal-content">
                        <div className="box">
                            
                        </div>
                    </div>
                </div> */}
            </div>
        )
    );
}
  