import { React, Component, useState, useEffect, useContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';
import { encode } from "base-64";

import { DeviceContext } from './DevicesDashboard';
import { getDeviceHashrateHistoryByMac } from '../../store/actions';
import { Line } from 'react-chartjs-2';

export default function DeviceAsicPanel({data, onFinish}) {

    const dispatch = useDispatch();
    const {selectedDeviceForAsicPanel, setSelectedDeviceForAsicPanel} = useContext(DeviceContext)

    useEffect( () => {
        async function fetchData() {
            console.log(encode('root' + ":" + 'root'))
            const response = await fetch('http://10.10.11.9/ip/192.168.1.131/index.html', {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Basic ' + encode('root' + ":" + 'root'),
                    'Content-Type': 'application/json'
                })
            });
            const posts = await response.text();
            console.log(response)
        } 
        if(selectedDeviceForAsicPanel !== null)   {
            fetchData()
        }
    }, [selectedDeviceForAsicPanel]);

    return (
        <div className={(selectedDeviceForAsicPanel !== null) ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    setSelectedDeviceForAsicPanel(null)
                }}>
            </div>
            <div className="modal-content" style={{backgroundColor:'white', height:'60%', width:'80%'}}>
                <div style={{backgroundColor:'white', width:'100%', height:'100%'}}>
                
                </div>
            </div>
        </div>
    )
}