import { React, Component, useState, useEffect, useContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { UserContext } from './Users';
import { Oval } from  'react-loader-spinner'
import { getAlertsUserList, setAlertsDataList } from '../../store/actions';
import Swal from 'sweetalert2';
import { FaCheck } from "react-icons/fa6";
import { isBossUser } from '../../utils/access';
import { Tree } from 'react-arborist';
import { GrGroup } from "react-icons/gr";
import { LiaTelegramPlane } from "react-icons/lia";

export default function AlertPriority({data, onFinish}) {

    const dispatch = useDispatch();
    const {priority,setPriority} = useContext(UserContext)

    const [areas, setAreas] = useState([])
    const [telegramAcc, setTelegramAcc] = useState('')
    const [selectedAreaTabIndex, setSelectedAreaTabIndex] = useState(0)

    const [alertsLoading, setAlertsLoading] = useState(false)

    const [alerts, setAlerts] = useState([])

    const [onSaveProcess, setOnSaveProcess] = useState(false)
    const [onSaveProcessSuccess, setOnSaveProcessSuccess] = useState(false)
    const [onSaveProcessFail, setOnSaveProcessFail] = useState(false)

    useEffect( () => { 
        if(priority !== null)   {
            setSelectedAreaTabIndex(0)
            resetSaveProcess()

            const ids = []

            data.areas.map(area => {
                ids.push({
                    id: area.id,
                    name: area.name
                })
            })
            setAreas(ids)
        }
    }, [priority]);

    useEffect( () => { 
        async function fetchData(area_id) {
            resetSaveProcess()
            setAlertsLoading(true)

            const result = await dispatch(getAlertsUserList(area_id))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            
            setAlertsLoading(false)

            const alertList = result.payload.data

            var data = []
            var idIndex = 0

            alertList.map((alertObject, index) => {
                const alertDict = alertObject['alert']
                const userList = alertObject['users']

                const childIdPrefix = alertDict['name'][0] + idIndex.toString()

                var childrens = []
                if(userList.length > 0) {
                    userList.map((userDict, userIndex) => {
                        childrens.push({id: childIdPrefix+userIndex.toString(), name: userDict['username'], meta:userDict})
                    })
                }
                data.push({id: idIndex.toString(), name: alertDict['desc'], children: childrens, meta:alertDict['name']})
                idIndex++
            })

            console.log(data)

            setAlerts(data)
            // Object.keys(alertList).forEach(function(key) {
                // const childs = alertList[key]

                // const childIdPrefix = key[0] + idIndex.toString()

                // var childrens = []
                // if(childs.length > 0)   {
                //     childs.map((name, index) => {
                //         childrens.push({id: childIdPrefix+index.toString(), name: name})
                //     })
                // }
                // data.push({id: idIndex.toString(), name: key, children: childrens})
                // idIndex++
            // })

            //setAlerts(data)
        }
    
        if(priority !== null && areas.length > 0)   {
            fetchData(areas[selectedAreaTabIndex].id)
        }
    }, [areas, selectedAreaTabIndex]);

    const resetSaveProcess = () => {
        setOnSaveProcessSuccess(false)
        setOnSaveProcessFail(false)
    }

    const onSave = () => {
        async function setData(area_id, data) {
            setOnSaveProcess(true)
            const result = await dispatch(setAlertsDataList(area_id, data))
            setOnSaveProcess(false)
            if(!result.success) {
                setOnSaveProcessFail(true)
                return Swal.fire({text:result.message, icon: 'error'});
            } else  {
                setOnSaveProcessSuccess(true)
            }
        }

        var data = {}
        alerts.map((rootDict, rootIndex) => {
            var ccc = []
            const childrens = rootDict['children']
            childrens.map((cDict, cindex) => {
                ccc.push(cDict['meta']['id'])
            })
            data[rootDict['meta']] = ccc
        })

        setData(areas[selectedAreaTabIndex].id, data)
    }

    const Button = ({className, title}) => {
        return (
            <button className={className} onClick={()=>{onSave()}}>
                {title}
            </button>
        )
    }

    const moveElement = (arr, fromIndex, toIndex) => {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    Array.prototype.move = function(from,to){
        this.splice(to,0,this.splice(from,1)[0]);
        return this;
    };


    const onMove = ({ dragIds, parentId, index }) => {
        console.log('TreeView onMove dragIds:'+dragIds+' parentId:'+parentId + ' index:'+index)

        var alertNew = []

        alerts.map((rootDict, rootIndex) => {
            var ccc = []

            const childrens = rootDict['children']
            childrens.map((cDict, cindex) => {
                if(cDict['id'] == dragIds.toString() && rootDict['id'] == parentId.toString())    {
                    const childIdPrefix = rootDict['meta'][0] + rootIndex.toString()
                    const fromIndex = cindex
                    const toIndex = index//(index <= 0)?0:index-1
                    
                    console.log(fromIndex, toIndex)
                    var childsNew = []

                    const childs = rootDict['children']
                    childs.map((childsDict, childsIndex) => {
                        //childsNew.push(childsDict['name'])
                        childsNew.push(childsDict)
                    }) 
                    
                    childsNew.move(fromIndex, toIndex)

                    childsNew.map((name, childsIndex) => {
                        ccc.push({id: childIdPrefix+childsIndex.toString(), name: name['name'], meta: name['meta']})
                    })
                } else  {
                    console.log('Noooo')
                }
            })

            var rootDictNew = rootDict
            if(ccc.length > 0)    {
                rootDictNew['children'] = ccc
            } else  {
                rootDictNew['children'] = childrens
            }
            alertNew.push(rootDict)
        })

        setAlerts(alertNew)
    };

    const Node = ({ node, style, dragHandle }) => {
        return (
            <div style={style} ref={dragHandle}>
                {node.isLeaf ? (<LiaTelegramPlane className='mr-1'/>) : (<GrGroup className='mr-1'/>)}
                {node.data.name}
            </div>
        );
    }

    return (
        <div className={priority !== null ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={()=>{
                    setPriority(null)
                }}>
            </div>
            <div className="modal-content" style={{backgroundColor:'transparent'}}>
                <nav className="panel is-danger">
                    <p className="panel-heading">
                        Приоритеты
                    </p>
                    
                    <p className="panel-tabs" style={{backgroundColor:'white'}}>
                        {
                            areas.map((area, index) => {
                                return (
                                    <a className={(index == selectedAreaTabIndex)?'is-active':''} key={index} onClick={()=>{setSelectedAreaTabIndex(index)}}>
                                        {area.name}
                                    </a>
                                )
                            })
                        }
                    </p>

                    <div style={{backgroundColor:'white'}}>
                        {
                            (alertsLoading) ? (
                                <div className="hero is-flex">
                                    <div className="container has-text-centered mt-4"> 
                                    <Oval
                                        height={80}
                                        width={80}
                                        color="blue"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="white"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                        />
                                    </div>
                                </div>
                            ):(
                                <div className='mx-2'>
                                    <Tree
                                        data={alerts}
                                        onMove={onMove}
                                        indent={24}
                                        height={400}
                                        width={600}
                                        overscanCount={1}
                                        paddingTop={30}
                                        paddingBottom={10}
                                        padding={10} 
                                    >
                                        {Node}
                                    </Tree>
                                </div>
                            )
                        }
                        <div className="panel-block">
                            {(alertsLoading) ? (
                                <></>
                            ):(
                                (onSaveProcess) ? (
                                    <Button className="button is-link is-outlined is-fullwidth is-loading" title="Сохранить"/>
                                ) :
                                (onSaveProcessSuccess) ? (
                                    <Button className="button is-link is-outlined is-fullwidth is-success" title={
                                        <>
                                            <span className="icon is-small">
                                                <FaCheck/>
                                            </span>
                                            <span>Сохранено</span>
                                        </>
                                    }/>
                                ) :
                                (onSaveProcessFail) ? (
                                    <Button className="button is-link is-outlined is-fullwidth is-danger" title="Ошибка сохранения"/>
                                ) : (
                                    <Button className="button is-link is-outlined is-fullwidth" title="Сохранить"/>
                                )
                            )}
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}